import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import moment, { MomentFormatSpecification } from "moment";
import { IndividualFull } from "../individual/individual-full.class";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Role } from "../enums/user.enums";
import { getQueryParams } from "../utils/queryParams.utils";
import { BehaviorSubject, from, Observable, of, Subject, Subscription } from "rxjs";
import {
  IndexedDBKeys,
  Language,
  LocalStorageKeys,
  SendMsgToIndType,
  StorageHost,
  TimeVaryingMethod
} from "../enums/general.enums";
import { TranslateService } from "@ngx-translate/core";
import { BarcodeFormat } from "@zxing/library";
import { CloudFnV2Error, CustomError } from "../models/general.models";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { mergeAll, reduce, take, timeout } from "rxjs/operators";
import { CacheKeys, CacheService } from "../cache/cache.service";
import { Background } from "../utils/background.utils";
import { EncryptionService } from "../encryption/encryption.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AlertDialogService } from "src/app/features/alert-dialog/alert-dialog.service";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { UrlService } from "../digital-id/url.service";
import { LicenseService } from "../license/license.service";
import { AuthApiService } from "../auth/auth.api.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { IndividualApiService } from "../individual/individual.api.service";
import { Visits } from "../Visits/visits";
import { SettingsService } from "../settings/settings.service";
import { CertificateService } from "../certificate.service";
import { PassTypesService } from "../pass-types/pass-types.service";
import { VariationsService } from "../variations/variations.service";
import { GetIndPhotoUsingPrefSettings } from "../pipes/getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe";
import { OrgManagerService } from "../orgManager/orgManager.service";
import { TransactionsService } from "../transactions.service";
import { LeagueDivisionsService } from "../league-divisions.service";
import { BehaviouralIncidentService } from "../behavioural-incident/behavioural-incident.service";
import { IncidentsService } from "../incidents/incidents.service";
import { MimeType } from "../photo.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { PassesService } from "../passes/passes.service";
import {
  AuthDependency,
  CommonAuthDependencies,
  PrepMsgInputs,
  PrepMsgOptions,
  PrepMsgPreCookedData,
  PrepMsgServices
} from "../interface/types";
import { IndexedDBService } from "../IndexedDB/indexed-db.service";
import { ApiHelperService, CloudFnNames } from "../api-helper.service";
import { GetIndFields } from "../utils/inds.utils";
import { getDeviceInfo } from "../../../assets/js/deviceInfo.js";
import { OAuthErrorEvent } from "angular-oauth2-oidc";

declare var VipPassRendererInterOp;
export class LeagueDivision {
  name?: string;
  minAge?: string;
  maxAge?: string;
  minWeight?: string;
  maxWeight?: string;
  key?: string;
}
@Injectable({
  providedIn: "root"
})
export class CommonServiceService {
  static readonly DefMaxCapForPortraits = 800;
  static readonly DEFAULT_PRIMARY_COLOR = "#1D3E66";
  static readonly DEFAULT_SECONDARY_COLOR = "#F69B05";

  static readonly photoPathArr = ["/photo", "/photo/takePhoto"];

  static readonly tvtPathArr = [
    "/visitor/take-visitor-photo",
    "/visitor/visitor-badge"
  ];

  static readonly _2FATokenExpiry = 7 * 24 * 60 * 60 * 1000; // 7 days

  static readonly MsgTypeShortNames = {
    [SendMsgToIndType.FORGOT_PASS]: "Auth Forgot Password",
    [SendMsgToIndType.SET_PASS]: "Auth - Password Set",
    [SendMsgToIndType.CROSSING_NOTIFICATION]: "Crossing notification"
  };

  static readonly iOSPlatforms = [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod"
  ];

  // --- guardian auth dependency to get child data
  static readonly grdnAuthDependency: AuthDependency = {
    forRule: "static",
    requires: "relations"
  };

  isAskLocationDetails: boolean = false;
  isShowPassTypeBtn: boolean = true;
  // authStateReadyObservable: Observable<any>;

  shareData = {
    customScannerData: null,
    deferredPWAPrompt: null,
    connectionStatus: "online"
  };

  static SaveToHomeScreen = {
    video: {
      android8:
        "https://high5.id/media/save-to-home/SaveToHomeScreen_Android8.m4v",
      android10:
        "https://high5.id/media/save-to-home/SaveToHomeScreen_Android10.m4v",
      iOS: "https://high5.id/media/save-to-home/SaveToHomeScreen_iOS.m4v"
    },
    poster: {
      android8: "https://high5.id/media/save-to-home/Android8Poster.png",
      android10: "https://high5.id/media/save-to-home/Android10Poster.png",
      iOS: "https://high5.id/media/save-to-home/iOSPoster.png"
    }
  };

  static obscureData: any[] = [
    {
      key: "studentID",
      obj: {
        start: 1,
        end: 2
      }
    },
    {
      key: "mobilePhone",
      obj: {
        start: 3,
        end: 1
      }
    },
    {
      key: "email",
      obj: {
        start: 2,
        end: 3
      }
    }
  ];

  static CaptureGuides = {
    notLike: [
      "https://high5.id/media/capture-guides/No-1.jpg",
      "https://high5.id/media/capture-guides/No-2.jpg",
      "https://high5.id/media/capture-guides/No-3.jpg",
      "https://high5.id/media/capture-guides/No-4.jpg",
      "https://high5.id/media/capture-guides/No-5.jpg",
      "https://high5.id/media/capture-guides/No-6.jpg",
      "https://high5.id/media/capture-guides/No-7.jpg",
      "https://high5.id/media/capture-guides/No-8.jpg",
      "https://high5.id/media/capture-guides/No-9.jpg",
      "https://high5.id/media/capture-guides/No-10.jpg"
    ],
    like: "https://high5.id/media/capture-guides/OK.jpg"
  };

  static rendererScripts = {
    rendererURL: `${
      environment.rendererBaseUrl
    }/container/js/vipPassRenderer.js?v=${moment().valueOf()}`,
    rendererContainerURL: `${
      environment.rendererBaseUrl
    }/container/js/container.js?v=${moment().valueOf()}`
  };

  scannerAllowedFormats = [
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_128,
    BarcodeFormat.QR_CODE
  ];
  usaSynonymList = [
    "USA",
    "US",
    "US of a",
    "america",
    "U.S.A",
    "United States"
  ];
  emailRegx = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  countryList = [
    {
      name: "Canada",
      code: "CA"
    },
    {
      name: "United States",
      code: "US"
    },
    {
      name: "Afghanistan",
      code: "AF"
    },
    {
      name: "Åland Islands",
      code: "AX"
    },
    {
      name: "Albania",
      code: "AL"
    },
    {
      name: "Algeria",
      code: "DZ"
    },
    {
      name: "American Samoa",
      code: "AS"
    },
    {
      name: "AndorrA",
      code: "AD"
    },
    {
      name: "Angola",
      code: "AO"
    },
    {
      name: "Anguilla",
      code: "AI"
    },
    {
      name: "Antarctica",
      code: "AQ"
    },
    {
      name: "Antigua and Barbuda",
      code: "AG"
    },
    {
      name: "Argentina",
      code: "AR"
    },
    {
      name: "Armenia",
      code: "AM"
    },
    {
      name: "Aruba",
      code: "AW"
    },
    {
      name: "Australia",
      code: "AU"
    },
    {
      name: "Austria",
      code: "AT"
    },
    {
      name: "Azerbaijan",
      code: "AZ"
    },
    {
      name: "Bahamas",
      code: "BS"
    },
    {
      name: "Bahrain",
      code: "BH"
    },
    {
      name: "Bangladesh",
      code: "BD"
    },
    {
      name: "Barbados",
      code: "BB"
    },
    {
      name: "Belarus",
      code: "BY"
    },
    {
      name: "Belgium",
      code: "BE"
    },
    {
      name: "Belize",
      code: "BZ"
    },
    {
      name: "Benin",
      code: "BJ"
    },
    {
      name: "Bermuda",
      code: "BM"
    },
    {
      name: "Bhutan",
      code: "BT"
    },
    {
      name: "Bolivia",
      code: "BO"
    },
    {
      name: "Bosnia and Herzegovina",
      code: "BA"
    },
    {
      name: "Botswana",
      code: "BW"
    },
    {
      name: "Bouvet Island",
      code: "BV"
    },
    {
      name: "Brazil",
      code: "BR"
    },
    {
      name: "British Indian Ocean Territory",
      code: "IO"
    },
    {
      name: "Brunei Darussalam",
      code: "BN"
    },
    {
      name: "Bulgaria",
      code: "BG"
    },
    {
      name: "Burkina Faso",
      code: "BF"
    },
    {
      name: "Burundi",
      code: "BI"
    },
    {
      name: "Cambodia",
      code: "KH"
    },
    {
      name: "Cameroon",
      code: "CM"
    },
    {
      name: "Cape Verde",
      code: "CV"
    },
    {
      name: "Cayman Islands",
      code: "KY"
    },
    {
      name: "Central African Republic",
      code: "CF"
    },
    {
      name: "Chad",
      code: "TD"
    },
    {
      name: "Chile",
      code: "CL"
    },
    {
      name: "China",
      code: "CN"
    },
    {
      name: "Christmas Island",
      code: "CX"
    },
    {
      name: "Cocos (Keeling) Islands",
      code: "CC"
    },
    {
      name: "Colombia",
      code: "CO"
    },
    {
      name: "Comoros",
      code: "KM"
    },
    {
      name: "Congo",
      code: "CG"
    },
    {
      name: "Congo, The Democratic Republic of the",
      code: "CD"
    },
    {
      name: "Cook Islands",
      code: "CK"
    },
    {
      name: "Costa Rica",
      code: "CR"
    },
    {
      name: 'Cote D"Ivoire',
      code: "CI"
    },
    {
      name: "Croatia",
      code: "HR"
    },
    {
      name: "Cuba",
      code: "CU"
    },
    {
      name: "Cyprus",
      code: "CY"
    },
    {
      name: "Czech Republic",
      code: "CZ"
    },
    {
      name: "Denmark",
      code: "DK"
    },
    {
      name: "Djibouti",
      code: "DJ"
    },
    {
      name: "Dominica",
      code: "DM"
    },
    {
      name: "Dominican Republic",
      code: "DO"
    },
    {
      name: "Ecuador",
      code: "EC"
    },
    {
      name: "Egypt",
      code: "EG"
    },
    {
      name: "El Salvador",
      code: "SV"
    },
    {
      name: "Equatorial Guinea",
      code: "GQ"
    },
    {
      name: "Eritrea",
      code: "ER"
    },
    {
      name: "Estonia",
      code: "EE"
    },
    {
      name: "Ethiopia",
      code: "ET"
    },
    {
      name: "Falkland Islands (Malvinas)",
      code: "FK"
    },
    {
      name: "Faroe Islands",
      code: "FO"
    },
    {
      name: "Fiji",
      code: "FJ"
    },
    {
      name: "Finland",
      code: "FI"
    },
    {
      name: "France",
      code: "FR"
    },
    {
      name: "French Guiana",
      code: "GF"
    },
    {
      name: "French Polynesia",
      code: "PF"
    },
    {
      name: "French Southern Territories",
      code: "TF"
    },
    {
      name: "Gabon",
      code: "GA"
    },
    {
      name: "Gambia",
      code: "GM"
    },
    {
      name: "Georgia",
      code: "GE"
    },
    {
      name: "Germany",
      code: "DE"
    },
    {
      name: "Ghana",
      code: "GH"
    },
    {
      name: "Gibraltar",
      code: "GI"
    },
    {
      name: "Greece",
      code: "GR"
    },
    {
      name: "Greenland",
      code: "GL"
    },
    {
      name: "Grenada",
      code: "GD"
    },
    {
      name: "Guadeloupe",
      code: "GP"
    },
    {
      name: "Guam",
      code: "GU"
    },
    {
      name: "Guatemala",
      code: "GT"
    },
    {
      name: "Guernsey",
      code: "GG"
    },
    {
      name: "Guinea",
      code: "GN"
    },
    {
      name: "Guinea-Bissau",
      code: "GW"
    },
    {
      name: "Guyana",
      code: "GY"
    },
    {
      name: "Haiti",
      code: "HT"
    },
    {
      name: "Heard Island and Mcdonald Islands",
      code: "HM"
    },
    {
      name: "Holy See (Vatican City State)",
      code: "VA"
    },
    {
      name: "Honduras",
      code: "HN"
    },
    {
      name: "Hong Kong",
      code: "HK"
    },
    {
      name: "Hungary",
      code: "HU"
    },
    {
      name: "Iceland",
      code: "IS"
    },
    {
      name: "India",
      code: "IN"
    },
    {
      name: "Indonesia",
      code: "ID"
    },
    {
      name: "Iran, Islamic Republic Of",
      code: "IR"
    },
    {
      name: "Iraq",
      code: "IQ"
    },
    {
      name: "Ireland",
      code: "IE"
    },
    {
      name: "Isle of Man",
      code: "IM"
    },
    {
      name: "Israel",
      code: "IL"
    },
    {
      name: "Italy",
      code: "IT"
    },
    {
      name: "Jamaica",
      code: "JM"
    },
    {
      name: "Japan",
      code: "JP"
    },
    {
      name: "Jersey",
      code: "JE"
    },
    {
      name: "Jordan",
      code: "JO"
    },
    {
      name: "Kazakhstan",
      code: "KZ"
    },
    {
      name: "Kenya",
      code: "KE"
    },
    {
      name: "Kiribati",
      code: "KI"
    },
    {
      name: 'Korea, Democratic People"S Republic of',
      code: "KP"
    },
    {
      name: "Korea, Republic of",
      code: "KR"
    },
    {
      name: "Kuwait",
      code: "KW"
    },
    {
      name: "Kyrgyzstan",
      code: "KG"
    },
    {
      name: 'Lao People"S Democratic Republic',
      code: "LA"
    },
    {
      name: "Latvia",
      code: "LV"
    },
    {
      name: "Lebanon",
      code: "LB"
    },
    {
      name: "Lesotho",
      code: "LS"
    },
    {
      name: "Liberia",
      code: "LR"
    },
    {
      name: "Libyan Arab Jamahiriya",
      code: "LY"
    },
    {
      name: "Liechtenstein",
      code: "LI"
    },
    {
      name: "Lithuania",
      code: "LT"
    },
    {
      name: "Luxembourg",
      code: "LU"
    },
    {
      name: "Macao",
      code: "MO"
    },
    {
      name: "Macedonia, The Former Yugoslav Republic of",
      code: "MK"
    },
    {
      name: "Madagascar",
      code: "MG"
    },
    {
      name: "Malawi",
      code: "MW"
    },
    {
      name: "Malaysia",
      code: "MY"
    },
    {
      name: "Maldives",
      code: "MV"
    },
    {
      name: "Mali",
      code: "ML"
    },
    {
      name: "Malta",
      code: "MT"
    },
    {
      name: "Marshall Islands",
      code: "MH"
    },
    {
      name: "Martinique",
      code: "MQ"
    },
    {
      name: "Mauritania",
      code: "MR"
    },
    {
      name: "Mauritius",
      code: "MU"
    },
    {
      name: "Mayotte",
      code: "YT"
    },
    {
      name: "Mexico",
      code: "MX"
    },
    {
      name: "Micronesia, Federated States of",
      code: "FM"
    },
    {
      name: "Moldova, Republic of",
      code: "MD"
    },
    {
      name: "Monaco",
      code: "MC"
    },
    {
      name: "Mongolia",
      code: "MN"
    },
    {
      name: "Montserrat",
      code: "MS"
    },
    {
      name: "Morocco",
      code: "MA"
    },
    {
      name: "Mozambique",
      code: "MZ"
    },
    {
      name: "Myanmar",
      code: "MM"
    },
    {
      name: "Namibia",
      code: "NA"
    },
    {
      name: "Nauru",
      code: "NR"
    },
    {
      name: "Nepal",
      code: "NP"
    },
    {
      name: "Netherlands",
      code: "NL"
    },
    {
      name: "Netherlands Antilles",
      code: "AN"
    },
    {
      name: "New Caledonia",
      code: "NC"
    },
    {
      name: "New Zealand",
      code: "NZ"
    },
    {
      name: "Nicaragua",
      code: "NI"
    },
    {
      name: "Niger",
      code: "NE"
    },
    {
      name: "Nigeria",
      code: "NG"
    },
    {
      name: "Niue",
      code: "NU"
    },
    {
      name: "Norfolk Island",
      code: "NF"
    },
    {
      name: "Northern Mariana Islands",
      code: "MP"
    },
    {
      name: "Norway",
      code: "NO"
    },
    {
      name: "Oman",
      code: "OM"
    },
    {
      name: "Pakistan",
      code: "PK"
    },
    {
      name: "Palau",
      code: "PW"
    },
    {
      name: "Palestinian Territory, Occupied",
      code: "PS"
    },
    {
      name: "Panama",
      code: "PA"
    },
    {
      name: "Papua New Guinea",
      code: "PG"
    },
    {
      name: "Paraguay",
      code: "PY"
    },
    {
      name: "Peru",
      code: "PE"
    },
    {
      name: "Philippines",
      code: "PH"
    },
    {
      name: "Pitcairn",
      code: "PN"
    },
    {
      name: "Poland",
      code: "PL"
    },
    {
      name: "Portugal",
      code: "PT"
    },
    {
      name: "Puerto Rico",
      code: "PR"
    },
    {
      name: "Qatar",
      code: "QA"
    },
    {
      name: "Reunion",
      code: "RE"
    },
    {
      name: "Romania",
      code: "RO"
    },
    {
      name: "Russian Federation",
      code: "RU"
    },
    {
      name: "RWANDA",
      code: "RW"
    },
    {
      name: "Saint Helena",
      code: "SH"
    },
    {
      name: "Saint Kitts and Nevis",
      code: "KN"
    },
    {
      name: "Saint Lucia",
      code: "LC"
    },
    {
      name: "Saint Pierre and Miquelon",
      code: "PM"
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "VC"
    },
    {
      name: "Samoa",
      code: "WS"
    },
    {
      name: "San Marino",
      code: "SM"
    },
    {
      name: "Sao Tome and Principe",
      code: "ST"
    },
    {
      name: "Saudi Arabia",
      code: "SA"
    },
    {
      name: "Senegal",
      code: "SN"
    },
    {
      name: "Serbia",
      code: "RS"
    },
    {
      name: "Montenegro",
      code: "ME"
    },
    {
      name: "Seychelles",
      code: "SC"
    },
    {
      name: "Sierra Leone",
      code: "SL"
    },
    {
      name: "Singapore",
      code: "SG"
    },
    {
      name: "Slovakia",
      code: "SK"
    },
    {
      name: "Slovenia",
      code: "SI"
    },
    {
      name: "Solomon Islands",
      code: "SB"
    },
    {
      name: "Somalia",
      code: "SO"
    },
    {
      name: "South Africa",
      code: "ZA"
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      code: "GS"
    },
    {
      name: "Spain",
      code: "ES"
    },
    {
      name: "Sri Lanka",
      code: "LK"
    },
    {
      name: "Sudan",
      code: "SD"
    },
    {
      name: "Suriname",
      code: "SR"
    },
    {
      name: "Svalbard and Jan Mayen",
      code: "SJ"
    },
    {
      name: "Swaziland",
      code: "SZ"
    },
    {
      name: "Sweden",
      code: "SE"
    },
    {
      name: "Switzerland",
      code: "CH"
    },
    {
      name: "Syrian Arab Republic",
      code: "SY"
    },
    {
      name: "Taiwan, Province of China",
      code: "TW"
    },
    {
      name: "Tajikistan",
      code: "TJ"
    },
    {
      name: "Tanzania, United Republic of",
      code: "TZ"
    },
    {
      name: "Thailand",
      code: "TH"
    },
    {
      name: "Timor-Leste",
      code: "TL"
    },
    {
      name: "Togo",
      code: "TG"
    },
    {
      name: "Tokelau",
      code: "TK"
    },
    {
      name: "Tonga",
      code: "TO"
    },
    {
      name: "Trinidad and Tobago",
      code: "TT"
    },
    {
      name: "Tunisia",
      code: "TN"
    },
    {
      name: "Turkey",
      code: "TR"
    },
    {
      name: "Turkmenistan",
      code: "TM"
    },
    {
      name: "Turks and Caicos Islands",
      code: "TC"
    },
    {
      name: "Tuvalu",
      code: "TV"
    },
    {
      name: "Uganda",
      code: "UG"
    },
    {
      name: "Ukraine",
      code: "UA"
    },
    {
      name: "United Arab Emirates",
      code: "AE"
    },
    {
      name: "United Kingdom",
      code: "GB"
    },
    {
      name: "United States Minor Outlying Islands",
      code: "UM"
    },
    {
      name: "Uruguay",
      code: "UY"
    },
    {
      name: "Uzbekistan",
      code: "UZ"
    },
    {
      name: "Vanuatu",
      code: "VU"
    },
    {
      name: "Venezuela",
      code: "VE"
    },
    {
      name: "Viet Nam",
      code: "VN"
    },
    {
      name: "Virgin Islands, British",
      code: "VG"
    },
    {
      name: "Virgin Islands, U.S.",
      code: "VI"
    },
    {
      name: "Wallis and Futuna",
      code: "WF"
    },
    {
      name: "Western Sahara",
      code: "EH"
    },
    {
      name: "Yemen",
      code: "YE"
    },
    {
      name: "Zambia",
      code: "ZM"
    },
    {
      name: "Zimbabwe",
      code: "ZW"
    }
  ];
  usStateList = [
    { name: "Alabama", "alpha-2": "AL" },
    { name: "Alaska", "alpha-2": "AK" },
    { name: "Arizona", "alpha-2": "AZ" },
    { name: "Arkansas", "alpha-2": "AR" },
    { name: "California", "alpha-2": "CA" },
    { name: "Colorado", "alpha-2": "CO" },
    { name: "Connecticut", "alpha-2": "CT" },
    { name: "Delaware", "alpha-2": "DE" },
    { name: "District of Columbia", "alpha-2": "DC" },
    { name: "Florida", "alpha-2": "FL" },
    { name: "Georgia", "alpha-2": "GA" },
    { name: "Hawaii", "alpha-2": "HI" },
    { name: "Idaho", "alpha-2": "ID" },
    { name: "Illinois", "alpha-2": "IL" },
    { name: "Indiana", "alpha-2": "IN" },
    { name: "Iowa", "alpha-2": "IA" },
    { name: "Kansas", "alpha-2": "KS" },
    { name: "Kentucky", "alpha-2": "KY" },
    { name: "Lousiana", "alpha-2": "LA" },
    { name: "Maine", "alpha-2": "ME" },
    { name: "Maryland", "alpha-2": "MD" },
    { name: "Massachusetts", "alpha-2": "MA" },
    { name: "Michigan", "alpha-2": "MI" },
    { name: "Minnesota", "alpha-2": "MN" },
    { name: "Mississippi", "alpha-2": "MS" },
    { name: "Missouri", "alpha-2": "MO" },
    { name: "Montana", "alpha-2": "MT" },
    { name: "Nebraska", "alpha-2": "NE" },
    { name: "Nevada", "alpha-2": "NV" },
    { name: "New Hampshire", "alpha-2": "NH" },
    { name: "New Jersey", "alpha-2": "NJ" },
    { name: "New Mexico", "alpha-2": "NM" },
    { name: "New York", "alpha-2": "NY" },
    { name: "North Carolina", "alpha-2": "NC" },
    { name: "North Dakota", "alpha-2": "ND" },
    { name: "Ohio", "alpha-2": "OH" },
    { name: "Oklahoma", "alpha-2": "OK" },
    { name: "Oregon", "alpha-2": "OR" },
    { name: "Pennsylvania", "alpha-2": "PA" },
    { name: "Rhode Island", "alpha-2": "RI" },
    { name: "South Carolina", "alpha-2": "SC" },
    { name: "South Dakota", "alpha-2": "SD" },
    { name: "Tennessee", "alpha-2": "TN" },
    { name: "Texas", "alpha-2": "TX" },
    { name: "Utah", "alpha-2": "UT" },
    { name: "Vermont", "alpha-2": "VT" },
    { name: "Virginia", "alpha-2": "VA" },
    { name: "Washington", "alpha-2": "WA" },
    { name: "West Virginia", "alpha-2": "WV" },
    { name: "Wisconsin", "alpha-2": "WI" },
    { name: "Wyoming", "alpha-2": "WY" }
  ];

  canadaStateList = [
    {
      name: "Alberta",
      "alpha-2": "AB"
    },
    {
      name: "British Columbia",
      "alpha-2": "BC"
    },
    {
      name: "Manitoba",
      "alpha-2": "MB"
    },
    {
      name: "New Brunswick",
      "alpha-2": "NB"
    },
    {
      name: "Newfoundland and Labrador",
      "alpha-2": "NL"
    },
    {
      name: "Northwest Territories",
      "alpha-2": "NT"
    },
    {
      name: "Nova Scotia",
      "alpha-2": "NS"
    },
    {
      name: "Nunavut",
      "alpha-2": "NU"
    },
    {
      name: "Ontario",
      "alpha-2": "ON"
    },
    {
      name: "Prince Edward Island",
      "alpha-2": "PE"
    },
    {
      name: "Quebec",
      "alpha-2": "QC"
    },
    {
      name: "Saskatchewan",
      "alpha-2": "SK"
    },
    {
      name: "Yukon Territory",
      "alpha-2": "YT"
    }
  ];

  timezoneList = [
    {
      value: "Dateline Standard Time",
      abbr: "DST",
      offset: -12,
      isdst: false,
      text: "(UTC-12:00) International Date Line West",
      utc: ["Etc/GMT+12"]
    },
    {
      value: "UTC-11",
      abbr: "U",
      offset: -11,
      isdst: false,
      text: "(UTC-11:00) Coordinated Universal Time-11",
      utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"]
    },
    {
      value: "Hawaiian Standard Time",
      abbr: "HST",
      offset: -10,
      isdst: false,
      text: "(UTC-10:00) Hawaii",
      utc: [
        "Etc/GMT+10",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Rarotonga",
        "Pacific/Tahiti"
      ]
    },
    {
      value: "Alaskan Standard Time",
      abbr: "AKDT",
      offset: -8,
      isdst: true,
      text: "(UTC-09:00) Alaska",
      utc: [
        "America/Anchorage",
        "America/Juneau",
        "America/Nome",
        "America/Sitka",
        "America/Yakutat"
      ]
    },
    {
      value: "Pacific Standard Time (Mexico)",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      text: "(UTC-08:00) Baja California",
      utc: ["America/Santa_Isabel"]
    },
    {
      value: "Pacific Daylight Time",
      abbr: "PDT",
      offset: -7,
      isdst: true,
      text: "(UTC-07:00) Pacific Time (US & Canada)",
      utc: [
        "America/Dawson",
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "America/Whitehorse"
      ]
    },
    {
      value: "Pacific Standard Time",
      abbr: "PST",
      offset: -8,
      isdst: false,
      text: "(UTC-08:00) Pacific Time (US & Canada)",
      utc: [
        "America/Dawson",
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "America/Whitehorse",
        "PST8PDT"
      ]
    },
    {
      value: "US Mountain Standard Time",
      abbr: "UMST",
      offset: -7,
      isdst: false,
      text: "(UTC-07:00) Arizona",
      utc: [
        "America/Creston",
        "America/Dawson_Creek",
        "America/Hermosillo",
        "America/Phoenix",
        "Etc/GMT+7"
      ]
    },
    {
      value: "Mountain Standard Time (Mexico)",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      utc: ["America/Chihuahua", "America/Mazatlan"]
    },
    {
      value: "Mountain Standard Time",
      abbr: "MDT",
      offset: -6,
      isdst: true,
      text: "(UTC-07:00) Mountain Time (US & Canada)",
      utc: [
        "America/Boise",
        "America/Cambridge_Bay",
        "America/Denver",
        "America/Edmonton",
        "America/Inuvik",
        "America/Ojinaga",
        "America/Yellowknife",
        "MST7MDT"
      ]
    },
    {
      value: "Central America Standard Time",
      abbr: "CAST",
      offset: -6,
      isdst: false,
      text: "(UTC-06:00) Central America",
      utc: [
        "America/Belize",
        "America/Costa_Rica",
        "America/El_Salvador",
        "America/Guatemala",
        "America/Managua",
        "America/Tegucigalpa",
        "Etc/GMT+6",
        "Pacific/Galapagos"
      ]
    },
    {
      value: "Central Standard Time",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      text: "(UTC-06:00) Central Time (US & Canada)",
      utc: [
        "America/Chicago",
        "America/Indiana/Knox",
        "America/Indiana/Tell_City",
        "America/Matamoros",
        "America/Menominee",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Resolute",
        "America/Winnipeg",
        "CST6CDT"
      ]
    },
    {
      value: "Central Standard Time (Mexico)",
      abbr: "CDT",
      offset: -5,
      isdst: true,
      text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      utc: [
        "America/Bahia_Banderas",
        "America/Cancun",
        "America/Merida",
        "America/Mexico_City",
        "America/Monterrey"
      ]
    },
    {
      value: "Canada Central Standard Time",
      abbr: "CCST",
      offset: -6,
      isdst: false,
      text: "(UTC-06:00) Saskatchewan",
      utc: ["America/Regina", "America/Swift_Current"]
    },
    {
      value: "SA Pacific Standard Time",
      abbr: "SPST",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Bogota, Lima, Quito",
      utc: [
        "America/Bogota",
        "America/Cayman",
        "America/Coral_Harbour",
        "America/Eirunepe",
        "America/Guayaquil",
        "America/Jamaica",
        "America/Lima",
        "America/Panama",
        "America/Rio_Branco",
        "Etc/GMT+5"
      ]
    },
    {
      value: "Eastern Daylight Time",
      abbr: "EDT",
      offset: -4,
      isdst: true,
      text: "(UTC-04:00) Eastern Daylight Time (US & Canada)",
      utc: [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto",
        "EST5EDT"
      ]
    },
    {
      value: "Eastern Standard Time",
      abbr: "EST",
      offset: -5,
      isdst: false,
      text: "(UTC-05:00) Eastern Time (US & Canada)",
      utc: [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto"
      ]
    },
    {
      value: "US Eastern Standard Time",
      abbr: "UEDT",
      offset: -4,
      isdst: true,
      text: "(UTC-05:00) Indiana (East)",
      utc: [
        "America/Indiana/Marengo",
        "America/Indiana/Vevay",
        "America/Indianapolis"
      ]
    },
    {
      value: "Venezuela Standard Time",
      abbr: "VST",
      offset: -4.5,
      isdst: false,
      text: "(UTC-04:30) Caracas",
      utc: ["America/Caracas"]
    },
    {
      value: "Paraguay Standard Time",
      abbr: "PYT",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Asuncion",
      utc: ["America/Asuncion"]
    },
    {
      value: "Atlantic Standard Time",
      abbr: "ADT",
      offset: -3,
      isdst: true,
      text: "(UTC-04:00) Atlantic Time (Canada)",
      utc: [
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Moncton",
        "America/Thule",
        "Atlantic/Bermuda"
      ]
    },
    {
      value: "Central Brazilian Standard Time",
      abbr: "CBST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Cuiaba",
      utc: ["America/Campo_Grande", "America/Cuiaba"]
    },
    {
      value: "SA Western Standard Time",
      abbr: "SWST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      utc: [
        "America/Anguilla",
        "America/Antigua",
        "America/Aruba",
        "America/Barbados",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Curacao",
        "America/Dominica",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guyana",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lower_Princes",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Montserrat",
        "America/Port_of_Spain",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Santo_Domingo",
        "America/St_Barthelemy",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Tortola",
        "Etc/GMT+4"
      ]
    },
    {
      value: "Pacific SA Standard Time",
      abbr: "PSST",
      offset: -4,
      isdst: false,
      text: "(UTC-04:00) Santiago",
      utc: ["America/Santiago", "Antarctica/Palmer"]
    },
    {
      value: "Newfoundland Standard Time",
      abbr: "NDT",
      offset: -2.5,
      isdst: true,
      text: "(UTC-03:30) Newfoundland",
      utc: ["America/St_Johns"]
    },
    {
      value: "E. South America Standard Time",
      abbr: "ESAST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Brasilia",
      utc: ["America/Sao_Paulo"]
    },
    {
      value: "Argentina Standard Time",
      abbr: "AST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Buenos Aires",
      utc: [
        "America/Argentina/La_Rioja",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Buenos_Aires",
        "America/Catamarca",
        "America/Cordoba",
        "America/Jujuy",
        "America/Mendoza"
      ]
    },
    {
      value: "SA Eastern Standard Time",
      abbr: "SEST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Cayenne, Fortaleza",
      utc: [
        "America/Araguaina",
        "America/Belem",
        "America/Cayenne",
        "America/Fortaleza",
        "America/Maceio",
        "America/Paramaribo",
        "America/Recife",
        "America/Santarem",
        "Antarctica/Rothera",
        "Atlantic/Stanley",
        "Etc/GMT+3"
      ]
    },
    {
      value: "Greenland Standard Time",
      abbr: "GDT",
      offset: -3,
      isdst: true,
      text: "(UTC-03:00) Greenland",
      utc: ["America/Godthab"]
    },
    {
      value: "Montevideo Standard Time",
      abbr: "MST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Montevideo",
      utc: ["America/Montevideo"]
    },
    {
      value: "Bahia Standard Time",
      abbr: "BST",
      offset: -3,
      isdst: false,
      text: "(UTC-03:00) Salvador",
      utc: ["America/Bahia"]
    },
    {
      value: "UTC-02",
      abbr: "U",
      offset: -2,
      isdst: false,
      text: "(UTC-02:00) Coordinated Universal Time-02",
      utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"]
    },
    {
      value: "Mid-Atlantic Standard Time",
      abbr: "MDT",
      offset: -1,
      isdst: true,
      text: "(UTC-02:00) Mid-Atlantic - Old",
      utc: []
    },
    {
      value: "Azores Standard Time",
      abbr: "ADT",
      offset: 0,
      isdst: true,
      text: "(UTC-01:00) Azores",
      utc: ["America/Scoresbysund", "Atlantic/Azores"]
    },
    {
      value: "Cape Verde Standard Time",
      abbr: "CVST",
      offset: -1,
      isdst: false,
      text: "(UTC-01:00) Cape Verde Is.",
      utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"]
    },
    {
      value: "Morocco Standard Time",
      abbr: "MDT",
      offset: 1,
      isdst: true,
      text: "(UTC) Casablanca",
      utc: ["Africa/Casablanca", "Africa/El_Aaiun"]
    },
    {
      value: "UTC",
      abbr: "UTC",
      offset: 0,
      isdst: false,
      text: "(UTC) Coordinated Universal Time",
      utc: ["America/Danmarkshavn", "Etc/GMT"]
    },
    {
      value: "GMT Standard Time",
      abbr: "GMT",
      offset: 0,
      isdst: false,
      text: "(UTC) Edinburgh, London",
      utc: [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      value: "British Summer Time",
      abbr: "BST",
      offset: 1,
      isdst: true,
      text: "(UTC+01:00) Edinburgh, London",
      utc: [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      value: "GMT Standard Time",
      abbr: "GDT",
      offset: 1,
      isdst: true,
      text: "(UTC) Dublin, Lisbon",
      utc: [
        "Atlantic/Canary",
        "Atlantic/Faeroe",
        "Atlantic/Madeira",
        "Europe/Dublin",
        "Europe/Lisbon"
      ]
    },
    {
      value: "Greenwich Standard Time",
      abbr: "GST",
      offset: 0,
      isdst: false,
      text: "(UTC) Monrovia, Reykjavik",
      utc: [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Bamako",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Freetown",
        "Africa/Lome",
        "Africa/Monrovia",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Sao_Tome",
        "Atlantic/Reykjavik",
        "Atlantic/St_Helena"
      ]
    },
    {
      value: "W. Europe Standard Time",
      abbr: "WEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      utc: [
        "Arctic/Longyearbyen",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Berlin",
        "Europe/Busingen",
        "Europe/Gibraltar",
        "Europe/Luxembourg",
        "Europe/Malta",
        "Europe/Monaco",
        "Europe/Oslo",
        "Europe/Rome",
        "Europe/San_Marino",
        "Europe/Stockholm",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Zurich"
      ]
    },
    {
      value: "Central Europe Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      utc: [
        "Europe/Belgrade",
        "Europe/Bratislava",
        "Europe/Budapest",
        "Europe/Ljubljana",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Tirane"
      ]
    },
    {
      value: "Romance Standard Time",
      abbr: "RDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      utc: [
        "Africa/Ceuta",
        "Europe/Brussels",
        "Europe/Copenhagen",
        "Europe/Madrid",
        "Europe/Paris"
      ]
    },
    {
      value: "Central European Standard Time",
      abbr: "CEDT",
      offset: 2,
      isdst: true,
      text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      utc: [
        "Europe/Sarajevo",
        "Europe/Skopje",
        "Europe/Warsaw",
        "Europe/Zagreb"
      ]
    },
    {
      value: "W. Central Africa Standard Time",
      abbr: "WCAST",
      offset: 1,
      isdst: false,
      text: "(UTC+01:00) West Central Africa",
      utc: [
        "Africa/Algiers",
        "Africa/Bangui",
        "Africa/Brazzaville",
        "Africa/Douala",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Luanda",
        "Africa/Malabo",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Porto-Novo",
        "Africa/Tunis",
        "Etc/GMT-1"
      ]
    },
    {
      value: "Namibia Standard Time",
      abbr: "NST",
      offset: 1,
      isdst: false,
      text: "(UTC+01:00) Windhoek",
      utc: ["Africa/Windhoek"]
    },
    {
      value: "GTB Standard Time",
      abbr: "GDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Athens, Bucharest",
      utc: [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau"
      ]
    },
    {
      value: "Middle East Standard Time",
      abbr: "MEDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Beirut",
      utc: ["Asia/Beirut"]
    },
    {
      value: "Egypt Standard Time",
      abbr: "EST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Cairo",
      utc: ["Africa/Cairo"]
    },
    {
      value: "Syria Standard Time",
      abbr: "SDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Damascus",
      utc: ["Asia/Damascus"]
    },
    {
      value: "E. Europe Standard Time",
      abbr: "EEDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) E. Europe",
      utc: [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Nicosia",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye"
      ]
    },
    {
      value: "South Africa Standard Time",
      abbr: "SAST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Harare, Pretoria",
      utc: [
        "Africa/Blantyre",
        "Africa/Bujumbura",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Kigali",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Etc/GMT-2"
      ]
    },
    {
      value: "FLE Standard Time",
      abbr: "FDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      utc: [
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye"
      ]
    },
    {
      value: "Turkey Standard Time",
      abbr: "TDT",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Istanbul",
      utc: ["Europe/Istanbul"]
    },
    {
      value: "Israel Standard Time",
      abbr: "JDT",
      offset: 3,
      isdst: true,
      text: "(UTC+02:00) Jerusalem",
      utc: ["Asia/Jerusalem"]
    },
    {
      value: "Libya Standard Time",
      abbr: "LST",
      offset: 2,
      isdst: false,
      text: "(UTC+02:00) Tripoli",
      utc: ["Africa/Tripoli"]
    },
    {
      value: "Jordan Standard Time",
      abbr: "JST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Amman",
      utc: ["Asia/Amman"]
    },
    {
      value: "Arabic Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Baghdad",
      utc: ["Asia/Baghdad"]
    },
    {
      value: "Kaliningrad Standard Time",
      abbr: "KST",
      offset: 3,
      isdst: false,
      text: "(UTC+02:00) Kaliningrad",
      utc: ["Europe/Kaliningrad"]
    },
    {
      value: "Arab Standard Time",
      abbr: "AST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Kuwait, Riyadh",
      utc: [
        "Asia/Aden",
        "Asia/Bahrain",
        "Asia/Kuwait",
        "Asia/Qatar",
        "Asia/Riyadh"
      ]
    },
    {
      value: "E. Africa Standard Time",
      abbr: "EAST",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Nairobi",
      utc: [
        "Africa/Addis_Ababa",
        "Africa/Asmera",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Mogadishu",
        "Africa/Nairobi",
        "Antarctica/Syowa",
        "Etc/GMT-3",
        "Indian/Antananarivo",
        "Indian/Comoro",
        "Indian/Mayotte"
      ]
    },
    {
      value: "Moscow Standard Time",
      abbr: "MSK",
      offset: 3,
      isdst: false,
      text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
      utc: [
        "Europe/Kirov",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Minsk"
      ]
    },
    {
      value: "Samara Time",
      abbr: "SAMT",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Samara, Ulyanovsk, Saratov",
      utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"]
    },
    {
      value: "Iran Standard Time",
      abbr: "IDT",
      offset: 4.5,
      isdst: true,
      text: "(UTC+03:30) Tehran",
      utc: ["Asia/Tehran"]
    },
    {
      value: "Arabian Standard Time",
      abbr: "AST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Abu Dhabi, Muscat",
      utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"]
    },
    {
      value: "Azerbaijan Standard Time",
      abbr: "ADT",
      offset: 5,
      isdst: true,
      text: "(UTC+04:00) Baku",
      utc: ["Asia/Baku"]
    },
    {
      value: "Mauritius Standard Time",
      abbr: "MST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Port Louis",
      utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"]
    },
    {
      value: "Georgian Standard Time",
      abbr: "GET",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Tbilisi",
      utc: ["Asia/Tbilisi"]
    },
    {
      value: "Caucasus Standard Time",
      abbr: "CST",
      offset: 4,
      isdst: false,
      text: "(UTC+04:00) Yerevan",
      utc: ["Asia/Yerevan"]
    },
    {
      value: "Afghanistan Standard Time",
      abbr: "AST",
      offset: 4.5,
      isdst: false,
      text: "(UTC+04:30) Kabul",
      utc: ["Asia/Kabul"]
    },
    {
      value: "West Asia Standard Time",
      abbr: "WAST",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Ashgabat, Tashkent",
      utc: [
        "Antarctica/Mawson",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Dushanbe",
        "Asia/Oral",
        "Asia/Samarkand",
        "Asia/Tashkent",
        "Etc/GMT-5",
        "Indian/Kerguelen",
        "Indian/Maldives"
      ]
    },
    {
      value: "Yekaterinburg Time",
      abbr: "YEKT",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Yekaterinburg",
      utc: ["Asia/Yekaterinburg"]
    },
    {
      value: "Pakistan Standard Time",
      abbr: "PKT",
      offset: 5,
      isdst: false,
      text: "(UTC+05:00) Islamabad, Karachi",
      utc: ["Asia/Karachi"]
    },
    {
      value: "India Standard Time",
      abbr: "IST",
      offset: 5.5,
      isdst: false,
      text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      utc: ["Asia/Kolkata", "Asia/Calcutta"]
    },
    {
      value: "Sri Lanka Standard Time",
      abbr: "SLST",
      offset: 5.5,
      isdst: false,
      text: "(UTC+05:30) Sri Jayawardenepura",
      utc: ["Asia/Colombo"]
    },
    {
      value: "Nepal Standard Time",
      abbr: "NST",
      offset: 5.75,
      isdst: false,
      text: "(UTC+05:45) Kathmandu",
      utc: ["Asia/Kathmandu"]
    },
    {
      value: "Central Asia Standard Time",
      abbr: "CAST",
      offset: 6,
      isdst: false,
      text: "(UTC+06:00) Nur-Sultan (Astana)",
      utc: [
        "Antarctica/Vostok",
        "Asia/Almaty",
        "Asia/Bishkek",
        "Asia/Qyzylorda",
        "Asia/Urumqi",
        "Etc/GMT-6",
        "Indian/Chagos"
      ]
    },
    {
      value: "Bangladesh Standard Time",
      abbr: "BST",
      offset: 6,
      isdst: false,
      text: "(UTC+06:00) Dhaka",
      utc: ["Asia/Dhaka", "Asia/Thimphu"]
    },
    {
      value: "Myanmar Standard Time",
      abbr: "MST",
      offset: 6.5,
      isdst: false,
      text: "(UTC+06:30) Yangon (Rangoon)",
      utc: ["Asia/Rangoon", "Indian/Cocos"]
    },
    {
      value: "SE Asia Standard Time",
      abbr: "SAST",
      offset: 7,
      isdst: false,
      text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      utc: [
        "Antarctica/Davis",
        "Asia/Bangkok",
        "Asia/Hovd",
        "Asia/Jakarta",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Saigon",
        "Asia/Vientiane",
        "Etc/GMT-7",
        "Indian/Christmas"
      ]
    },
    {
      value: "N. Central Asia Standard Time",
      abbr: "NCAST",
      offset: 7,
      isdst: false,
      text: "(UTC+07:00) Novosibirsk",
      utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"]
    },
    {
      value: "China Standard Time",
      abbr: "CST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"]
    },
    {
      value: "North Asia Standard Time",
      abbr: "NAST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Krasnoyarsk",
      utc: ["Asia/Krasnoyarsk"]
    },
    {
      value: "Singapore Standard Time",
      abbr: "MPST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Kuala Lumpur, Singapore",
      utc: [
        "Asia/Brunei",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Singapore",
        "Etc/GMT-8"
      ]
    },
    {
      value: "W. Australia Standard Time",
      abbr: "WAST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Perth",
      utc: ["Antarctica/Casey", "Australia/Perth"]
    },
    {
      value: "Taipei Standard Time",
      abbr: "TST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Taipei",
      utc: ["Asia/Taipei"]
    },
    {
      value: "Ulaanbaatar Standard Time",
      abbr: "UST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Ulaanbaatar",
      utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"]
    },
    {
      value: "North Asia East Standard Time",
      abbr: "NAEST",
      offset: 8,
      isdst: false,
      text: "(UTC+08:00) Irkutsk",
      utc: ["Asia/Irkutsk"]
    },
    {
      value: "Japan Standard Time",
      abbr: "JST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
      utc: [
        "Asia/Dili",
        "Asia/Jayapura",
        "Asia/Tokyo",
        "Etc/GMT-9",
        "Pacific/Palau"
      ]
    },
    {
      value: "Korea Standard Time",
      abbr: "KST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Seoul",
      utc: ["Asia/Pyongyang", "Asia/Seoul"]
    },
    {
      value: "Cen. Australia Standard Time",
      abbr: "CAST",
      offset: 9.5,
      isdst: false,
      text: "(UTC+09:30) Adelaide",
      utc: ["Australia/Adelaide", "Australia/Broken_Hill"]
    },
    {
      value: "AUS Central Standard Time",
      abbr: "ACST",
      offset: 9.5,
      isdst: false,
      text: "(UTC+09:30) Darwin",
      utc: ["Australia/Darwin"]
    },
    {
      value: "E. Australia Standard Time",
      abbr: "EAST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Brisbane",
      utc: ["Australia/Brisbane", "Australia/Lindeman"]
    },
    {
      value: "AUS Eastern Standard Time",
      abbr: "AEST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Canberra, Melbourne, Sydney",
      utc: ["Australia/Melbourne", "Australia/Sydney"]
    },
    {
      value: "West Pacific Standard Time",
      abbr: "WPST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Guam, Port Moresby",
      utc: [
        "Antarctica/DumontDUrville",
        "Etc/GMT-10",
        "Pacific/Guam",
        "Pacific/Port_Moresby",
        "Pacific/Saipan",
        "Pacific/Truk"
      ]
    },
    {
      value: "Tasmania Standard Time",
      abbr: "TST",
      offset: 10,
      isdst: false,
      text: "(UTC+10:00) Hobart",
      utc: ["Australia/Currie", "Australia/Hobart"]
    },
    {
      value: "Yakutsk Standard Time",
      abbr: "YST",
      offset: 9,
      isdst: false,
      text: "(UTC+09:00) Yakutsk",
      utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"]
    },
    {
      value: "Central Pacific Standard Time",
      abbr: "CPST",
      offset: 11,
      isdst: false,
      text: "(UTC+11:00) Solomon Is., New Caledonia",
      utc: [
        "Antarctica/Macquarie",
        "Etc/GMT-11",
        "Pacific/Efate",
        "Pacific/Guadalcanal",
        "Pacific/Kosrae",
        "Pacific/Noumea",
        "Pacific/Ponape"
      ]
    },
    {
      value: "Vladivostok Standard Time",
      abbr: "VST",
      offset: 11,
      isdst: false,
      text: "(UTC+11:00) Vladivostok",
      utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"]
    },
    {
      value: "New Zealand Standard Time",
      abbr: "NZST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Auckland, Wellington",
      utc: ["Antarctica/McMurdo", "Pacific/Auckland"]
    },
    {
      value: "UTC+12",
      abbr: "U",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Coordinated Universal Time+12",
      utc: [
        "Etc/GMT-12",
        "Pacific/Funafuti",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Nauru",
        "Pacific/Tarawa",
        "Pacific/Wake",
        "Pacific/Wallis"
      ]
    },
    {
      value: "Fiji Standard Time",
      abbr: "FST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Fiji",
      utc: ["Pacific/Fiji"]
    },
    {
      value: "Magadan Standard Time",
      abbr: "MST",
      offset: 12,
      isdst: false,
      text: "(UTC+12:00) Magadan",
      utc: [
        "Asia/Anadyr",
        "Asia/Kamchatka",
        "Asia/Magadan",
        "Asia/Srednekolymsk"
      ]
    },
    {
      value: "Kamchatka Standard Time",
      abbr: "KDT",
      offset: 13,
      isdst: true,
      text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
      utc: ["Asia/Kamchatka"]
    },
    {
      value: "Tonga Standard Time",
      abbr: "TST",
      offset: 13,
      isdst: false,
      text: "(UTC+13:00) Nuku'alofa",
      utc: [
        "Etc/GMT-13",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Tongatapu"
      ]
    },
    {
      value: "Samoa Standard Time",
      abbr: "SST",
      offset: 13,
      isdst: false,
      text: "(UTC+13:00) Samoa",
      utc: ["Pacific/Apia"]
    }
  ];

  currentCycle: any = {};
  subscriptions: Subscription[] = [];
  private basePath = `league`;
  private divisionsPath = `divisions/`;
  public landingSubPage = new BehaviorSubject<any>(false);
  public isLandingSubPage = this.landingSubPage.asObservable();

  sportFieldProgamList = [
    { id: "Football", name: "Football" },
    { id: "Spirit", name: "Spirit" },
    { id: "Board", name: "Board" }
  ];
  sportFieldProgamSectionList = [
    { id: "none", name: "None" },
    { id: "Cheer", name: "Cheer" },
    { id: "Step", name: "Step" },
    { id: "Dance", name: "Dance" }
  ];
  sportFieldPlayStatusList = [
    { id: "OK", name: "OK" },
    { id: "suspended", name: "Suspended" },
    { id: "Expelled", name: "Expelled" }
  ];
  sportFieldPaymentStatusList = [
    { id: "Outstanding Payment", name: "Outstanding Payment" },
    { id: "Paid", name: "Paid" },
    { id: "Other", name: "Other" }
  ];

  public yearbookArr : any = [
    {
      value: "true",
      label: "Yes",
    },
    {
      value: "false",
      label: "No",
    },
  ];

  static synonym = {
    image: [
      "photo",
      "image file name",
      "image name",
      "img name",
      "image",
      "photo name",
      "image_name",
      "filename",
      "image file"
    ],
    grade: ["class", "ChronoGrade", "Chrono grade", "gr"],
    salutation: ["Salutation", "Salutations", "salutations"],
    fname: [
      "First Name",
      "fname",
      "first name",
      "given name",
      "f name",
      "firstName",
      "first"
    ],
    middleName: ["mname", "middleName", "middle name"],
    lname: [
      "Family Name",
      "Surname",
      "last name",
      "lname",
      "family name",
      "familyName",
      "l name",
      "lastName",
      "last"
    ],
    suffix: ["suffix", "Suffix"],
    role: ["role"],
    photoUrl: ["photoUrl", "Image Url"],
    teacher: [
      "HR Teacher",
      "Teacher Name",
      "HRTeachername",
      "teachername",
      "teacher name",
      "homeroom teacher name",
      "homeroomteachername"
    ],
    homeRoom: ["Home Room", "homeroom"],
    id: [
      "Student ID",
      "Student Number",
      "SIN",
      "studentNumber",
      "student#",
      "student #",
      "studentId",
      "studentID",
      "org id",
      "OrgId",
      "org_Id",
      "Staff number",
      "StaffNumber",
      "StaffNum",
      "Staff Num",
      "Studentcode",
      "Student code"
    ],
    hash: ["dbhash", "db hash"],
    photoStatus: ["photoApprovalStatus", "Image status"],
    email: [
      "Email ID",
      "emailID",
      "email",
      "e-mail",
      "email address",
      "email ID"
    ],
    mobilePhone: [
      "mobile",
      "cell",
      "mobilePhone",
      "mobile Phone",
      "phone",
      "cellphone",
      "cell phone",
      "cell phone",
      "cell phone number"
    ],
    guardianPhone: ["Primary phone", "ParentPhone", "Parent Phone"],
    ASBMember: ["ASB Member", "ASB"],
    govtID: [
      "DL",
      "drivers",
      "driversLicense",
      "drivers",
      "license",
      "government ID"
    ],
    admissibilityGroup: [
      "admissible group",
      "Admissible Group",
      "Admissibility Group"
    ],
    RFID: ["RFID Code", "RF ID", "rfid"],
    schoolDepartment: ["schoolDepartment", "school Department"],
    birth: [
      "Birthdate",
      "birthday",
      "Date of birth",
      "DateOfBirth",
      "birth",
      "birth day",
      "birthday"
    ],
    company: ["company name", "employer"],
    hairColor: ["hairColor", "Hair Color", "haircolor"],
    eyeColor: ["Eye Color", "eye color", "eyecolor"],
    indID_Client: [
      "Client indID",
      "ClientIndID",
      "indID_Client",
      "subjectID",
      "subject ID"
    ],
    title: ["Title/Position", "Position", "Job title"],
    guardianEmail: ["ParentEmail", "Parent Email"],
    key: ["key", "db key", "dbkey", "DBKey"],
    guardianFullName: [
      "Guardian name",
      "GuardianName",
      "Parent Name",
      "ParentName",
      "Parent/Guardian",
      "Guardian/Parent",
      "Father",
      "Mother"
    ],
    auxID: ["Auxiliary ID", "Aux ID"],
    photoYear: [
      "photoYear",
      "PhotoYear",
      "Photo Year",
      "Image Year",
      "imageYear",
      "year",
      "cycle"
    ],
    jerseyNumber: [
      "jerseyNumber",
      "jersey",
      "jersey #",
      "#",
      "shirt number",
      "shirt #"
    ],
    yearbookCollected: [
      'yearbookCollected',
      'yearbookCollect',
      'yearbook collect',
      'yearbook collected',
      "yearbook Collect",
      "annual collected",
      "annual collect",
      "annual collected",
      "annualCollected",
      "annualCollect"
    ],
    yearbookOrdered: [
      'yearbookOrdered',
      'yearbookOrder',
      'yearbook ordered',
      "yearbook Ordered",
      "annual ordered",
      "annual ordered",
      "annualOrdered",
      "annualOrdered"
    ],
    yearbookPaid: ['yearbookPaid',
    'yearbook paid',
    "yearbook Paid",
    "annual Paid",
    "annual paid",
    "annualPaid"
  ],
  campus: [
    'campus',
    'Campus',
    "campuses",
    "camps"
  ],
  lunchPeriod: [
    'lunchPeriod',
    'lunchperiod',
    'lunch Period',
    "lunch period",
    "LunchPeriod",
    "Lunch Period"
  ],
    nickName: ["nickName", "nick name", "Nick Name", "nickName"],
    physicianName: [
      "physicianName",
      "Medical Physician Name",
      "Physician Name",
      "medicalPhysicianName",
      "medicalphysicianname",
      "physician name"
    ],
    physicianStreet: [
      "physicianStreet",
      "Medical Physician Street",
      "Physician Street",
      "physicianStreet",
      "medicalPhysicianStreet",
      "physician street"
    ],
    physicianCity: [
      "physicianCity",
      "medical physician city",
      "physician city",
      "physicianCity",
      "Physician City",
      "Medical Physician City",
      "medicalPhysicianCity"
    ],
    physicianState: [
      "physicianState",
      "Medical Physician State",
      "Physician State",
      "physician state",
      "medicalPhysicianState",
      "medicalphysicianstate"
    ],
    physicianZip: [
      "physicianZip",
      "Medical Physician Zip",
      "Physician Zip",
      "physicianZip",
      "medicalPhysicianZip",
      "medical physician zip"
    ],
    physicianPhone: [
      "physicianPhone",
      "Medical Physician Phone",
      "Physician Phone",
      "medicalPhysicianPhone",
      "physicianPhone"
    ],
    physicianFax: [
      "physicianFax",
      "Medical Physician Fax",
      "Physician Fax",
      "physician fax",
      "medicalPhysicianFax",
      "MedicalphysicianFax"
    ],
    physicianEmail: [
      "physicianEmail",
      "MedicalPhysicianEmail",
      "Medical Physician Email",
      "Physician Email",
      "medicalPhysicianEmail"
    ],
    medPrefHospital: [
      "medPrefHospital",
      "Medical Preferred Hospital",
      "Preferred Hospital",
      "medical preferred hospital",
      "medicalPrefrredHospital"
    ],
    medAllergies: [
      "medAllergies",
      "Medical Allergies",
      "medical allergies",
      "medicalAllergies",
      "MedicalAllergies",
      "medicalallergies"
    ],
    medOtherNotes: [
      "medOtherNotes",
      "medical other notes",
      "medicalOtherNotes",
      "Medical Other Notes",
      "medical_other_notes",
      "medicalothernotes"
    ],
    medConditions: [
      "medConditions",
      "Med Conditions",
      "medconditions",
      "medicalConditions",
      "Medical Conditions",
      "medicalconditions",
      "MedicalConditions",
      "medical conditions"
    ],
    hasInsurance: [
      "hasInsurance",
      "hasInsurance",
      "hasInsurance",
      "Has Insurance",
      "hasinsurance"
    ],
    insuranceCarrierName: [
      "insuranceCarrierName",
      "insurance carrier name",
      "insuranceCarrierName",
      "Insurance Carrier Name",
      "InsuranceCarrierName"
    ],
    insuranceGroup: [
      "insuranceGroup",
      "Insurance Group",
      "InsuranceGroup",
      "insurance group",
      "insuranceGroup",
      "insurancegroup"
    ],
    insurancePolicyNumber: [
      "insurancePolicyNumber",
      "Insurance Policy Number",
      "Insurance Policy No",
      "insurance policy number",
      "insurance policy no"
    ],
    insuranceGroupNumber: [
      "insuranceGroupNumber",
      "insurance group number",
      "Insurance Group Number",
      "insurance group no",
      "Insurance Group No"
    ],
    insurancePolicyHolderName: [
      "insurancePolicyHolderName",
      "Insurance Policy Holder Name",
      "insurance policy holder name",
      "insurance holder name",
      "policy holder name"
    ],
    schoolName: [
      "schoolName",
      "School Name",
      "school name",
      "schoolname",
      "nameschool",
      "nameSchool",
      "name school",
      "nameSchool",
      "Name School"
    ],
    schoolDistrict: [
      "schoolDistrict",
      "School District",
      "school district",
      "schooldistrict",
      "district school",
      "District School",
      "districtSchool"
    ],
    emergencyCon1Name: [
      "emergencyCon1Name",
      "emergency con 1 name",
      "contact 1 name"
    ],
    emergencyCon1Phone: [
      "emergencyCon1Phone",
      "emergency con 1 phone",
      "contact 2 phone"
    ],
    emergencyCon1Relationship: [
      "emergencyCon1Relationship",
      "emergency con 1 relationship",
      "contact 1 relationship"
    ],
    emergencyCon2Name: [
      "emergencyCon2Name",
      "emergency con 2 name",
      "contact 2 name"
    ],
    emergencyCon2Phone: [
      "emergencyCon2Phone",
      "emergency con 2 phone",
      "contact 2 phone"
    ],
    emergencyCon2Relationship: [
      "emergencyCon2Relationship",
      "emergency con 2 relationship",
      "contact 2 relationship"
    ],
    employer: ["employer", "Employer"],
    feesRegistration: ["feesRegistration", "fees_registration"],
    feesSeason: ["feesSeason", "fees_season"],
   
   
    accessBus_mode: ["accessBus_mode", "accessbusmode", "accessBusMode", "access Bus mode", "access_Bus_mode", "Access Bus mode",],
    accessBus_endDateTime: ['access Bus End Date Time', 'accessBus endDateTime', 'accessBus_EndDateTime','accessBus_endDateTime','accessbusenddatetime','accessbus_enddatetime','access_Bus_endDateTime','access_Bus_end_Date_Time', 'Access Bus EndDateTime'],
    accessBus_startDateTime: ['access Bus Start Date Time', 'accessBus startDateTime', 'accessBus_StartDateTime','accessBus_startDateTime','accessbusstartdatetime','accessbus_startdatetime','access_Bus_startDateTime','access_Bus_start_Date_Time', 'Access Bus StartDateTime'],
    accessBus_validAfterDateTime: ['access Bus Valid After Date Time', 'accessBus validafterDateTime', 'accessBus_validAfterDateTime','accessBus_ValidAfterDateTime','accessbusvalidafterdatetime','accessbus_validafterdatetime','accessBus_Valid_AfterDateTime','access_Bus_Valid_After_Date_Time', 'Access Bus ValidAfterDateTime'],
    accessBus_invalidAfterDateTime: ['access Bus Invalid After Date Time', 'accessBus invalidafterDateTime', 'accessBus_invalidAfterDateTime','accessBus_InvalidAfterDateTime','accessbusinvalidafterdatetime','accessbus_invalidafterdatetime','accessBus_Invalid_AfterDateTime','access_Bus_Invalid_After_Date_Time', 'Access Bus InvalidAfterDateTime'],
    
    accessCampus_mode: ["accessCampus_mode", "accesscampusmode", "accessCampusMode", "access Campus mode", "access_Campus_mode", "Access Campus mode", "Admissible in person mode", "Admissible in Person Mode", "admissibleinpersonmode"],
    accessCampus_endDateTime: ['access Campus End DateTime', 'accessCampus endDateTime', 'accessCampus_EndDateTime','accessCampus_endDateTimeTime','accesscampusenddatetime','accesscampus_enddatetime','access_Campus_endDateTime','access_Campus_end_Date_Time', 'Access Campus EndDateTime', "Admissible in person endDateTime", "Admissible in Person EndDateTime", "admissibleinpersonenddatetime"],
    accessCampus_startDateTime: ['access Campus Start Date Time', 'accessCampus startDateTime', 'accessCampus_StartDateTime','accessCampus_startDateTimeTime','accesscampusstartdatetime','accesscampus_startdatetime','access_Campus_startDateTime','access_Campus_start_Date_Time', 'Access Campus StartDateTime', "Admissible in person startDateTime", "Admissible in Person StartDateTime", "admissibleinpersonstartdatetime"],
    accessCampus_validAfterDateTime: ['access Campus Valid After Date Time', 'accessCampus validafterDateTime', 'accessCampus_validAfterDateTime','accessCampus_ValidAfterDateTime','accesscampusvalidafterdatetime','accesscampus_validafterdatetime','accessCampus_Valid_AfterDateTime','access_Campus_Valid_After_Date_Time', 'Access Campus ValidAfterDateTime', "Admissible in person ValidAfterDateTime", "Admissible in Person validafterdatetime", "admissibleinpersoncalidafterdatetime"],
    accessCampus_invalidAfterDateTime: ['access Campus Invalid After Date Time', 'accessCampus invalidafterDateTime', 'accessCampus_invalidafterDateTime','accessCampus_InvalidAfterDateTime','accesscampusinvalidafterdatetime','accesscampus_invalidafterdatetime','accessCampus_Invalid_AfterDateTime','access_Campus_Invalid_After_Date_Time', 'Access Campus InvalidAfterDateTime', "Admissible in person InvalidAfterDateTime", "Admissible in Person invalidafterdatetime", "admissibleinpersoninvalidaterdatetime"],
    
    accessCafeteria_mode: ["accessCafeteria_mode", "accesscafeteriamode", "accessCafeteriaMode", "access Cafeteria mode", "access_Cafeteria_mode", "Access Cafeteria mode", 'Lunch period mode', 'Lunch Period Mode'],
    accessCafeteria_endDateTime: ['access Cafeteria End DateTime', 'accessCafeteria endDateTime', 'accessCafeteria_EndDateTime','accessCafeteria_endDateTimeTime','accesscafeteriaenddatetime','accesscafeteria_enddatetime','access_Cafeteria_endDateTime','access_Cafeteria_end_Date_Time', 'Access Cafeteria EndDateTime', 'Lunch period endDateTime', 'Lunch Period EndDateTime'],
    accessCafeteria_startDateTime: ['access Cafeteria Start Date Time', 'accessCafeteria startDateTime', 'accessCafeteria_StartDateTime','accessCafeteria_startDateTimeTime','accesscafeteriastartdatetime','accesscafeteria_startdatetime','access_Cafeteria_startDateTime','access_Cafeteria_start_Date_Time', 'Access Cafeteria StartDateTime', 'Lunch period startDateTime', 'Lunch Period StartDateTime'],
    accessCafeteria_validAfterDateTime: ['access Cafeteria Valid After Date Time', 'accessCafeteria validafterDateTime', 'accessCafeteria_validAfterDateTime','accessCafeteria_ValidAfterDateTime','accesscafeteriavalidafterdatetime','accesscafeteria_validafterdatetime','accessCafeteria_Valid_AfterDateTime','access_Cafeteria_Valid_After_Date_Time', 'Access Cafeteria ValidAfterDateTime', 'Lunch period validAfetrDateTime', 'Lunch Period ValidAfterDateTime'],
    accessCafeteria_invalidAfterDateTime: ['access Cafeteria Invalid After Date Time', 'accessCafeteria invalidafterDateTime', 'accessCafeteria_invalidafterDateTime','accessCafeteria_InvalidAfterDateTime','accesscafeteriainvalidafterdatetime','accesscafeteria_invalidafterdatetime','accessCafeteria_Invalid_AfterDateTime','access_Cafeteria_Invalid_After_Date_Time', 'Access Cafeteria InvalidAfterDateTime', 'Lunch period invalidAfterDateTime', 'Lunch Period InvalidAfterDateTime'],
    
    idValid_mode: ["idValid_mode", "idvalidiamode", "idValidMode", "id Valid mode", "id_Valid_mode", "Id Valid mode", 'IdStatus', 'Id Status', 'IdStatus Mode'],
    idValid_endDateTime: ['id Valid End DateTime', 'idValid endDateTime', 'idValid_EndDateTime','idValid_endDateTimeTime','idvalidiaenddatetime','idvalidia_enddatetime','id_Valid_endDateTime','id_Valid_end_Date_Time', 'IdValid EndDateTime', 'Id Status EndDateTime', 'IdStatus EndDateTime'],
    idValid_startDateTime: ['id Valid Start Date Time', 'idValid startDateTime', 'idValid_StartDateTime','idValid_startDateTimeTime','idvalidiastartdatetime','idvalidia_startdatetime','id_Valid_startDateTime','id_Valid_start_Date_Time', 'IdValid StartDateTime', 'Id Status StartDateTime', 'IdStatus StartDateTime'],
    idValid_validAfterDateTime: ['id Valid Valid After Date Time', 'idValid validafterDateTime', 'idValid_validAfterDateTime','idValid_ValidAfterDateTime','idvalidiavalidafterdatetime','idvalidia_validafterdatetime','idValid_Valid_AfterDateTime','id_Valid_Valid_After_Date_Time', 'IdValid ValidAfterDateTime', 'Id Status ValidAfterDateTime', 'IdStatus ValidAfterDateTime'],
    idValid_invalidAfterDateTime: ['id Valid Invalid After Date Time', 'idValid invalidafterDateTime', 'idValid_invalidafterDateTime','idValid_InvalidAfterDateTime','idvalidiainvalidafterdatetime','idvalidia_invalidafterdatetime','idValid_Invalid_AfterDateTime','id_Valid_Invalid_After_Date_Time', 'IdValid InvalidAfterDateTime', 'Id Status InvalidAfterDateTime', 'IdStatus InvalidAfterDateTime'],
    
    accessSocial_mode: ["accessSocial_mode", "accesssocialmode", "accessSocialMode", "access Social mode", "access_Social_mode", "Access Social mode", 'No-go list mode', 'No-go List Mode'],
    accessSocial_endDateTime: ['access Social End DateTime', 'accessSocial endDateTime', 'accessSocial_EndDateTime','accessSocial_endDateTimeTime','accesssocialenddatetime','accesssocial_enddatetime','access_Social_endDateTime','access_Social_end_Date_Time', 'Access Social EndDateTime', 'No-go list endDateTime', 'No-go List EndDateTime'],
    accessSocial_startDateTime: ['access Social Start Date Time', 'accessSocial startDateTime', 'accessSocial_StartDateTime','accessSocial_startDateTimeTime','accesssocialstartdatetime','accesssocial_startdatetime','access_Social_startDateTime','access_Social_start_Date_Time', 'Access Social StartDateTime', 'No-go list startDateTime', 'No-go List StartDateTime'],
    accessSocial_validAfterDateTime: ['access Social Valid After Date Time', 'accessSocial validafterDateTime', 'accessSocial_validAfterDateTime','accessSocial_ValidAfterDateTime','accesssocialvalidafterdatetime','accesssocial_validafterdatetime','accessSocial_Valid_AfterDateTime','access_Social_Valid_After_Date_Time', 'Access Social ValidAfterDateTime', 'No-go list validAfetrDateTime', 'No-go List ValidAfterDateTime'],
    accessSocial_invalidAfterDateTime: ['access Social Invalid After Date Time', 'accessSocial invalidafterDateTime', 'accessSocial_invalidafterDateTime','accessSocial_InvalidAfterDateTime','accesssocialinvalidafterdatetime','accesssocial_invalidafterdatetime','accessSocial_Invalid_AfterDateTime','access_Social_Invalid_After_Date_Time', 'Access Social InvalidAfterDateTime', 'No-go list invalidAfterDateTime', 'No-go List InvalidAfterDateTime'],
    
    accessAthletics_mode: ["accessAthletics_mode", "accessathleticsmode", "accessAthleticsMode", "access Athletics mode", "access_Athletics_mode", "Access Athletics mode"],
    accessAthletics_endDateTime: ['access Athletics End DateTime', 'accessAthletics endDateTime', 'accessAthletics_EndDateTime','accessAthletics_endDateTimeTime','accessathleticsenddatetime','accessathletics_enddatetime','access_Athletics_endDateTime','access_Athletics_end_Date_Time', 'Access Athletics EndDateTime'],
    accessAthletics_startDateTime: ['access Athletics Start Date Time', 'accessAthletics startDateTime', 'accessAthletics_StartDateTime','accessAthletics_startDateTimeTime','accessathleticsstartdatetime','accessathletics_startdatetime','access_Athletics_startDateTime','access_Athletics_start_Date_Time', 'Access Athletics StartDateTime'],
    accessAthletics_validAfterDateTime: ['access Athletics Valid After Date Time', 'accessAthletics validafterDateTime', 'accessAthletics_validAfterDateTime','accessAthletics_ValidAfterDateTime','accessathleticsvalidafterdatetime','accessathletics_validafterdatetime','accessAthletics_Valid_AfterDateTime','access_Athletics_Valid_After_Date_Time', 'Access Athletics ValidAfterDateTime'],
    accessAthletics_invalidAfterDateTime: ['access Athletics Invalid After Date Time', 'accessAthletics invalidafterDateTime', 'accessAthletics_invalidafterDateTime','accessAthletics_InvalidAfterDateTime','accessathleticsinvalidafterdatetime','accessathletics_invalidafterdatetime','accessAthletics_Invalid_AfterDateTime','access_Athletics_Invalid_After_Date_Time', 'Access Athletics InvalidAfterDateTime'],
    
    permissionToLeave_mode: ["permissionToLeave_mode", "permissiontoleavemode", "permissionToLeaveMode", "permission To Leave mode", "permission_To_Leave_mode", "Permission To Leave mode", 'Off-campus lunch mode', 'Off-campus Lunch Mode'],
    permissionToLeave_endDateTime: ['permission To Leave End DateTime', 'permissionToLeave endDateTime', 'permissionToLeave_EndDateTime','permissionToLeave_endDateTimeTime','permissiontoleaveenddatetime','permissiontoleave_enddatetime','permission_To_Leave_endDateTime','permission_To_Leave_end_Date_Time', 'Permission To Leave EndDateTime', 'Off-campus lunch endDateTime', 'Off-campus Lunch EndDateTime'],
    permissionToLeave_startDateTime: ['permission To Leave Start Date Time', 'permissionToLeave startDateTime', 'permissionToLeave_StartDateTime','permissionToLeave_startDateTimeTime','permissiontoleavestartdatetime','permissiontoleave_startdatetime','permission_To_Leave_startDateTime','permission_To_Leave_start_Date_Time', 'Permission To Leave StartDateTime', 'Off-campus lunch startDateTime', 'Off-campus Lunch StartDateTime'],
    permissionToLeave_validAfterDateTime: ['permission To Leave Valid After Date Time', 'permissionToLeave validafterDateTime', 'permissionToLeave_validAfterDateTime','permissionToLeave_ValidAfterDateTime','permissiontoleavevalidafterdatetime','permissiontoleave_validafterdatetime','permissionToLeave_Valid_AfterDateTime','permission_To_Leave_Valid_After_Date_Time', 'Permission To Leave ValidAfterDateTime', 'Off-campus lunch validAfterDateTime', 'Off-campus Lunch ValidAfterDateTime'],
    permissionToLeave_invalidAfterDateTime: ['permission To Leave Invalid After Date Time', 'permissionToLeave invalidafterDateTime', 'permissionToLeave_invalidafterDateTime','permissionToLeave_InvalidAfterDateTime','permissiontoleaveinvalidafterdatetime','permissiontoleave_invalidafterdatetime','permissionToLeave_Invalid_AfterDateTime','permission_To_Leave_Invalid_After_Date_Time', 'Permission To Leave InvalidAfterDateTime', 'Off-campus lunch invalidAfterDateTime', 'Off-campus Lunch InvalidAfterDateTime'],
    
    accessParking_mode: ["accessParking_mode", "accessparkingmode", "accessParkingMode", "access Parking mode", "access_Parking_mode", "Access Parking mode"],
    accessParking_endDateTime: ['access Parking End DateTime', 'accessParking endDateTime', 'accessParking_EndDateTime','accessParking_endDateTimeTime','accessparkingenddatetime','accessparking_enddatetime','access_Parking_endDateTime','access_Parking_end_Date_Time', 'Access Parking EndDateTime'],
    accessParking_startDateTime: ['access Parking Start Date Time', 'accessParking startDateTime', 'accessParking_StartDateTime','accessParking_startDateTimeTime','accessparkingstartdatetime','accessparking_startdatetime','access_Parking_startDateTime','access_Parking_start_Date_Time', 'Access Parking StartDateTime'],
    accessParking_validAfterDateTime: ['access Parking Valid After Date Time', 'accessParking validafterDateTime', 'accessParking_validAfterDateTime','accessParking_ValidAfterDateTime','accessparkingvalidafterdatetime','accessparking_validafterdatetime','accessParking_Valid_AfterDateTime','access_Parking_Valid_After_Date_Time', 'Access Parking ValidAfterDateTime'],
    accessParking_invalidAfterDateTime: ['access Parking Invalid After Date Time', 'accessParking invalidafterDateTime', 'accessParking_invalidafterDateTime','accessParking_InvalidAfterDateTime','accessparkinginvalidafterdatetime','accessparking_invalidafterdatetime','accessParking_Invalid_AfterDateTime','access_Parking_Invalid_After_Date_Time', 'Access Parking InvalidAfterDateTime'],
    
    permissionFlexSchedule_mode: ["permissionFlexSchedule_mode", "permissionflexschedulemode", "permissionFlexScheduleMode", "permission Flex Schedule mode", "permission_Flex_Schedule_mode", "Access Parking mode", 'Short schedule mode', 'Short Schedule Mode'],
    permissionFlexSchedule_endDateTime: ['permission Flex Schedule End DateTime', 'permissionFlexSchedule endDateTime', 'permissionFlexSchedule_EndDateTime','permissionFlexSchedule_endDateTimeTime','permissionflexscheduleenddatetime','permissionflexschedule_enddatetime','permission_Flex_Schedule_endDateTime','permission_Flex_Schedule_end_Date_Time', 'Permission Flex Schedule EndDateTime', 'Short schedule endDateTime', 'Short Schedule EndDateTime', 'Short schedule mode', 'Short Schedule Mode'],
    permissionFlexSchedule_startDateTime: ['permission Flex Schedule Start Date Time', 'permissionFlexSchedule startDateTime', 'permissionFlexSchedule_StartDateTime','permissionFlexSchedule_startDateTimeTime','permissionflexschedulestartdatetime','permissionflexschedule_startdatetime','permission_Flex_Schedule_startDateTime','permission_Flex_Schedule_start_Date_Time', 'Permission Flex Schedule StartDateTime', 'Short schedule startDateTime', 'Short Schedule StartDateTime'],
    permissionFlexSchedule_validAfterDateTime: ['permission Flex Schedule Valid After Date Time', 'permissionFlexSchedule validafterDateTime', 'permissionFlexSchedule_validAfterDateTime','permissionFlexSchedule_ValidAfterDateTime','permissionflexschedulevalidafterdatetime','permissionflexschedule_validafterdatetime','permissionFlexSchedule_Valid_AfterDateTime','permission_Flex_Schedule_Valid_After_Date_Time', 'Permission Flex Schedule ValidAfterDateTime', 'Short schedule validAfterDateTime', 'Short Schedule ValidAfterDateTime'],
    permissionFlexSchedule_invalidAfterDateTime: ['permission Flex Schedule Invalid After Date Time', 'permissionFlexSchedule invalidafterDateTime', 'permissionFlexSchedule_invalidafterDateTime','permissionFlexSchedule_InvalidAfterDateTime','permissionflexscheduleinvalidafterdatetime','permissionflexschedule_invalidafterdatetime','permissionFlexSchedule_Invalid_AfterDateTime','permission_Flex_Schedule_Invalid_After_Date_Time', 'Permission Flex Schedule InvalidAfterDateTime', 'Short schedule invalidAfterDateTime', 'Short Schedule InvalidAfterDateTime'],
    
  };

  static getFieldMapperColumns(type: string) {
    let synonym = CommonServiceService.synonym;
    switch (type) {
      case "school":
        return [
          {
            name: "key",
            property: "key",
            title: "DB key",
            synonyms: synonym.key
          },
          {
            name: "salutation",
            property: "salutation",
            title: "Salutation",
            synonyms: synonym.salutation
          },
          {
            name: "fname",
            property: "firstName",
            title: "First Name",
            synonyms: synonym.fname
          },
          {
            name: "middleName",
            property: "middleName",
            title: "Middle Name",
            synonyms: synonym.middleName
          },
          {
            name: "nickName",
            property: "nickName",
            title: "Nick Name",
            synonyms: synonym.nickName
          },
          {
            name: "lname",
            property: "lastName",
            title: "Last Name",
            synonyms: synonym.lname
          },
          {
            name: "suffix",
            property: "suffix",
            title: "Suffix",
            synonyms: synonym.suffix
          },
          {
            name: "role",
            property: "role",
            title: "Role",
            synonyms: synonym.role
          },
          {
            name: "status",
            property: "status",
            title: "status",
            synonyms: []
          },
          {
            name: "grade",
            property: "class",
            title: "Grade",
            synonyms: synonym.grade
          },
          {
            name: "homeRoom",
            property: "homeRoom",
            title: "Home room",
            synonyms: synonym.homeRoom
          },
          {
            name: "period",
            property: "period",
            title: "Period",
            synonyms: []
          },
          {
            name: "track",
            property: "track",
            title: "Track",
            synonyms: []
          },
          {
            name: "photoUrl",
            property: "photoUrl",
            title: "Image Url",
            synonyms: synonym.photoUrl
          },
          {
            name: "teacher",
            property: "teacher",
            title: "Teacher",
            synonyms: synonym.teacher
          },
          {
            name: "id",
            property: "studentID",
            title: "Organization ID",
            synonyms: synonym.id
          },
          {
            name: "hash",
            property: "hash",
            title: "DB Hash",
            synonyms: synonym.hash
          },
          {
            name: "image",
            property: "photo",
            title: "Photo filename",
            synonyms: synonym.image
          },
          {
            name: "photoStatus",
            property: "photoStatus",
            title: "Photo Approval Status",
            synonyms: synonym.photoStatus
          },
          {
            name: "email",
            property: "email",
            title: "Email",
            synonyms: synonym.email
          },
          {
            name: "mobilePhone",
            property: "mobilePhone",
            title: "Mobile Phone",
            synonyms: synonym.mobilePhone
          },
          {
            name: "guardianFullName",
            property: "guardianFullName",
            title: "Guardian",
            synonyms: synonym.guardianFullName
          },
          {
            name: "guardianEmail",
            property: "guardianEmail",
            title: "Guardian Email",
            synonyms: synonym.guardianEmail
          },
          {
            name: "guardianPhone",
            property: "guardianPhone",
            title: "Guardian Phone",
            synonyms: synonym.guardianPhone
          },

          {
            name: "permissionToLeave",
            property: "permissionToLeave",
            title: "Permission To Leave",
            synonyms: []
          },
          {
            name: "ASBMember",
            property: "ASBMember",
            title: "ASB Member",
            synonyms: synonym.ASBMember
          },
          {
            name: "govtID",
            property: "govtID",
            title: "Government-issued ID",
            synonyms: synonym.govtID
          },
          {
            name: "admissibilityGroup",
            property: "admissibilityGroup",
            title: "Admissibility Group",
            synonyms: synonym.admissibilityGroup
          },
          {
            name: "RFID",
            property: "RFID",
            title: "RFID Code",
            synonyms: synonym.RFID
          },
          {
            name: "group",
            property: "group",
            title: "Group",
            synonyms: []
          },
          {
            name: "department",
            property: "department",
            title: "Department",
            synonyms: []
          },
          {
            name: "schoolDepartment",
            property: "schoolDepartment",
            title: "School Department",
            synonyms: synonym.schoolDepartment
          },
          {
            name: "title",
            property: "title",
            title: "Title",
            synonyms: synonym.title
          },
          {
            name: "section",
            property: "section",
            title: "section",
            synonyms: []
          },
          {
            name: "custom1",
            property: "custom1",
            title: "Custom1",
            synonyms: []
          },
          {
            name: "custom2",
            property: "custom2",
            title: "Custom2",
            synonyms: []
          },
          {
            name: "custom3",
            property: "custom3",
            title: "Custom3",
            synonyms: []
          },
          {
            name: "lockerNumber",
            property: "lockerNumber",
            title: "Locker Number",
            synonyms: []
          },
          {
            name: "lockerCombination",
            property: "lockerCombination",
            title: "Locker Combination",
            synonyms: []
          },
          {
            name: "teammatePURL",
            property: "teammatePURL",
            title: "TeammatePURL",
            synonyms: []
          },
          {
            name: "birth",
            property: "birth",
            title: "DateOfBirth",
            synonyms: synonym.birth
          },
          {
            name: "company",
            property: "company",
            title: "Company",
            synonyms: synonym.company
          },
          {
            name: "weight",
            property: "weight",
            title: "Weight",
            synonyms: []
          },
          {
            name: "height1",
            property: "height1",
            title: "Height (feet)",
            synonyms: []
          },
          {
            name: "height2",
            property: "height2",
            title: "Height (inches)",
            synonyms: []
          },
          {
            name: "hairColor",
            property: "hairColor",
            title: "Hair Color",
            synonyms: synonym.hairColor
          },
          {
            name: "eyeColor",
            property: "eyeColor",
            title: "Eye Color",
            synonyms: synonym.eyeColor
          },
          {
            name: "indID_Client",
            property: "indID_Client",
            title: "Client indID",
            synonyms: synonym.indID_Client
          },
          {
            name: "auxID",
            property: "auxID",
            title: "Auxiliary ID",
            synonyms: synonym.auxID
          },
          {
            name: "photoYear",
            property: "photoYear",
            title: "Photo Year",
            synonyms: synonym.photoYear
          },
          {
            name: "yearbookOrdered",
            property: "yearbookOrdered",
            title: "Yearbook Ordered",
            synonyms: synonym.yearbookOrdered
          },
          {
            name: "yearbookPaid",
            property: "yearbookPaid",
            title: "Yearbook Paid",
            synonyms: synonym.yearbookPaid
          },
          {
            name: "yearbookCollected",
            property: "yearbookCollected",
            title: "Yearbook Collected",
            synonyms: synonym.yearbookCollected
          },
          {
            name: "campus",
            property: "campus",
            title: "Campus",
            synonyms: synonym.campus
          },
          {
            name: "lunchPeriod",
            property: "lunchPeriod",
            title: "Lunch Period",
            synonyms: synonym.lunchPeriod
          },
          {
            name: "accessBus_startDateTime",
            property: 'accessBus_startDateTime',
            title: "Bus Pass Start",
            synonyms: synonym.accessBus_startDateTime
          },
          {
            name: "accessBus_endDateTime",
            property: 'accessBus_endDateTime',
            title: "Bus Pass End",
            synonyms: synonym.accessBus_endDateTime
          },
          {
            name: "accessBus_invalidAfterDateTime",
            property: 'accessBus_invalidAfterDateTime',
            title: "Bus Pass Not Valid after Time",
            synonyms: synonym.accessBus_invalidAfterDateTime
          },
          {
            name: "accessBus_validAfterDateTime",
            property: 'accessBus_validAfterDateTime',
            title: "Bus Pass Not Valid Before Time",
            synonyms: synonym.accessBus_validAfterDateTime
          },
          {
            name: "accessBus_mode",
            property: 'accessBus_mode',
            title: "Bus Pass Valid",
            synonyms: synonym.accessBus_mode
          },
          {
            name: "accessCafeteria_mode",
            property: 'accessCafeteria_mode',
            title: "Access Cafeteria",
            synonyms: synonym.accessCafeteria_mode
          },
          {
            name: "accessCafeteria_endDateTime",
            property: 'accessCafeteria_endDateTime',
            title: "Access Cafeteria End Date",
            synonyms: synonym.accessCafeteria_endDateTime
          },
          {
            name: "accessCafeteria_startDateTime",
            property: 'accessCafeteria_startDateTime',
            title: "Access Cafeteria Start Date",
            synonyms: synonym.accessCafeteria_startDateTime
          },
          {
            name: "accessCafeteria_validAfterDateTime",
            property: 'accessCafeteria_validAfterDateTime',
            title: "Access Cafeteria Valid after",
            synonyms: synonym.accessCafeteria_validAfterDateTime
          },
          {
            name: "accessCafeteria_invalidAfterDateTime",
            property: 'accessCafeteria_invalidAfterDateTime',
            title: "Access Cafeteria Not Valid after",
            synonyms: synonym.accessCafeteria_invalidAfterDateTime
          },
          {
            name: "accessCampus_mode",
            property: 'accessCampus_mode',
            title: "admissible in Person",
            synonyms: synonym.accessCampus_mode
          },
          {
            name: "accessCampus_startDateTime",
            property: 'accessCampus_startDateTime',
            title: "admissible Person start time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_endDateTime",
            property: 'accessCampus_endDateTime',
            title: "admissible Person End time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_invalidAfterDateTime",
            property: 'accessCampus_invalidAfterDateTime',
            title: "admissible Person Not Valid after Time",
            synonyms: synonym.accessCampus_invalidAfterDateTime
          },
          {
            name: "accessCampus_validAfterDateTime",
            property: 'accessCampus_validAfterDateTime',
            title: "admissible Person Not Valid Before Time",
            synonyms: synonym.accessCampus_validAfterDateTime
          },
          {
            name: "idValid_startDateTime",
            property: 'idValid_startDateTime',
            title: "ID Status Start",
            synonyms: synonym.idValid_startDateTime
          },
          {
            name: "idValid_endDateTime",
            property: 'idValid_endDateTime',
            title: "ID Status End",
            synonyms: synonym.idValid_endDateTime
          },
          {
            name: "idValid_invalidAfterDateTime",
            property: 'idValid_invalidAfterDateTime',
            title: "ID Status Not Valid after Time",
            synonyms: synonym.idValid_invalidAfterDateTime
          },
          {
            name: "idValid_validAfterDateTime",
            property: 'idValid_validAfterDateTime',
            title: "ID Status Not Valid Before Time",
            synonyms: synonym.idValid_validAfterDateTime
          },
          {
            name: "idValid_mode",
            property: 'idValid_mode',
            title: "ID Status",
            synonyms: synonym.idValid_mode
          },
          {
            name: "accessSocial_mode",
            property: 'accessSocial_mode',
            title: "Social Access",
            synonyms: synonym.accessSocial_mode
          },
          {
            name: "accessSocial_endDateTime",
            property: 'accessSocial_endDateTime',
            title: "Social Access End",
            synonyms: synonym.accessSocial_endDateTime
          },
          {
            name: "accessSocial_startDateTime",
            property: 'accessSocial_startDateTime',
            title: "Social Access Start",
            synonyms: synonym.accessSocial_startDateTime
          },
          {
            name: "accessSocial_validAfterDateTime",
            property: 'accessSocial_validAfterDateTime',
            title: "Social Access Not Valid Before Time",
            synonyms: synonym.accessSocial_validAfterDateTime
          },
          {
            name: "accessSocial_invalidAfterDateTime",
            property: 'accessSocial_invalidAfterDateTime',
            title: "Social Access Not Valid After Time",
            synonyms: synonym.accessSocial_invalidAfterDateTime
          },
          {
            name: "accessAthletics_mode",
            property: 'accessAthletics_mode',
            title: "Athletics Access",
            synonyms: synonym.accessAthletics_mode
          },
          {
            name: "accessAthletics_startDateTime",
            property: 'accessAthletics_startDateTime',
            title: "Athletics Access Start",
            synonyms: synonym.accessAthletics_startDateTime
          },
          {
            name: "accessAthletics_endDateTime",
            property: 'accessAthletics_endDateTime',
            title: "Athletics Access End",
            synonyms: synonym.accessAthletics_endDateTime
          },
          {
            name: "accessAthletics_validAfterDateTime",
            property: 'accessAthletics_validAfterDateTime',
            title: "Athletics Access Not Valid Before Time",
            synonyms: synonym.accessAthletics_validAfterDateTime
          },
          {
            name: "accessAthletics_invalidAfterDateTime",
            property: 'accessAthletics_invalidAfterDateTime',
            title: "Athletics Access Not Valid after Time",
            synonyms: synonym.accessAthletics_invalidAfterDateTime
          },
          {
            name: "permissionToLeave_mode",
            property: 'permissionToLeave_mode',
            title: "Permission To Leave",
            synonyms: synonym.permissionToLeave_mode
          },
          {
            name: "permissionToLeave_endDateTime",
            property: 'permissionToLeave_endDateTime',
            title: "Permission To Leave End Date",
            synonyms: synonym.permissionToLeave_endDateTime
          },
          {
            name: "permissionToLeave_startDateTime",
            property: 'permissionToLeave_startDateTime',
            title: "Permission To Leave Start Date",
            synonyms: synonym.permissionToLeave_startDateTime
          },
          {
            name: "permissionToLeave_validAfterDateTime",
            property: 'permissionToLeave_validAfterDateTime',
            title: "Permission To Leave Not Valid Before Time",
            synonyms: synonym.permissionToLeave_validAfterDateTime
          },
          {
            name: "permissionToLeave_invalidAfterDateTime",
            property: 'permissionToLeave_invalidAfterDateTime',
            title: "Permission To Leave Not Valid After Time",
            synonyms: synonym.permissionToLeave_invalidAfterDateTime
          },
          {
            name: "accessParking_mode",
            property: 'accessParking_mode',
            title: "Parking Access",
            synonyms: synonym.accessParking_mode
          },
          {
            name: "accessParking_endDateTime",
            property: 'accessParking_endDateTime',
            title: "Parking Access End time",
            synonyms: synonym.accessParking_endDateTime
          },
          {
            name: "accessParking_startDateTime",
            property: 'accessParking_startDateTime',
            title: "Parking Access start time",
            synonyms: synonym.accessParking_startDateTime
          },
          {
            name: "accessParking_validAfterDateTime",
            property: 'accessParking_validAfterDateTime',
            title: "Parking Access Not Valid Before Time",
            synonyms: synonym.accessParking_validAfterDateTime
          },
          {
            name: "accessParking_invalidAfterDateTime",
            property: 'accessParking_invalidAfterDateTime',
            title: "Parking Access Not Valid After Time",
            synonyms: synonym.accessParking_invalidAfterDateTime
          },
          {
            name: "permissionFlexSchedule_mode",
            property: 'permissionFlexSchedule_mode',
            title: "Short Schedule",
            synonyms: synonym.permissionFlexSchedule_mode
          },
          {
            name: "permissionFlexSchedule_endDateTime",
            property: 'permissionFlexSchedule_endDateTime',
            title: "Short Schedule End",
            synonyms: synonym.permissionFlexSchedule_endDateTime
          },
          {
            name: "permissionFlexSchedule_startDateTime",
            property: 'permissionFlexSchedule_startDateTime',
            title: "Short Schedule Start",
            synonyms: synonym.permissionFlexSchedule_startDateTime
          },
          {
            name: "permissionFlexSchedule_validAfterDateTime",
            property: 'permissionFlexSchedule_validAfterDateTime',
            title: "Short Schedule Not Valid Before Time",
            synonyms: synonym.permissionFlexSchedule_validAfterDateTime
          },
          {
            name: "permissionFlexSchedule_invalidAfterDateTime",
            property: 'permissionFlexSchedule_invalidAfterDateTime',
            title: "Short Schedule Not Valid After Time" ,
            synonyms: synonym.permissionFlexSchedule_invalidAfterDateTime
          },
          {
            name: "statusAtOrg",
            property: "statusAtOrg",
            title: "Status at Organization",
            synonyms: []
          },
        ];
      case "conference":
        return [
          {
            name: "key",
            property: "key",
            title: "DB key",
            synonyms: synonym.key
          },
          {
            name: "salutation",
            property: "salutation",
            title: "Salutation",
            synonyms: synonym.salutation
          },
          {
            name: "fname",
            property: "firstName",
            title: "First Name",
            synonyms: synonym.fname
          },
          {
            name: "middleName",
            property: "middleName",
            title: "Middle Name",
            synonyms: synonym.middleName
          },
          {
            name: "nickName",
            property: "nickName",
            title: "Nick Name",
            synonyms: synonym.nickName
          },
          {
            name: "lname",
            property: "lastName",
            title: "Last Name",
            synonyms: synonym.lname
          },
          {
            name: "suffix",
            property: "suffix",
            title: "Suffix",
            synonyms: synonym.suffix
          },
          {
            name: "role",
            property: "role",
            title: "Role",
            synonyms: synonym.role
          },
          {
            name: "homeRoom",
            property: "homeRoom",
            title: "Home room",
            synonyms: synonym.homeRoom
          },
          {
            name: "period",
            property: "period",
            title: "Period",
            synonyms: []
          },
          {
            name: "track",
            property: "track",
            title: "Track",
            synonyms: []
          },
          {
            name: "photoUrl",
            property: "photoUrl",
            title: "Image Url",
            synonyms: synonym.photoUrl
          },
          {
            name: "status",
            property: "status",
            title: "status",
            synonyms: []
          },
          {
            name: "id",
            property: "license",
            title: "Organization ID",
            synonyms: ["license"]
          },
          {
            name: "company",
            property: "company",
            title: "Company",
            synonyms: synonym.company
          },
          {
            name: "hash",
            property: "hash",
            title: "DB Hash",
            synonyms: synonym.hash
          },
          {
            name: "image",
            property: "photo",
            title: "Photo filename",
            synonyms: synonym.image
          },
          {
            name: "email",
            property: "email",
            title: "Email",
            synonyms: synonym.email
          },
          {
            name: "mobilePhone",
            property: "mobilePhone",
            title: "Mobile Phone",
            synonyms: synonym.mobilePhone
          },
          {
            name: "groupID",
            property: "groupID",
            title: "Company ID",
            synonyms: ["groupID", "companyID", "company ID", "companyId"]
          },
          {
            name: "addressPart1",
            property: "addressPart1",
            title: "Street address",
            synonyms: ["Street mailing address", "address"]
          },
          {
            name: "addressPart2",
            property: "addressPart2",
            title: "Street address line 2",
            synonyms: ["address2"]
          },
          {
            name: "postalCode",
            property: "postalCode",
            title: "ZIP/Postal Code",
            synonyms: ["zip code", "zip"]
          },
          {
            name: "city",
            property: "city",
            title: "City",
            synonyms: []
          },
          {
            name: "state",
            property: "state",
            title: "State/Province",
            synonyms: []
          },
          {
            name: "country",
            property: "country",
            title: "Country",
            synonyms: []
          },
          {
            name: "photoStatus",
            property: "photoStatus",
            title: "Photo Approval Status",
            synonyms: synonym.photoStatus
          },
          {
            name: "downloads",
            property: "downloads",
            title: "Downloads",
            synonyms: []
          },
          {
            name: "specialEvents",
            property: "specialEvents",
            title: "Special Events",
            synonyms: []
          },
          {
            name: "admissibilityGroup",
            property: "admissibilityGroup",
            title: "Admissibility Group",
            synonyms: synonym.admissibilityGroup
          },
          {
            name: "permissionToLeave",
            property: "permissionToLeave",
            title: "Permission To Leave",
            synonyms: []
          },
          {
            name: "RFID",
            property: "RFID",
            title: "RFID Code",
            synonyms: synonym.RFID
          },
          {
            name: "ASBMember",
            property: "ASBMember",
            title: "ASB Member",
            synonyms: synonym.ASBMember
          },
          {
            name: "group",
            property: "group",
            title: "Group",
            synonyms: []
          },
          {
            name: "department",
            property: "department",
            title: "Department",
            synonyms: []
          },
          {
            name: "schoolDepartment",
            property: "schoolDepartment",
            title: "School Department",
            synonyms: synonym.schoolDepartment
          },
          {
            name: "title",
            property: "title",
            title: "Title",
            synonyms: synonym.title
          },
          {
            name: "section",
            property: "section",
            title: "section",
            synonyms: []
          },
          {
            name: "custom1",
            property: "custom1",
            title: "Custom1",
            synonyms: []
          },
          {
            name: "custom2",
            property: "custom2",
            title: "Custom2",
            synonyms: []
          },
          {
            name: "custom3",
            property: "custom3",
            title: "Custom3",
            synonyms: []
          },
          {
            name: "auxID",
            property: "auxID",
            title: "Auxiliary ID",
            synonyms: synonym.auxID
          },
          {
            name: "lockerNumber",
            property: "lockerNumber",
            title: "Locker Number",
            synonyms: []
          },
          {
            name: "lockerCombination",
            property: "lockerCombination",
            title: "Locker Combination",
            synonyms: []
          },
          {
            name: "teammatePURL",
            property: "teammatePURL",
            title: "TeammatePURL",
            synonyms: []
          },
          {
            name: "weight",
            property: "weight",
            title: "Weight",
            synonyms: []
          },
          {
            name: "height1",
            property: "height1",
            title: "Height (feet)",
            synonyms: []
          },
          {
            name: "height2",
            property: "height2",
            title: "Height (inches)",
            synonyms: []
          },
          {
            name: "hairColor",
            property: "hairColor",
            title: "Hair Color",
            synonyms: synonym.hairColor
          },
          {
            name: "eyeColor",
            property: "eyeColor",
            title: "Eye Color",
            synonyms: synonym.eyeColor
          },
          {
            name: "indID_Client",
            property: "indID_Client",
            title: "Client indID",
            synonyms: synonym.indID_Client
          },
          {
            name: "auxID",
            property: "auxID",
            title: "Auxiliary ID",
            synonyms: synonym.auxID
          },
          {
            name: "photoYear",
            property: "photoYear",
            title: "Photo Year",
            synonyms: synonym.photoYear
          },
          {
            name: "accessBus_startDateTime",
            property: 'accessBus_startDateTime',
            title: "Bus Pass Start",
            synonyms: synonym.accessBus_startDateTime
          },
          {
            name: "accessBus_endDateTime",
            property: 'accessBus_endDateTime',
            title: "Bus Pass End",
            synonyms: synonym.accessBus_endDateTime
          },
          {
            name: "accessBus_invalidAfterDateTime",
            property: 'accessBus_invalidAfterDateTime',
            title: "Bus Pass Not Valid after Time",
            synonyms: synonym.accessBus_invalidAfterDateTime
          },
          {
            name: "accessBus_validAfterDateTime",
            property: 'accessBus_validAfterDateTime',
            title: "Bus Pass Not Valid Before Time",
            synonyms: synonym.accessBus_validAfterDateTime
          },
          {
            name: "accessBus_mode",
            property: 'accessBus_mode',
            title: "Bus Pass Valid",
            synonyms: synonym.accessBus_mode
          },
          {
            name: "accessCafeteria_mode",
            property: 'accessCafeteria_mode',
            title: "Access Cafeteria",
            synonyms: synonym.accessCafeteria_mode
          },
          {
            name: "accessCafeteria_endDateTime",
            property: 'accessCafeteria_endDateTime',
            title: "Access Cafeteria End Date",
            synonyms: synonym.accessCafeteria_endDateTime
          },
          {
            name: "accessCafeteria_startDateTime",
            property: 'accessCafeteria_startDateTime',
            title: "Access Cafeteria Start Date",
            synonyms: synonym.accessCafeteria_startDateTime
          },
          {
            name: "accessCafeteria_validAfterDateTime",
            property: 'accessCafeteria_validAfterDateTime',
            title: "Access Cafeteria Valid after",
            synonyms: synonym.accessCafeteria_validAfterDateTime
          },
          {
            name: "accessCafeteria_invalidAfterDateTime",
            property: 'accessCafeteria_invalidAfterDateTime',
            title: "Access Cafeteria Not Valid after",
            synonyms: synonym.accessCafeteria_invalidAfterDateTime
          },
          {
            name: "accessCampus_mode",
            property: 'accessCampus_mode',
            title: "admissible in Person",
            synonyms: synonym.accessCampus_mode
          },
          {
            name: "accessCampus_startDateTime",
            property: 'accessCampus_startDateTime',
            title: "admissible Person start time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_endDateTime",
            property: 'accessCampus_endDateTime',
            title: "admissible Person End time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_invalidAfterDateTime",
            property: 'accessCampus_invalidAfterDateTime',
            title: "admissible Person Not Valid after Time",
            synonyms: synonym.accessCampus_invalidAfterDateTime
          },
          {
            name: "accessCampus_validAfterDateTime",
            property: 'accessCampus_validAfterDateTime',
            title: "admissible Person Not Valid Before Time",
            synonyms: synonym.accessCampus_validAfterDateTime
          },
          {
            name: "idValid_startDateTime",
            property: 'idValid_startDateTime',
            title: "ID Status Start",
            synonyms: synonym.idValid_startDateTime
          },
          {
            name: "idValid_endDateTime",
            property: 'idValid_endDateTime',
            title: "ID Status End",
            synonyms: synonym.idValid_endDateTime
          },
          {
            name: "idValid_invalidAfterDateTime",
            property: 'idValid_invalidAfterDateTime',
            title: "ID Status Not Valid after Time",
            synonyms: synonym.idValid_invalidAfterDateTime
          },
          {
            name: "idValid_validAfterDateTime",
            property: 'idValid_validAfterDateTime',
            title: "ID Status Not Valid Before Time",
            synonyms: synonym.idValid_validAfterDateTime
          },
          {
            name: "idValid_mode",
            property: 'idValid_mode',
            title: "ID Status",
            synonyms: synonym.idValid_mode
          },
          {
            name: "accessSocial_mode",
            property: 'accessSocial_mode',
            title: "Social Access",
            synonyms: synonym.accessSocial_mode
          },
          {
            name: "accessSocial_endDateTime",
            property: 'accessSocial_endDateTime',
            title: "Social Access End",
            synonyms: synonym.accessSocial_endDateTime
          },
          {
            name: "accessSocial_startDateTime",
            property: 'accessSocial_startDateTime',
            title: "Social Access Start",
            synonyms: synonym.accessSocial_startDateTime
          },
          {
            name: "accessSocial_validAfterDateTime",
            property: 'accessSocial_validAfterDateTime',
            title: "Social Access Not Valid Before Time",
            synonyms: synonym.accessSocial_validAfterDateTime
          },
          {
            name: "accessSocial_invalidAfterDateTime",
            property: 'accessSocial_invalidAfterDateTime',
            title: "Social Access Not Valid After Time",
            synonyms: synonym.accessSocial_invalidAfterDateTime
          },
          {
            name: "accessAthletics_mode",
            property: 'accessAthletics_mode',
            title: "Athletics Access",
            synonyms: synonym.accessAthletics_mode
          },
          {
            name: "accessAthletics_startDateTime",
            property: 'accessAthletics_startDateTime',
            title: "Athletics Access Start",
            synonyms: synonym.accessAthletics_startDateTime
          },
          {
            name: "accessAthletics_endDateTime",
            property: 'accessAthletics_endDateTime',
            title: "Athletics Access End",
            synonyms: synonym.accessAthletics_endDateTime
          },
          {
            name: "accessAthletics_validAfterDateTime",
            property: 'accessAthletics_validAfterDateTime',
            title: "Athletics Access Not Valid Before Time",
            synonyms: synonym.accessAthletics_validAfterDateTime
          },
          {
            name: "accessAthletics_invalidAfterDateTime",
            property: 'accessAthletics_invalidAfterDateTime',
            title: "Athletics Access Not Valid after Time",
            synonyms: synonym.accessAthletics_invalidAfterDateTime
          },
          {
            name: "permissionToLeave_mode",
            property: 'permissionToLeave_mode',
            title: "Permission To Leave",
            synonyms: synonym.permissionToLeave_mode
          },
          {
            name: "permissionToLeave_endDateTime",
            property: 'permissionToLeave_endDateTime',
            title: "Permission To Leave End Date",
            synonyms: synonym.permissionToLeave_endDateTime
          },
          {
            name: "permissionToLeave_startDateTime",
            property: 'permissionToLeave_startDateTime',
            title: "Permission To Leave Start Date",
            synonyms: synonym.permissionToLeave_startDateTime
          },
          {
            name: "permissionToLeave_validAfterDateTime",
            property: 'permissionToLeave_validAfterDateTime',
            title: "Permission To Leave Not Valid Before Time",
            synonyms: synonym.permissionToLeave_validAfterDateTime
          },
          {
            name: "permissionToLeave_invalidAfterDateTime",
            property: 'permissionToLeave_invalidAfterDateTime',
            title: "Permission To Leave Not Valid After Time",
            synonyms: synonym.permissionToLeave_invalidAfterDateTime
          },
          {
            name: "accessParking_mode",
            property: 'accessParking_mode',
            title: "Parking Access",
            synonyms: synonym.accessParking_mode
          },
          {
            name: "accessParking_endDateTime",
            property: 'accessParking_endDateTime',
            title: "Parking Access End time",
            synonyms: synonym.accessParking_endDateTime
          },
          {
            name: "accessParking_startDateTime",
            property: 'accessParking_startDateTime',
            title: "Parking Access start time",
            synonyms: synonym.accessParking_startDateTime
          },
          {
            name: "accessParking_validAfterDateTime",
            property: 'accessParking_validAfterDateTime',
            title: "Parking Access Not Valid Before Time",
            synonyms: synonym.accessParking_validAfterDateTime
          },
          {
            name: "accessParking_invalidAfterDateTime",
            property: 'accessParking_invalidAfterDateTime',
            title: "Parking Access Not Valid After Time",
            synonyms: synonym.accessParking_invalidAfterDateTime
          },
          {
            name: "permissionFlexSchedule_mode",
            property: 'permissionFlexSchedule_mode',
            title: "Short Schedule",
            synonyms: synonym.permissionFlexSchedule_mode
          },
          {
            name: "permissionFlexSchedule_endDateTime",
            property: 'permissionFlexSchedule_endDateTime',
            title: "Short Schedule End",
            synonyms: synonym.permissionFlexSchedule_endDateTime
          },
          {
            name: "permissionFlexSchedule_startDateTime",
            property: 'permissionFlexSchedule_startDateTime',
            title: "Short Schedule Start",
            synonyms: synonym.permissionFlexSchedule_startDateTime
          },
          {
            name: "permissionFlexSchedule_validAfterDateTime",
            property: 'permissionFlexSchedule_validAfterDateTime',
            title: "Short Schedule Not Valid Before Time",
            synonyms: synonym.permissionFlexSchedule_validAfterDateTime
          },
          {
            name: "permissionFlexSchedule_invalidAfterDateTime",
            property: 'permissionFlexSchedule_invalidAfterDateTime',
            title: "Short Schedule Not Valid After Time" ,
            synonyms: synonym.permissionFlexSchedule_invalidAfterDateTime
          },
          {
            name: "statusAtOrg",
            property: "statusAtOrg",
            title: "Status at Organization",
            synonyms: []
          },
        ];
      case "sportsteam":
        return [
          {
            name: "key",
            property: "key",
            title: "DB key",
            synonyms: synonym.key
          },
          {
            name: "salutation",
            property: "salutation",
            title: "Salutation",
            synonyms: synonym.salutation
          },
          {
            name: "fname",
            property: "firstName",
            title: "First Name",
            synonyms: synonym.fname
          },
          {
            name: "middleName",
            property: "middleName",
            title: "Middle Name",
            synonyms: synonym.middleName
          },
          {
            name: "nickName",
            property: "nickName",
            title: "Nick Name",
            synonyms: synonym.nickName
          },
          {
            name: "physicianName",
            property: "physicianName",
            title: "Medical Physician Name",
            synonyms: synonym.physicianName
          },
          {
            name: "physicianStreet",
            property: "physicianStreet",
            title: "Medical Physician Street",
            synonyms: synonym.physicianStreet
          },
          {
            name: "physicianCity",
            property: "physicianCity",
            title: "Medical Physician City",
            synonyms: synonym.physicianCity
          },
          {
            name: "physicianState",
            property: "physicianState",
            title: "Medical Physician State",
            synonyms: synonym.physicianState
          },
          {
            name: "physicianZip",
            property: "physicianZip",
            title: "Medical Physician Zip",
            synonyms: synonym.physicianZip
          },
          {
            name: "physicianPhone",
            property: "physicianPhone",
            title: "Medical Physician Phone",
            synonyms: synonym.physicianPhone
          },
          {
            name: "physicianFax",
            property: "physicianFax",
            title: "Medical Physician Fax",
            synonyms: synonym.physicianFax
          },
          {
            name: "physicianEmail",
            property: "physicianEmail",
            title: "Medical Physician Email",
            synonyms: synonym.physicianEmail
          },
          {
            name: "medPrefHospital",
            property: "medPrefHospital",
            title: "Medical Physician Hospital",
            synonyms: synonym.medPrefHospital
          },
          {
            name: "medAllergies",
            property: "medAllergies",
            title: "Medical Allergies",
            synonyms: synonym.medAllergies
          },
          {
            name: "medConditions",
            property: "medConditions",
            title: "Medical Conditions",
            synonyms: synonym.medConditions
          },
          {
            name: "medOtherNotes",
            property: "medOtherNotes",
            title: "Medical OtherNotes",
            synonyms: synonym.medOtherNotes
          },
          {
            name: "hasInsurance",
            property: "hasInsurance",
            title: "Insurance hasInsurance",
            synonyms: synonym.hasInsurance
          },
          {
            name: "insuranceCarrierName",
            property: "insuranceCarrierName",
            title: "Insurance CarrierName",
            synonyms: synonym.insuranceCarrierName
          },
          {
            name: "insuranceGroup",
            property: "insuranceGroup",
            title: "Insurance Group",
            synonyms: synonym.insuranceGroup
          },
          {
            name: "insurancePolicyNumber",
            property: "insurancePolicyNumber",
            title: "Insurance Policy Number",
            synonyms: synonym.insurancePolicyNumber
          },
          {
            name: "insuranceGroupNumber",
            property: "insuranceGroupNumber",
            title: "Insurance Group Number",
            synonyms: synonym.insuranceGroupNumber
          },
          {
            name: "insurancePolicyHolderName",
            property: "insurancePolicyHolderName",
            title: "Insurance Policy HolderName",
            synonyms: synonym.insurancePolicyHolderName
          },
          {
            name: "schoolName",
            property: "schoolName",
            title: "School Name",
            synonyms: synonym.schoolName
          },
          {
            name: "schoolDistrict",
            property: "schoolDistrict",
            title: "School District",
            synonyms: synonym.schoolDistrict
          },
          {
            name: "emergencyCon1Name",
            property: "emergencyCon1Name",
            title: "emergency contact 1 name",
            synonyms: synonym.emergencyCon1Name
          },
          {
            name: "emergencyCon1Phone",
            property: "emergencyCon1Phone",
            title: "emergency contact 1 phone",
            synonyms: synonym.emergencyCon1Phone
          },
          {
            name: "emergencyCon1Relationship",
            property: "emergencyCon1Relationship",
            title: "emergency contact 1 relationship",
            synonyms: synonym.emergencyCon1Relationship
          },
          {
            name: "emergencyCon2Name",
            property: "emergencyCon2Name",
            title: "emergency contact 2 name",
            synonyms: synonym.emergencyCon2Name
          },
          {
            name: "emergencyCon2Phone",
            property: "emergencyCon2Phone",
            title: "emergency contact 2 phone",
            synonyms: synonym.emergencyCon2Phone
          },
          {
            name: "emergencyCon2Relationship",
            property: "emergencyCon2Relationship",
            title: "emergency contact 2 relationship",
            synonyms: synonym.emergencyCon2Relationship
          },
          {
            name: "feesSeason",
            property: "feesSeason",
            title: "Fees Season",
            synonyms: synonym.feesSeason
          },
          {
            name: "feesRegistration",
            property: "feesRegistration",
            title: "Fees Registration",
            synonyms: synonym.feesRegistration
          },
          {
            name: "employer",
            property: "employer",
            title: "Employer",
            synonyms: synonym.employer
          },
          {
            name: "lname",
            property: "lastName",
            title: "Last Name",
            synonyms: synonym.lname
          },
          {
            name: "suffix",
            property: "suffix",
            title: "Suffix",
            synonyms: synonym.suffix
          },
          {
            name: "role",
            property: "role",
            title: "Role",
            synonyms: []
          },
          {
            name: "status",
            property: "status",
            title: "status",
            synonyms: []
          },
          {
            name: "Image Url",
            property: "photoUrl",
            title: "Image Url",
            synonyms: ["photoUrl", "Image Url"]
          },
          {
            name: "hash",
            property: "hash",
            title: "DB Hash",
            synonyms: ["DBKey", "dbkey"]
          },
          {
            name: "image",
            property: "photo",
            title: "Photo filename",
            synonyms: [
              "photo",
              "image file name",
              "image name",
              "img name",
              "image",
              "photo name",
              "image_name"
            ]
          },
          {
            name: "photoStatus",
            property: "photoStatus",
            title: "Photo Approval Status",
            synonyms: ["photoApprovalStatus", "Image status"]
          },
          {
            name: "email",
            property: "email",
            title: "Email",
            synonyms: synonym.email
          },
          {
            name: "mobilePhone",
            property: "mobilePhone",
            title: "Mobile Phone",
            synonyms: synonym.mobilePhone
          },
          {
            name: "guardianFullName",
            property: "guardianFullName",
            title: "Guardian",
            synonyms: synonym.guardianFullName
          },
          {
            name: "guardianEmail",
            property: "guardianEmail",
            title: "Guardian Email",
            synonyms: synonym.guardianEmail
          },
          {
            name: "guardianPhone",
            property: "guardianPhone",
            title: "Guardian Phone",
            synonyms: synonym.guardianPhone
          },

          {
            name: "permissionToLeave",
            property: "permissionToLeave",
            title: "Permission To Leave",
            synonyms: []
          },

          // --- sports section fields START
          {
            name: "team",
            property: "team",
            title: "Team",
            synonyms: []
          },
          {
            name: "coach",
            property: "coach",
            title: "Coach",
            synonyms: []
          },
          {
            name: "jerseyNumber",
            property: "jerseyNumber",
            title: "Jersey Number",
            synonyms: synonym.jerseyNumber
          },
          {
            name: "position",
            property: "position",
            title: "Position",
            synonyms: []
          },
          {
            name: "weight",
            property: "weight",
            title: "Weight",
            synonyms: []
          },
          {
            name: "height1",
            property: "height1",
            title: "Height (feet)",
            synonyms: []
          },
          {
            name: "height2",
            property: "height2",
            title: "Height (inches)",
            synonyms: []
          },
          {
            name: "hairColor",
            property: "hairColor",
            title: "Hair Color",
            synonyms: synonym.hairColor
          },
          {
            name: "eyeColor",
            property: "eyeColor",
            title: "Eye Color",
            synonyms: synonym.eyeColor
          },
          {
            name: "division",
            property: "division",
            title: "Division",
            synonyms: []
          },
          // {
          //   name: "ageClass",
          //   property: "ageClass",
          //   title: "AgeClass",
          //   synonyms: []
          // },
          {
            name: "weightClass",
            property: "weightClass",
            title: "WeightClass",
            synonyms: []
          },
          {
            name: "program",
            property: "program",
            title: "Program",
            synonyms: []
          },
          {
            name: "programSection",
            property: "programSection",
            title: "Program Section",
            synonyms: []
          },
          {
            name: "playStatus",
            property: "playStatus",
            title: "Play Status",
            synonyms: []
          },
          {
            name: "paymentStatus",
            property: "paymentStatus",
            title: "Payment status",
            synonyms: []
          },
          {
            name: "bookCode",
            property: "bookCode",
            title: "Book Code",
            synonyms: []
          },
          // --- sports section fields END

          {
            name: "govtID",
            property: "govtID",
            title: "Government-issued ID",
            synonyms: [
              "DL",
              "drivers",
              "driversLicense",
              "drivers",
              "license",
              "government ID"
            ]
          },
          {
            name: "admissibilityGroup",
            property: "admissibilityGroup",
            title: "Admissibility Group",
            synonyms: [
              "admissible group",
              "Admissible Group",
              "Admissibility Group"
            ]
          },
          {
            name: "RFID",
            property: "RFID",
            title: "RFID Code",
            synonyms: synonym.RFID
          },
          {
            name: "group",
            property: "group",
            title: "Group",
            synonyms: []
          },
          {
            name: "department",
            property: "department",
            title: "Department",
            synonyms: []
          },
          {
            name: "schoolDepartment",
            property: "schoolDepartment",
            title: "School Department",
            synonyms: ["schoolDepartment", "school Department"]
          },
          {
            name: "title",
            property: "title",
            title: "Title",
            synonyms: synonym.title
          },
          {
            name: "section",
            property: "section",
            title: "section",
            synonyms: []
          },
          {
            name: "custom1",
            property: "custom1",
            title: "Custom1",
            synonyms: []
          },
          {
            name: "custom2",
            property: "custom2",
            title: "Custom2",
            synonyms: []
          },
          {
            name: "custom3",
            property: "custom3",
            title: "Custom3",
            synonyms: []
          },
          {
            name: "auxID",
            property: "auxID",
            title: "Auxiliary ID",
            synonyms: synonym.auxID
          },
          {
            name: "teammatePURL",
            property: "teammatePURL",
            title: "TeammatePURL",
            synonyms: []
          },
          {
            name: "birth",
            property: "birth",
            title: "DateOfBirth",
            synonyms: [
              "Birthdate",
              "birthday",
              "Date of birth",
              "DateOfBirth",
              "birth",
              "birth day",
              "birthday"
            ]
          },
          {
            name: "company",
            property: "company",
            title: "Company",
            synonyms: ["company name", "employer"]
          },
          {
            name: "indID_Client",
            property: "indID_Client",
            title: "Client indID",
            synonyms: synonym.indID_Client
          },
          {
            name: "auxID",
            property: "auxID",
            title: "Auxiliary ID",
            synonyms: synonym.auxID
          },
          {
            name: "photoYear",
            property: "photoYear",
            title: "Photo Year",
            synonyms: synonym.photoYear
          },
          {
            name: "grade",
            property: "class",
            title: "Grade",
            synonyms: synonym.grade
          },
          {
            name: "addressPart1",
            property: "addressPart1",
            title: "Street address",
            synonyms: ["Street mailing address", "address"]
          },
          {
            name: "addressPart2",
            property: "addressPart2",
            title: "Street address line 2",
            synonyms: ["address2"]
          },
          {
            name: "postalCode",
            property: "postalCode",
            title: "ZIP/Postal Code",
            synonyms: ["zip code", "zip"]
          },
          {
            name: "city",
            property: "city",
            title: "City",
            synonyms: []
          },
          {
            name: "state",
            property: "state",
            title: "State/Province",
            synonyms: []
          },
          {
            name: "country",
            property: "country",
            title: "Country",
            synonyms: []
          },
          {
            name: "accessBus_startDateTime",
            property: 'accessBus_startDateTime',
            title: "Bus Pass Start",
            synonyms: synonym.accessBus_startDateTime
          },
          {
            name: "accessBus_endDateTime",
            property: 'accessBus_endDateTime',
            title: "Bus Pass End",
            synonyms: synonym.accessBus_endDateTime
          },
          {
            name: "accessBus_invalidAfterDateTime",
            property: 'accessBus_invalidAfterDateTime',
            title: "Bus Pass Not Valid after Time",
            synonyms: synonym.accessBus_invalidAfterDateTime
          },
          {
            name: "accessBus_validAfterDateTime",
            property: 'accessBus_validAfterDateTime',
            title: "Bus Pass Not Valid Before Time",
            synonyms: synonym.accessBus_validAfterDateTime
          },
          {
            name: "accessBus_mode",
            property: 'accessBus_mode',
            title: "Bus Pass Valid",
            synonyms: synonym.accessBus_mode
          },
          {
            name: "accessCafeteria_mode",
            property: 'accessCafeteria_mode',
            title: "Access Cafeteria",
            synonyms: synonym.accessCafeteria_mode
          },
          {
            name: "accessCafeteria_endDateTime",
            property: 'accessCafeteria_endDateTime',
            title: "Access Cafeteria End Date",
            synonyms: synonym.accessCafeteria_endDateTime
          },
          {
            name: "accessCafeteria_startDateTime",
            property: 'accessCafeteria_startDateTime',
            title: "Access Cafeteria Start Date",
            synonyms: synonym.accessCafeteria_startDateTime
          },
          {
            name: "accessCafeteria_validAfterDateTime",
            property: 'accessCafeteria_validAfterDateTime',
            title: "Access Cafeteria Valid after",
            synonyms: synonym.accessCafeteria_validAfterDateTime
          },
          {
            name: "accessCafeteria_invalidAfterDateTime",
            property: 'accessCafeteria_invalidAfterDateTime',
            title: "Access Cafeteria Not Valid after",
            synonyms: synonym.accessCafeteria_invalidAfterDateTime
          },
          {
            name: "accessCampus_mode",
            property: 'accessCampus_mode',
            title: "admissible in Person",
            synonyms: synonym.accessCampus_mode
          },
          {
            name: "accessCampus_startDateTime",
            property: 'accessCampus_startDateTime',
            title: "admissible Person start time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_endDateTime",
            property: 'accessCampus_endDateTime',
            title: "admissible Person End time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_invalidAfterDateTime",
            property: 'accessCampus_invalidAfterDateTime',
            title: "admissible Person Not Valid after Time",
            synonyms: synonym.accessCampus_invalidAfterDateTime
          },
          {
            name: "accessCampus_validAfterDateTime",
            property: 'accessCampus_validAfterDateTime',
            title: "admissible Person Not Valid Before Time",
            synonyms: synonym.accessCampus_validAfterDateTime
          },
          {
            name: "idValid_startDateTime",
            property: 'idValid_startDateTime',
            title: "ID Status Start",
            synonyms: synonym.idValid_startDateTime
          },
          {
            name: "idValid_endDateTime",
            property: 'idValid_endDateTime',
            title: "ID Status End",
            synonyms: synonym.idValid_endDateTime
          },
          {
            name: "idValid_invalidAfterDateTime",
            property: 'idValid_invalidAfterDateTime',
            title: "ID Status Not Valid after Time",
            synonyms: synonym.idValid_invalidAfterDateTime
          },
          {
            name: "idValid_validAfterDateTime",
            property: 'idValid_validAfterDateTime',
            title: "ID Status Not Valid Before Time",
            synonyms: synonym.idValid_validAfterDateTime
          },
          {
            name: "idValid_mode",
            property: 'idValid_mode',
            title: "ID Status",
            synonyms: synonym.idValid_mode
          },
          {
            name: "accessSocial_mode",
            property: 'accessSocial_mode',
            title: "Social Access",
            synonyms: synonym.accessSocial_mode
          },
          {
            name: "accessSocial_endDateTime",
            property: 'accessSocial_endDateTime',
            title: "Social Access End",
            synonyms: synonym.accessSocial_endDateTime
          },
          {
            name: "accessSocial_startDateTime",
            property: 'accessSocial_startDateTime',
            title: "Social Access Start",
            synonyms: synonym.accessSocial_startDateTime
          },
          {
            name: "accessSocial_validAfterDateTime",
            property: 'accessSocial_validAfterDateTime',
            title: "Social Access Not Valid Before Time",
            synonyms: synonym.accessSocial_validAfterDateTime
          },
          {
            name: "accessSocial_invalidAfterDateTime",
            property: 'accessSocial_invalidAfterDateTime',
            title: "Social Access Not Valid After Time",
            synonyms: synonym.accessSocial_invalidAfterDateTime
          },
          {
            name: "accessAthletics_mode",
            property: 'accessAthletics_mode',
            title: "Athletics Access",
            synonyms: synonym.accessAthletics_mode
          },
          {
            name: "accessAthletics_startDateTime",
            property: 'accessAthletics_startDateTime',
            title: "Athletics Access Start",
            synonyms: synonym.accessAthletics_startDateTime
          },
          {
            name: "accessAthletics_endDateTime",
            property: 'accessAthletics_endDateTime',
            title: "Athletics Access End",
            synonyms: synonym.accessAthletics_endDateTime
          },
          {
            name: "accessAthletics_validAfterDateTime",
            property: 'accessAthletics_validAfterDateTime',
            title: "Athletics Access Not Valid Before Time",
            synonyms: synonym.accessAthletics_validAfterDateTime
          },
          {
            name: "accessAthletics_invalidAfterDateTime",
            property: 'accessAthletics_invalidAfterDateTime',
            title: "Athletics Access Not Valid after Time",
            synonyms: synonym.accessAthletics_invalidAfterDateTime
          },
          {
            name: "permissionToLeave_mode",
            property: 'permissionToLeave_mode',
            title: "Permission To Leave",
            synonyms: synonym.permissionToLeave_mode
          },
          {
            name: "permissionToLeave_endDateTime",
            property: 'permissionToLeave_endDateTime',
            title: "Permission To Leave End Date",
            synonyms: synonym.permissionToLeave_endDateTime
          },
          {
            name: "permissionToLeave_startDateTime",
            property: 'permissionToLeave_startDateTime',
            title: "Permission To Leave Start Date",
            synonyms: synonym.permissionToLeave_startDateTime
          },
          {
            name: "permissionToLeave_validAfterDateTime",
            property: 'permissionToLeave_validAfterDateTime',
            title: "Permission To Leave Not Valid Before Time",
            synonyms: synonym.permissionToLeave_validAfterDateTime
          },
          {
            name: "permissionToLeave_invalidAfterDateTime",
            property: 'permissionToLeave_invalidAfterDateTime',
            title: "Permission To Leave Not Valid After Time",
            synonyms: synonym.permissionToLeave_invalidAfterDateTime
          },
          {
            name: "accessParking_mode",
            property: 'accessParking_mode',
            title: "Parking Access",
            synonyms: synonym.accessParking_mode
          },
          {
            name: "accessParking_endDateTime",
            property: 'accessParking_endDateTime',
            title: "Parking Access End time",
            synonyms: synonym.accessParking_endDateTime
          },
          {
            name: "accessParking_startDateTime",
            property: 'accessParking_startDateTime',
            title: "Parking Access start time",
            synonyms: synonym.accessParking_startDateTime
          },
          {
            name: "accessParking_validAfterDateTime",
            property: 'accessParking_validAfterDateTime',
            title: "Parking Access Not Valid Before Time",
            synonyms: synonym.accessParking_validAfterDateTime
          },
          {
            name: "accessParking_invalidAfterDateTime",
            property: 'accessParking_invalidAfterDateTime',
            title: "Parking Access Not Valid After Time",
            synonyms: synonym.accessParking_invalidAfterDateTime
          },
          {
            name: "permissionFlexSchedule_mode",
            property: 'permissionFlexSchedule_mode',
            title: "Short Schedule",
            synonyms: synonym.permissionFlexSchedule_mode
          },
          {
            name: "permissionFlexSchedule_endDateTime",
            property: 'permissionFlexSchedule_endDateTime',
            title: "Short Schedule End",
            synonyms: synonym.permissionFlexSchedule_endDateTime
          },
          {
            name: "permissionFlexSchedule_startDateTime",
            property: 'permissionFlexSchedule_startDateTime',
            title: "Short Schedule Start",
            synonyms: synonym.permissionFlexSchedule_startDateTime
          },
          {
            name: "permissionFlexSchedule_validAfterDateTime",
            property: 'permissionFlexSchedule_validAfterDateTime',
            title: "Short Schedule Not Valid Before Time",
            synonyms: synonym.permissionFlexSchedule_validAfterDateTime
          },
          {
            name: "permissionFlexSchedule_invalidAfterDateTime",
            property: 'permissionFlexSchedule_invalidAfterDateTime',
            title: "Short Schedule Not Valid After Time" ,
            synonyms: synonym.permissionFlexSchedule_invalidAfterDateTime
          },
          {
            name: "statusAtOrg",
            property: "statusAtOrg",
            title: "Status at Organization",
            synonyms: []
          },
        ];
      default:
        return [
          {
            name: "salutation",
            property: "salutation",
            title: "Salutation",
            synonyms: synonym.salutation
          },
          {
            name: "fname",
            property: "firstName",
            title: "First Name",
            synonyms: synonym.fname
          },
          {
            name: "middleName",
            property: "middleName",
            title: "Middle Name",
            synonyms: synonym.middleName
          },
          {
            name: "nickName",
            property: "nickName",
            title: "Nick Name",
            synonyms: synonym.nickName
          },
          {
            name: "lname",
            property: "lastName",
            title: "Last Name",
            synonyms: synonym.lname
          },
          {
            name: "suffix",
            property: "suffix",
            title: "Suffix",
            synonyms: synonym.suffix
          },
          {
            name: "role",
            property: "role",
            title: "Role",
            synonyms: ["Position"]
          },
          {
            name: "homeRoom",
            property: "homeRoom",
            title: "Home room",
            synonyms: synonym.homeRoom
          },
          {
            name: "period",
            property: "period",
            title: "Period",
            synonyms: []
          },
          {
            name: "track",
            property: "track",
            title: "Track",
            synonyms: []
          },
          {
            name: "schoolDepartment",
            property: "schoolDepartment",
            title: "School Department",
            synonyms: ["schoolDepartment", "school Department"]
          },
          {
            name: "admissibilityGroup",
            property: "admissibilityGroup",
            title: "Admissibility Group",
            synonyms: ["admissible group", "Admissible Group"]
          },
          {
            name: "status",
            property: "status",
            title: "status",
            synonyms: []
          },
          {
            name: "grade",
            property: "class",
            title: "Grade",
            synonyms: []
          },
          {
            name: "teacher",
            property: "teacher",
            title: "Teacher",
            synonyms: synonym.teacher
          },
          {
            name: "id",
            property: "license",
            title: "Organization ID",
            synonyms: ["license"]
          },
          {
            name: "hash",
            property: "hash",
            title: "DB Hash",
            synonyms: ["DBKey", "dbkey"]
          },
          {
            name: "image",
            property: "photo",
            title: "Photo filename",
            synonyms: [
              "photo",
              "image file name",
              "image name",
              "img name",
              "image",
              "photo name",
              "image_name"
            ]
          },
          {
            name: "photoStatus",
            property: "photoStatus",
            title: "Photo Approval Status",
            synonyms: ["photoApprovalStatus", "Image status"]
          },
          {
            name: "email",
            property: "email",
            title: "Email",
            synonyms: synonym.email
          },
          {
            name: "mobilePhone",
            property: "mobilePhone",
            title: "Mobile Phone",
            synonyms: synonym.mobilePhone
          },
          {
            name: "guardianFullName",
            property: "guardianFullName",
            title: "Guardian",
            synonyms: synonym.guardianFullName
          },
          {
            name: "guardianEmail",
            property: "guardianEmail",
            title: "Guardian Email",
            synonyms: synonym.guardianEmail
          },
          {
            name: "guardianPhone",
            property: "guardianPhone",
            title: "Guardian Phone",
            synonyms: synonym.guardianPhone
          },
          {
            name: "company",
            property: "company",
            title: "Company",
            synonyms: ["company name", "employer"]
          },
          {
            name: "permissionToLeave",
            property: "permissionToLeave",
            title: "Permission To Leave",
            synonyms: []
          },
          {
            name: "ASBMember",
            property: "ASBMember",
            title: "ASB Member",
            synonyms: synonym.ASBMember
          },
          {
            name: "govtID",
            property: "govtID",
            title: "Government-issued ID",
            synonyms: [
              "DL",
              "drivers",
              "driversLicense",
              "drivers",
              "license",
              "government ID"
            ]
          },
          {
            name: "RFID",
            property: "RFID",
            title: "RFID Code",
            synonyms: synonym.RFID
          },
          {
            name: "birth",
            property: "birth",
            title: "DateOfBirth",
            synonyms: [
              "Birthdate",
              "birthday",
              "Date of birth",
              "DateOfBirth",
              "birth",
              "birth day",
              "birthday"
            ]
          },
          {
            name: "group",
            property: "group",
            title: "Group",
            synonyms: []
          },
          {
            name: "department",
            property: "department",
            title: "Department",
            synonyms: []
          },
          {
            name: "title",
            property: "title",
            title: "Title",
            synonyms: synonym.title
          },
          {
            name: "addressPart1",
            property: "addressPart1",
            title: "Street address",
            synonyms: ["Street mailing address", "address"]
          },
          {
            name: "addressPart2",
            property: "addressPart2",
            title: "Street address line 2",
            synonyms: ["address2"]
          },
          {
            name: "postalCode",
            property: "postalCode",
            title: "ZIP/Postal Code",
            synonyms: ["zip code", "zip"]
          },
          {
            name: "city",
            property: "city",
            title: "City",
            synonyms: []
          },
          {
            name: "state",
            property: "state",
            title: "State/Province",
            synonyms: []
          },
          {
            name: "country",
            property: "country",
            title: "Country",
            synonyms: []
          },
          {
            name: "section",
            property: "section",
            title: "section",
            synonyms: []
          },
          {
            name: "custom1",
            property: "custom1",
            title: "Custom1",
            synonyms: []
          },
          {
            name: "custom2",
            property: "custom2",
            title: "Custom2",
            synonyms: []
          },
          {
            name: "custom3",
            property: "custom3",
            title: "Custom3",
            synonyms: []
          },
          {
            name: "auxID",
            property: "auxID",
            title: "Auxiliary ID",
            synonyms: synonym.auxID
          },
          {
            name: "lockerNumber",
            property: "lockerNumber",
            title: "Locker Number",
            synonyms: []
          },
          {
            name: "lockerCombination",
            property: "lockerCombination",
            title: "Locker Combination",
            synonyms: []
          },
          {
            name: "teammatePURL",
            property: "teammatePURL",
            title: "TeammatePURL",
            synonyms: []
          },
          {
            name: "weight",
            property: "weight",
            title: "Weight",
            synonyms: []
          },
          {
            name: "height1",
            property: "height1",
            title: "Height (feet)",
            synonyms: []
          },
          {
            name: "height2",
            property: "height2",
            title: "Height (inches)",
            synonyms: []
          },
          {
            name: "hairColor",
            property: "hairColor",
            title: "Hair Color",
            synonyms: synonym.hairColor
          },
          {
            name: "eyeColor",
            property: "eyeColor",
            title: "Eye Color",
            synonyms: synonym.eyeColor
          },
          {
            name: "indID_Client",
            property: "indID_Client",
            title: "Client indID",
            synonyms: synonym.indID_Client
          },
          {
            name: "auxID",
            property: "auxID",
            title: "Auxiliary ID",
            synonyms: synonym.auxID
          },
          {
            name: "photoYear",
            property: "photoYear",
            title: "Photo Year",
            synonyms: synonym.photoYear
          },
          {
            name: "accessBus_startDateTime",
            property: 'accessBus_startDateTime',
            title: "Bus Pass Start",
            synonyms: synonym.accessBus_startDateTime
          },
          {
            name: "accessBus_endDateTime",
            property: 'accessBus_endDateTime',
            title: "Bus Pass End",
            synonyms: synonym.accessBus_endDateTime
          },
          {
            name: "accessBus_invalidAfterDateTime",
            property: 'accessBus_invalidAfterDateTime',
            title: "Bus Pass Not Valid after Time",
            synonyms: synonym.accessBus_invalidAfterDateTime
          },
          {
            name: "accessBus_validAfterDateTime",
            property: 'accessBus_validAfterDateTime',
            title: "Bus Pass Not Valid Before Time",
            synonyms: synonym.accessBus_validAfterDateTime
          },
          {
            name: "accessBus_mode",
            property: 'accessBus_mode',
            title: "Bus Pass Valid",
            synonyms: synonym.accessBus_mode
          },
          {
            name: "accessCafeteria_mode",
            property: 'accessCafeteria_mode',
            title: "Access Cafeteria",
            synonyms: synonym.accessCafeteria_mode
          },
          {
            name: "accessCafeteria_endDateTime",
            property: 'accessCafeteria_endDateTime',
            title: "Access Cafeteria End Date",
            synonyms: synonym.accessCafeteria_endDateTime
          },
          {
            name: "accessCafeteria_startDateTime",
            property: 'accessCafeteria_startDateTime',
            title: "Access Cafeteria Start Date",
            synonyms: synonym.accessCafeteria_startDateTime
          },
          {
            name: "accessCafeteria_validAfterDateTime",
            property: 'accessCafeteria_validAfterDateTime',
            title: "Access Cafeteria Valid after",
            synonyms: synonym.accessCafeteria_validAfterDateTime
          },
          {
            name: "accessCafeteria_invalidAfterDateTime",
            property: 'accessCafeteria_invalidAfterDateTime',
            title: "Access Cafeteria Not Valid after",
            synonyms: synonym.accessCafeteria_invalidAfterDateTime
          },
          {
            name: "accessCampus_mode",
            property: 'accessCampus_mode',
            title: "admissible in Person",
            synonyms: synonym.accessCampus_mode
          },
          {
            name: "accessCampus_startDateTime",
            property: 'accessCampus_startDateTime',
            title: "admissible Person start time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_endDateTime",
            property: 'accessCampus_endDateTime',
            title: "admissible Person End time",
            synonyms: synonym.accessCampus_startDateTime
          },
          {
            name: "accessCampus_invalidAfterDateTime",
            property: 'accessCampus_invalidAfterDateTime',
            title: "admissible Person Not Valid after Time",
            synonyms: synonym.accessCampus_invalidAfterDateTime
          },
          {
            name: "accessCampus_validAfterDateTime",
            property: 'accessCampus_validAfterDateTime',
            title: "admissible Person Not Valid Before Time",
            synonyms: synonym.accessCampus_validAfterDateTime
          },
          {
            name: "idValid_startDateTime",
            property: 'idValid_startDateTime',
            title: "ID Status Start",
            synonyms: synonym.idValid_startDateTime
          },
          {
            name: "idValid_endDateTime",
            property: 'idValid_endDateTime',
            title: "ID Status End",
            synonyms: synonym.idValid_endDateTime
          },
          {
            name: "idValid_invalidAfterDateTime",
            property: 'idValid_invalidAfterDateTime',
            title: "ID Status Not Valid after Time",
            synonyms: synonym.idValid_invalidAfterDateTime
          },
          {
            name: "idValid_validAfterDateTime",
            property: 'idValid_validAfterDateTime',
            title: "ID Status Not Valid Before Time",
            synonyms: synonym.idValid_validAfterDateTime
          },
          {
            name: "idValid_mode",
            property: 'idValid_mode',
            title: "ID Status",
            synonyms: synonym.idValid_mode
          },
          {
            name: "accessSocial_mode",
            property: 'accessSocial_mode',
            title: "Social Access",
            synonyms: synonym.accessSocial_mode
          },
          {
            name: "accessSocial_endDateTime",
            property: 'accessSocial_endDateTime',
            title: "Social Access End",
            synonyms: synonym.accessSocial_endDateTime
          },
          {
            name: "accessSocial_startDateTime",
            property: 'accessSocial_startDateTime',
            title: "Social Access Start",
            synonyms: synonym.accessSocial_startDateTime
          },
          {
            name: "accessSocial_validAfterDateTime",
            property: 'accessSocial_validAfterDateTime',
            title: "Social Access Not Valid Before Time",
            synonyms: synonym.accessSocial_validAfterDateTime
          },
          {
            name: "accessSocial_invalidAfterDateTime",
            property: 'accessSocial_invalidAfterDateTime',
            title: "Social Access Not Valid After Time",
            synonyms: synonym.accessSocial_invalidAfterDateTime
          },
          {
            name: "accessAthletics_mode",
            property: 'accessAthletics_mode',
            title: "Athletics Access",
            synonyms: synonym.accessAthletics_mode
          },
          {
            name: "accessAthletics_startDateTime",
            property: 'accessAthletics_startDateTime',
            title: "Athletics Access Start",
            synonyms: synonym.accessAthletics_startDateTime
          },
          {
            name: "accessAthletics_endDateTime",
            property: 'accessAthletics_endDateTime',
            title: "Athletics Access End",
            synonyms: synonym.accessAthletics_endDateTime
          },
          {
            name: "accessAthletics_validAfterDateTime",
            property: 'accessAthletics_validAfterDateTime',
            title: "Athletics Access Not Valid Before Time",
            synonyms: synonym.accessAthletics_validAfterDateTime
          },
          {
            name: "accessAthletics_invalidAfterDateTime",
            property: 'accessAthletics_invalidAfterDateTime',
            title: "Athletics Access Not Valid after Time",
            synonyms: synonym.accessAthletics_invalidAfterDateTime
          },
          {
            name: "permissionToLeave_mode",
            property: 'permissionToLeave_mode',
            title: "Permission To Leave",
            synonyms: synonym.permissionToLeave_mode
          },
          {
            name: "permissionToLeave_endDateTime",
            property: 'permissionToLeave_endDateTime',
            title: "Permission To Leave End Date",
            synonyms: synonym.permissionToLeave_endDateTime
          },
          {
            name: "permissionToLeave_startDateTime",
            property: 'permissionToLeave_startDateTime',
            title: "Permission To Leave Start Date",
            synonyms: synonym.permissionToLeave_startDateTime
          },
          {
            name: "permissionToLeave_validAfterDateTime",
            property: 'permissionToLeave_validAfterDateTime',
            title: "Permission To Leave Not Valid Before Time",
            synonyms: synonym.permissionToLeave_validAfterDateTime
          },
          {
            name: "permissionToLeave_invalidAfterDateTime",
            property: 'permissionToLeave_invalidAfterDateTime',
            title: "Permission To Leave Not Valid After Time",
            synonyms: synonym.permissionToLeave_invalidAfterDateTime
          },
          {
            name: "accessParking_mode",
            property: 'accessParking_mode',
            title: "Parking Access",
            synonyms: synonym.accessParking_mode
          },
          {
            name: "accessParking_endDateTime",
            property: 'accessParking_endDateTime',
            title: "Parking Access End time",
            synonyms: synonym.accessParking_endDateTime
          },
          {
            name: "accessParking_startDateTime",
            property: 'accessParking_startDateTime',
            title: "Parking Access start time",
            synonyms: synonym.accessParking_startDateTime
          },
          {
            name: "accessParking_validAfterDateTime",
            property: 'accessParking_validAfterDateTime',
            title: "Parking Access Not Valid Before Time",
            synonyms: synonym.accessParking_validAfterDateTime
          },
          {
            name: "accessParking_invalidAfterDateTime",
            property: 'accessParking_invalidAfterDateTime',
            title: "Parking Access Not Valid After Time",
            synonyms: synonym.accessParking_invalidAfterDateTime
          },
          {
            name: "permissionFlexSchedule_mode",
            property: 'permissionFlexSchedule_mode',
            title: "Short Schedule",
            synonyms: synonym.permissionFlexSchedule_mode
          },
          {
            name: "permissionFlexSchedule_endDateTime",
            property: 'permissionFlexSchedule_endDateTime',
            title: "Short Schedule End",
            synonyms: synonym.permissionFlexSchedule_endDateTime
          },
          {
            name: "permissionFlexSchedule_startDateTime",
            property: 'permissionFlexSchedule_startDateTime',
            title: "Short Schedule Start",
            synonyms: synonym.permissionFlexSchedule_startDateTime
          },
          {
            name: "permissionFlexSchedule_validAfterDateTime",
            property: 'permissionFlexSchedule_validAfterDateTime',
            title: "Short Schedule Not Valid Before Time",
            synonyms: synonym.permissionFlexSchedule_validAfterDateTime
          },
          {
            name: "permissionFlexSchedule_invalidAfterDateTime",
            property: 'permissionFlexSchedule_invalidAfterDateTime',
            title: "Short Schedule Not Valid After Time" ,
            synonyms: synonym.permissionFlexSchedule_invalidAfterDateTime
          },
          {
            name: "statusAtOrg",
            property: "statusAtOrg",
            title: "Status at Organization",
            synonyms: []
          },
        ];
    }
  }

  passReportClk$ = new BehaviorSubject<boolean>(false);

  constructor(
    private db: AngularFireDatabase,
    private http: HttpClient,
    private translateService: TranslateService,
    private cacheService: CacheService,
    private encryptionService: EncryptionService,
    private alertDialogService: AlertDialogService,
    private firebaseStorage: AngularFireStorage,
    private indexedDBService: IndexedDBService,
    private apiHelperService: ApiHelperService
  ) {}

  setPath(path: boolean) {
    this.landingSubPage.next(path);
  }
  /**
   * Generate Random string id of the given length
   * @param length Length of the id you want to generate
   */
  public generateId(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // --- get studio data using studio id
  public getStudio(id: string, callback?, useCache = false) {
    return new Promise(async (resolve, reject) => {
      if (!id) {
        if (callback && lodash.isFunction(callback)) callback("");
        return resolve("");
      }

      if (useCache) {
        let cachedData = this.cacheService.get(CacheKeys.StudioData, id);
        if (cachedData) {
          if (callback && lodash.isFunction(callback))
            callback(cachedData.value);
          return resolve(cachedData.value);
        }
      }

      let snapshot = await this.db.object(`/studios/${id}`).query.once("value");
      let studioData = snapshot.val();
      if (!studioData) {
        if (callback && lodash.isFunction(callback)) callback("");
        return resolve("");
      }
      studioData["key"] = id;
      studioData["studioID"] = id;

      // --- set cache
      this.cacheService.set(CacheKeys.StudioData, id, studioData);

      if (callback && lodash.isFunction(callback)) callback(studioData);
      return resolve(studioData);
    });
  }

  // --- get list of all reporting years of organization
  async getAllReportingYears(orgID: string) {
    let reportingYearsObj = (
      await this.db
        .list(`Transactions/${orgID}/ReportingYear`)
        .query.once("value")
    ).val();
    return lodash.map(reportingYearsObj, (ryData: any, key) => {
      return { ...ryData, key };
    });
  }

  /**
   * Get current cycle (reporting year) of org
   * @param orgOrStudioId Organization Or Studio Id
   * @param role Role of the user
   * @returns Reporting Year
   */
  async getOrgCurrentCycle(
    orgOrStudioId: string,
    role: string = Role.ORG,
    propToReturn: "value" | "fullObj" = "value",
    skipCache: boolean = false
  ) {
    // --- return value from local variable if available
    if (
      !skipCache &&
      this.currentCycle &&
      this.currentCycle.orgOrStudioId == orgOrStudioId &&
      this.currentCycle[propToReturn]
    )
      return this.currentCycle[propToReturn];

    const getCycleData = async id => {
      if (!id) return null;
      let snapshot = await this.db
        .list(`Transactions/${id}/ReportingYear`)
        .query.once("value");
      return snapshot.val();
    };

    this.currentCycle["orgOrStudioId"] = orgOrStudioId;
    let reportingYearsObj = await getCycleData(orgOrStudioId);

    // --- if the role is organization and org has no cycle defined, try to fetch cycle from inheritance
    if (!reportingYearsObj && role == Role.ORG) {
      // --- fetch org data
      let orgData = await this.getOrgData(
        orgOrStudioId,
        ["type", "regionID", "conferenceID", "leagueID", "studioID"],
        true
      );

      let cycleDataPromises = [];
      if (orgData && orgData.type == "sportsteam") {
        // inheritance structure org > conf > region > league
        let studioDataPromises = [];
        studioDataPromises.push(this.getStudio(orgData.regionID));
        studioDataPromises.push(this.getStudio(orgData.conferenceID));

        cycleDataPromises = [];
        cycleDataPromises.push(getCycleData(orgData.leagueID));
        cycleDataPromises.push(getCycleData(orgData.regionID));
        cycleDataPromises.push(getCycleData(orgData.conferenceID));
        cycleDataPromises.push(getCycleData("superadmin"));

        let promisesResults;
        try {
          promisesResults = await Promise.all(
            [studioDataPromises, cycleDataPromises].map(pa => Promise.all(pa))
          );
        } catch (e) {
          console.log("Error while resolving promises: ", e);
        }

        if (promisesResults) {
          let studioDataPromisesResults = promisesResults[0];
          let cycleDataPromisesResults = promisesResults[1];
          const inheritanceRecursion = (
            cycleDataPosition,
            studioDataPosition
          ) => {
            let cycleData = cycleDataPromisesResults[cycleDataPosition];
            if (cycleData) return cycleData;

            if (cycleDataPosition == 0) return null;

            let studioData = studioDataPromisesResults[studioDataPosition];
            if (studioData && studioData.hasOwnProperty("inheritFrom")) {
              if (studioData.inheritFrom == orgData.regionID)
                return inheritanceRecursion(1, 0);
              else if (studioData.inheritFrom == orgData.leagueID)
                return inheritanceRecursion(0, null);
            }
            return null;
          };

          let res;
          if (orgData.studioID == orgData.leagueID)
            res = inheritanceRecursion(0, null);
          else if (orgData.studioID == orgData.regionID)
            res = inheritanceRecursion(1, 0);
          else if (orgData.studioID == orgData.conferenceID)
            res = inheritanceRecursion(2, 1);
          if (res) reportingYearsObj = res;
        }
      } else if (orgData && orgData.studioID) {
        cycleDataPromises = [];
        cycleDataPromises.push(getCycleData(orgData.studioID));
        cycleDataPromises.push(getCycleData("superadmin"));
        let [results, err] = await this.executePromise(
          Promise.all(cycleDataPromises)
        );

        let studioReportingYears = results[0];
        let superadminReportingYears = results[1];
        reportingYearsObj = studioReportingYears
          ? studioReportingYears
          : superadminReportingYears;
      }
    }

    let reportingYears = [];
    lodash.forOwn(reportingYearsObj, (val, key) => {
      val["key"] = key;
      reportingYears.push(val);
    });

    let currentReportingYear = reportingYears.filter(ry => {
      return ry["isCurrentYear"] == "Y";
    });
    let currentCalendarYear = new Date().getFullYear();
    let currentCalendarYearRY = lodash.find(
      reportingYears,
      year => year.reportingYear == currentCalendarYear
    );
    if (currentReportingYear.length > 0) {
      this.currentCycle["value"] = currentReportingYear[0].reportingYear;
      this.currentCycle["fullObj"] = currentReportingYear[0];
    } else if (currentCalendarYearRY) {
      currentCalendarYearRY["isCurrentYear"] = "Y";
      this.currentCycle["value"] = currentCalendarYearRY.reportingYear;
      this.currentCycle["fullObj"] = currentCalendarYearRY;

      // --- set reporting year as current year
      this.db
        .object(
          `Transactions/${orgOrStudioId}/ReportingYear/${currentCalendarYearRY.key}/isCurrentYear`
        )
        .set("Y");
    } else {
      this.currentCycle["value"] = `${currentCalendarYear}`;
      this.currentCycle["fullObj"] = {
        isCurrentYear: "Y",
        reportingYear: `${new Date().getFullYear()}`,
        yearStartDate: moment()
          .startOf("year")
          .format("YYYY/MM/DD"),
        default: true
      };

      // --- create reporting year in DB
      let ryData = lodash.cloneDeep(this.currentCycle["fullObj"]);
      delete ryData.default;
      this.db.list(`Transactions/${orgOrStudioId}/ReportingYear`).push(ryData);
    }
    return this.currentCycle[propToReturn];
  }

  /**
   * returns current-month/date (eg. April/20210421)
   */
  getMonthDatePath(momentObject?: moment.Moment) {
    return (momentObject || moment()).format("MMMM/YYYYMMDD/");
  }

  /**
   * check survey answer is correct or not
   * @param correctAns it can be string representing range or set using mathematical symbols like "[]" or "()" or "{} for set" or can be a simple string
   * @param userAns the answer value, which needs to be checked if its correct or incorrect
   * @returns 1 if answer is correct, -1 if answer is incorrect
   */
  checkAnsFormat(correctAns, userAns) {
    if (correctAns == null) return -1;

    correctAns = correctAns.trim();
    if (
      correctAns.includes("[") &&
      correctAns.includes("]") &&
      correctAns.startsWith("[") &&
      correctAns.endsWith("]")
    ) {
      let ansArr = correctAns.split(",");
      let lower = +ansArr[0].replace(/\D/g, "");
      let upper = +ansArr[1].replace(/\D/g, "");
      return userAns >= lower && userAns <= upper ? 1 : -1;
    } else if (
      correctAns.includes("(") &&
      correctAns.includes(")") &&
      correctAns.startsWith("(") &&
      correctAns.endsWith(")")
    ) {
      let ansArr = correctAns.split(",");
      let lower = +ansArr[0].replace(/\D/g, "");
      let upper = +ansArr[1].replace(/\D/g, "");
      return userAns > lower && userAns < upper ? 1 : -1;
    } else if (
      correctAns.includes("{") &&
      correctAns.includes("}") &&
      correctAns.startsWith("{") &&
      correctAns.endsWith("}")
    ) {
      let ansArr = correctAns.split(",");
      ansArr.map((item, index) => {
        ansArr[index] = +item.replace(/\D/g, "");
      });
      return ansArr.indexOf(userAns) > -1 ? 1 : -1;
    } else if (
      correctAns !== undefined &&
      lodash.isString(correctAns) &&
      userAns !== undefined &&
      userAns.toLowerCase() == correctAns.toLowerCase()
    ) {
      return 1;
    } else {
      return -1;
    }
  }

  trimAndLowerCase(str) {
    if (typeof str == "string") {
      str = str.trim().toLowerCase();
      return str;
    }
  }
  checkDivision(division, list) {
    let findKey = "";
    if (division && list.length > 0) {
      let findObj = list.filter(
        item =>
          this.trimAndLowerCase(item.name.toLowerCase()) ==
          this.trimAndLowerCase(division.toLowerCase())
      );
      if (findObj.length > 0) {
        findKey = findObj[0].key;
      }
    }
    return findKey;
  }

  setIndValueUsingSurveyResult(
    surObj,
    individual: IndividualFull,
    divisionList?
  ) {
    let indObj = {};
    try {
      Object.keys(surObj).map(item => {
        let key = "";
        if (item.includes("_")) {
          let keyArr = item.split("_");
          if (keyArr.length > 1) {
            key = keyArr[1];
            if (keyArr[0] == "ind" && individual.hasOwnProperty(key)) {
              if (keyArr[1] == "division") {
                indObj[key] = this.checkDivision(surObj[item], divisionList);
              } else if (keyArr[1] == "birth") {
                indObj[key] = moment(surObj[item]).format("YYYY/MM/DD");
              } else {
                indObj[key] = surObj[item];
              }
            }
          } else {
            key = keyArr[0];
            if (individual.hasOwnProperty(key)) {
              if (keyArr[0] == "division") {
                indObj[key] = this.checkDivision(surObj[item], divisionList);
              } else if (keyArr[1] == "birth") {
                indObj[key] = moment(surObj[item]).format("YYYY/MM/DD");
              } else {
                indObj[key] = surObj[item];
              }
            }
          }
        } else {
          if (individual.hasOwnProperty(item)) {
            if (item == "division") {
              indObj[item] = this.checkDivision(surObj[item], divisionList);
            } else if (item == "birth") {
              indObj[item] = moment(surObj[item]).format("YYYY/MM/DD");
            } else {
              indObj[item] = surObj[item];
            }
          }
        }
      });
      return indObj;
    } catch (e) {
      console.log("error on set ind key", e);
    }
  }

  generateS3SignUrl(obj) {
    let dataObj: any = {
      extensions: obj.extensions,
      path: obj.path
    };
    if (obj.fileName) {
      dataObj.fileName = obj.fileName;
    }
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("x-api-key", environment.awsImageUpload.xApiKey);
    return this.http.post(environment.awsImageUpload.signUrlApi, dataObj, {
      headers: headers
    });
  }
  uploadImageOnS3(signUrlApi, fileObj) {
    let obj = { file: fileObj };
    let file: File = fileObj;
    let head: any = {
      // NOTE: Because we are posting a Blob (File is a specialized Blob
      // object) as the POST body, we have to include the Content-Type
      // header. If we don't, the server will try to parse the body as
      // plain text.
      headers: {
        "Content-Type": file.type
      },
      // added responseType as text because response is getting null
      responseType: "text"
    };
    return this.http.put(signUrlApi, file, head);
  }
  imageUpload(obj, fileObj) {
    return new Promise((response, reject) => {
      this.generateS3SignUrl(obj).subscribe(
        res => {
          if (res) {
            // var buf = new Buffer(fileObj.replace(/^data:image\/\w+;base64,/, ""),'base64')
            this.uploadImageOnS3(res[0].signedUrl, fileObj).subscribe(
              () => {
                response(res[0].fileName);
              },
              err => {
                let userAuth = getQueryParams();
                let message = {
                  timestamp: new Date().getTime(),
                  details: `Failed to upload image from uploadImageOnS3 for orgId:- ${userAuth.orgID} indId:- ${userAuth.indID}, err:- ${err.message}`,
                  type: "error"
                };
                this.http
                  .post(
                    "https://hooks.slack.com/services/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",
                    { channel: "#high5-prod1-bug", text: message },
                    {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                      },
                      responseType: "text"
                    }
                  )
                  .subscribe(
                    res => {},
                    err => {
                      console.log("err: ", err);
                    }
                  );
                reject();
              }
            );
          }
        },
        err => {
          console.log("imageUpload err", err);
          let userAuth = getQueryParams();
          let message = {
            timestamp: new Date().getTime(),
            details: `Failed to upload image from generateS3SignUrl for orgId:- ${userAuth.orgID} indId:- ${userAuth.indID}, err:- ${err.message}`,
            type: "error"
          };
          this.http
            .post(
              "https://hooks.slack.com/services/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",
              { channel: "#high5-prod1-bug", text: message },
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
                },
                responseType: "text"
              }
            )
            .subscribe(
              res => {
                console.log("res: ", res);
              },
              err => {
                console.log("err: ", err);
              }
            );
          reject();
        }
      );
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  convertObjToArr(obj) {
    let returnArr = [];
    Object.keys(obj).forEach(objKey => {
      let value = obj[objKey];
      if (value && lodash.isObject(value)) {
        value["key"] = objKey;
        returnArr.push(value);
      }
    });
    return returnArr;
  }

  prepareErrorMessage(e: any, defMsg?, shouldIncludeDetails?): string {
    if (!e) return defMsg;

    // --- this block is for sso error handling
    if (e instanceof OAuthErrorEvent) {
      if(!shouldIncludeDetails) {
        let errorParams: any = e.params;
        if (errorParams?.error === "access_denied")
          return "We need requested data access to allow you to proceed. If you denied the permission, please try again and approve it to proceed. If you're using school/company account, you may need to contact your IT department to enable access."

        if (errorParams?.error_description) return errorParams.error_description;

        let errorReasonObj: any = e.reason
        if (errorReasonObj?.message) return errorReasonObj.message;
        return "An error occurred while processing your request. Please try again later.";
      }
  
      return `${e.type} :: ${JSON.stringify(e.reason || {})}`;
    }

    // --- this block is for firebase callable functions v2 error handling
    if(e instanceof CloudFnV2Error) {
      if(!shouldIncludeDetails) return e.getErrorMessage();
      else return e.toString();
    }

    if (e instanceof CustomError) {
      if (!shouldIncludeDetails) return e.message;
      if (shouldIncludeDetails)
        return `${e.message} ${e.extraDetails ? e.extraDetails : ""}`.replace(
          /\n/g,
          "<br/>"
        );
    }

    // --- this block is for firebase callable functions error handling
    if(e instanceof HttpErrorResponse) {
      if(!shouldIncludeDetails) return lodash.get(e, "error.error.message") || e.message;
      
      if(shouldIncludeDetails) {
        let message = `${e.message} :: ${lodash.get(e, "error.error.message")}`
        let failureRulesIDs =
            lodash.get(
              e,
              "error.error.details.ruleCheckerResult.error.failedRules",
              []
            ) || [];
        message += ` :: ${failureRulesIDs.join(",")}`;
        return message;
      }
    }

    if (e instanceof Error || (e && typeof e == "object" && e.message)) {
      return e.message;
    }

    // --- this block is for renderer side error (image load failure error)
    if (e && typeof e == "object" && e.error && e.error.details) {
      if (!shouldIncludeDetails)
        return `Output halted: We were unable to load a component of this design.${
          e.error.message ? "\n\nError Message: " + e.error.message : ""
        }\n\nDetails:\n${e.error.details}`;
      else return e.error.details.replace(/\n/g, "<br/>");
    }

    if (lodash.isString(e)) return e;

    if (defMsg) return defMsg;

    try {
      if (lodash.isObject(e)) return JSON.stringify(e);
    } catch (e) {}

    return null;
  }

  // --- get org manager type data
  async getOrgMgrType(orgMgrTypeID: string) {
    if (!orgMgrTypeID) return null;

    return (
      await this.db
        .object(`org-manager-types/${orgMgrTypeID}`)
        .query.once("value")
    ).val();
  }

  // --- get league id
  async getOrgLeagueId(orgId: string) {
    let orgData = await this.getOrgData(orgId, ["leagueID"], true);
    console.log("orgData", orgData);
    return lodash.get(orgData, "leagueID", null);
  }

  // --- get league id
  async getLeagueID(role: string, userID: string) {
    if (!role || !userID) return null;

    if (role == Role.ORG) {
      return await this.getOrgLeagueId(userID);
    }

    if (role == Role.STUDIO) {
      let studioData = await this.getStudio(userID);

      let leagueID = lodash.get(studioData, "leagueID");
      if (leagueID) return leagueID;

      let orgMgrType = lodash.get(studioData, "orgManagerType");
      if (!orgMgrType) return null;

      let orgMgrTypeData = await this.getOrgMgrType(orgMgrType);
      return lodash.get(orgMgrTypeData, "name", null) == "League"
        ? userID
        : null;
    }

    return null;
  }

  divisionsChangesListeners = [];
  /**
   * Read List of Divisions for a given league id
   * @param leagueId League Id
   * @param changesListener Changes Listener Which will be triggered everytime when divisions changes
   * @returns Promise of List of Divisions
   */
  divisionsList: LeagueDivision[] = [];
  getDivisions(
    leagueId: string,
    changesListener?: (divisionsList: LeagueDivision[]) => void
  ): Promise<LeagueDivision[]> {
    return new Promise(resolve => {
      if (!leagueId) throw Error("League Id Required!");

      if (changesListener) this.divisionsChangesListeners.push(changesListener);

      if (this.divisionsList.length > 0) {
        // --- send triggers to all registered listeners
        lodash.each(this.divisionsChangesListeners, listener =>
          listener(lodash.cloneDeep(this.divisionsList))
        );

        return resolve(lodash.cloneDeep(this.divisionsList));
      }

      this.subscriptions.push(
        this.db
          .list(`${this.basePath}/${leagueId}/${this.divisionsPath}`)
          .snapshotChanges()
          .subscribe(val => {
            // --- refresh data of divisionsList with new updated data
            this.divisionsList = [];
            lodash.each(val, entry => {
              let rule = entry.payload.val();
              rule["key"] = entry.payload.key;
              this.divisionsList.push(rule);
            });

            // --- send triggers to all registered listeners
            lodash.each(this.divisionsChangesListeners, listener =>
              listener(lodash.cloneDeep(this.divisionsList))
            );

            // --- finally resolve the promise
            return resolve(lodash.cloneDeep(this.divisionsList));
          })
      );
    });
  }

  // --- convert callback function to promise
  promisify(fn: Function, callbackArgPosition, self) {
    return function(...args) {
      return new Promise((resolve, reject) => {
        let callbackFunction = data => {
          resolve(data);
        };
        args[callbackArgPosition] = callbackFunction;

        fn.call(self, ...args);
      });
    };
  }

  /**
   * execute promises with error handling built in
   * @param promise Promise function
   * @returns array containing 2 elements, 1st is result and 2nd is error message if any
   */
  async executePromise(promise: Promise<any>) {
    let res = [null, null];
    try {
      res = [await promise, null];
    } catch (e) {
      res = [null, e];
    }
    return res;
  }

  // --- execute observables with parallel execution limit (eg. max 10 observable executions should run parallel)
  executeObservablesWithParallelLimit(
    observables: Observable<any>[],
    limit: number = 10
  ) {
    return this.executePromise(
      from(observables)
        .pipe(mergeAll(limit))
        .pipe(reduce((acc, val) => acc.concat(val), []))
        .toPromise()
    );
  }

  /**
   * get url of give firebase storage path
   * @param pathOrURL Firebase Path or Any URL
   * @returns URL of the file/image
   */
  async fetchFirebaseStorageDownloadURL(pathOrURL) {
    if (!pathOrURL) return null;

    if (
      pathOrURL.indexOf("http") > -1 ||
      pathOrURL.indexOf("firebasestorage") > -1
    )
      return pathOrURL;

    let [logoUrl, error] = await this.executePromise(
      this.firebaseStorage
        .ref(pathOrURL)
        .getDownloadURL()
        .pipe(take(1))
        .toPromise()
    );
    if (logoUrl) return logoUrl;
    if (error) console.log("Error while fetching logo url: ", error);
    return null;
  }

  /**
   * set app language
   * @param language language short code
   */
  setAppLanguage(language: Language) {
    this.translateService.use(language);
  }
  /**
   * set default student message template
   * @param type is a broadcast medium
   */
  msgSymbol = "~^";
  getStudentReasonMessageTemplate(type: string): string {
    switch (type) {
      case "sms":
        return `From ${this.msgSymbol}org.displayName${this.msgSymbol}:\n ${this.msgSymbol}Ind.firstName${this.msgSymbol} ${this.msgSymbol}Ind.lastName${this.msgSymbol} checked in tardy at ${this.msgSymbol}crossing.timestamp${this.msgSymbol}\n today. \n
        The reason given was\n${this.msgSymbol}crossing.Reason${this.msgSymbol}`;
      case "email":
        return `From ${this.msgSymbol}org.displayName${this.msgSymbol}:\n ${this.msgSymbol}Ind.firstName${this.msgSymbol} ${this.msgSymbol}Ind.lastName${this.msgSymbol} checked in tardy at ${this.msgSymbol}crossing.timestamp${this.msgSymbol}\n today. \n
        The reason given was\n${this.msgSymbol}crossing.Reason${this.msgSymbol}`;
      case "email_sub":
        return `${this.msgSymbol}Ind.firstName${this.msgSymbol} ${this.msgSymbol}Ind.lastName${this.msgSymbol} late arrival`;
    }
  }
  // --- get broadcast type name using number
  getBroadcastName(num: any, type: string): any {
    if (type == "name") {
      switch (num) {
        case 1:
          return "SMS / Text message";
        case 2:
          return "Email - Plain Text";
        case 3:
          return "Email - HTML";
      }
    } else {
      switch (num) {
        case "sms":
          return 1;
        case "email":
          return 2;
        case "emailHtml":
          return 3;
      }
    }
  }

  // --- get broadcast type name using number
  getRecipientName(recipient: any, type: string): any {
    if (type == "name") {
      switch (recipient) {
        case 1:
          return "Guardian only";
        case 2:
          return "Guardian or Individual";
        case 3:
          return "Individual or Guardian";
        case 4:
          return "Guardian and Individual";
        case 5:
          return "Individual";
      }
    } else {
      switch (recipient) {
        case "Guardian only":
          return 1;
        case "Guardian or Individual":
          return 2;
        case "Individual or Guardian":
          return 3;
        case "Guardian and Individual":
          return 4;
        case "Individual":
          return 5;
      }
    }
  }
  filterOptInInd(indList: any[]): any[] {
    indList = indList.filter((ind: IndividualFull) => {
      return (
        !ind.hasOwnProperty("optin") ||
        (ind.hasOwnProperty("optin") && ind.optin == true)
      );
    });
    return indList;
  }

  /**
   * Remove un-necessary properties (Key-value) from object
   */
  cleanUpObj(obj, allowedProps: string[]) {
    lodash.forOwn(obj, (val, key) => {
      // --- remove not allowed props
      if (!allowedProps.includes(key)) delete obj[key];

      // --- remove null/empty/undefined valued props
      if (val == undefined || val == null || val == "") delete obj[key];

      // --- if phone is included in params, format it properly
      if (key == "phone" && obj.phone)
        obj.phone = this.formatPhoneNumber(obj.phone);
    });
    return obj;
  }

  // --- calculate tax
  async getTax(requestObj) {
    let requestBody = {
      type: "getTaxDetails",
      cartID: "usdfsdfdsfdsfdsuid222",
      deliveredBySeller: true,
      origin: {
        Address1: "4219 S Market Ct Ste N Sacremento, CA 95834",
        City: "Sacremento, CA",
        State: "CA",
        Zip5: "95834"
      },
      ...requestObj
    };

    let [response, error] = await this.executePromise(
      this.http
        .post(`${environment.newApiBaseUrl}/api.php`, requestBody)
        .pipe(timeout(30 * 1000))
        .pipe(take(1))
        .toPromise()
    );

    let success = lodash.get(response, "success");
    if (!error && !success) error = lodash.get(response, "message");

    if (error) throw new Error(this.prepareErrorMessage(error));

    response = lodash.get(response, "data");
    let cartItemsTaxData = lodash.get(response, "CartItemsResponse");
    if (!cartItemsTaxData || cartItemsTaxData.length == 0) {
      let messages = lodash.get(response, "Messages");
      let errorMessages = [];
      if (messages && messages.length > 0) {
        lodash.each(messages, message => {
          let msg: string = lodash.get(message, "Message", "");
          errorMessages.push(
            msg.toLowerCase().indexOf("not valid for this state") > -1
              ? `Teammate Zipcode is not valid (${requestObj.destination.Zip5}).`
              : msg
          );
        });
      } else {
        errorMessages.push("Something went wrong calculating tax.");
      }
      throw new Error(errorMessages.join("<br/>"));
    }

    return cartItemsTaxData;
  }

  floatingValue(x) {
    return +Number.parseFloat(x).toFixed(2);
  }

  /**
   *
   * @param orgTypeName orgType Name
   * @param indRole individual role
   */
  getFlagsListUsingOrgTypeNameAndRoleName(
    orgTypeName: string,
    indRole: string
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let orgTypeSub = this.db
        .list(`/org-types/`, ref =>
          ref.orderByChild("name").equalTo(orgTypeName)
        )
        .snapshotChanges()
        .subscribe(orgType => {
          orgTypeSub.unsubscribe();
          if (orgType.length > 0) {
            let orgTypeData: any;
            orgType.forEach(ele => {
              orgTypeData = ele.payload.val();
              orgTypeData.key = ele.payload.key;
            });
            if (orgTypeData) {
              if (orgTypeData.roles) {
                let roleArr = this.convertObjToArr(orgTypeData.roles);
                let currentRole = roleArr.filter(role => {
                  return role.name == indRole;
                });
                if (currentRole.length > 0 && currentRole[0].flags) {
                  let flagsArr = this.convertObjToArr(currentRole[0].flags);
                  resolve(flagsArr);
                  return;
                }
              }
              resolve([]);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        });
    });
  }

  // --- merge two arrays
  mergeTwoArrUsingKeyMatch(arr1, arr2) {
    let resultantArr = arr1;
    if (!arr2 || arr2.length == 0) return resultantArr;

    for (let i = 0; i < arr2.length; i++) {
      const ele = arr2[i];
      if (!arr1.find(o => o.key === ele.key)) {
        resultantArr.push(ele);
      }
    }
    return resultantArr;
  }

  getOrgTypeIdUsingName(name: string) {
    return new Promise((resolve, reject) => {
      let orgTypeSub = this.db
        .list(`/org-types/`, ref => ref.orderByChild("name").equalTo(name))
        .snapshotChanges()
        .subscribe(orgType => {
          orgTypeSub.unsubscribe();
          if (orgType.length > 0) {
            let orgTypeData: any;
            orgType.forEach(ele => {
              orgTypeData = ele.payload.val();
              orgTypeData.key = ele.payload.key;
            });
            resolve(orgTypeData);
          }
        });
    });
  }

  /**
   * get organization data
   * @returns org data
   * @deprecated use the same method from orgService instead
   */
  async getOrgData(
    orgID: string,
    propsToReturn: string[] = ["all"],
    useCache: boolean = false,
    callback?: (orgData: any) => void
  ) {
    if (!orgID || !propsToReturn) throw new Error("Params missing!");

    // --- prepare cache path
    let cachePath = `${orgID}/`;
    cachePath += this.getCachePathForProps(propsToReturn);

    let orgData: any;

    // --- read from cache
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.OrgData, cachePath);
      if (cachedData) orgData = cachedData.value;
    }

    // --- read org data from database
    if (!orgData) {
      let reqBody = { orgID, propsToReturn };
      let orgDataRes = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getOrgData,
        reqBody
      );
      orgData = lodash.get(orgDataRes, "result.data");

      // --- set cache
      this.cacheService.set(CacheKeys.OrgData, cachePath, orgData);
    }

    if (callback && lodash.isFunction(callback)) callback(orgData);
    return orgData;
  }

  /**
   * Format phone number
   * @param phone Phone string
   * @returns formatted phone string
   */
  formatPhoneNumber(phone) {
    if (!phone) return "";
    let formattedPhone = phone;

    // --- replace first occurrence with + because sometimes in url,
    // + and space both encoded using %20 so first occurrence is + and others are space
    if (phone.startsWith("%20")) {
      formattedPhone = phone.replace("%20", "+");
    } else if (phone.startsWith("%25")) {
      formattedPhone = phone.replace("%25", "+");
    } else if (phone.startsWith("%2B")) {
      formattedPhone = phone.replace("%2B", "+");
    }

    formattedPhone = formattedPhone.replace(/%20/gi, "");

    return formattedPhone;
  }

  saveBroadcast(
    orgId: string,
    broadcastData: any,
    currentCycle: string,
    key: string
  ) {
    return this.db
      .list(
        `/Transactions/${orgId}/${currentCycle}/communications/broadcastReport/`
      )
      .update(key, broadcastData);
  }

  // create firebase push id
  createFirebasePushId() {
    return this.db.createPushId();
  }

  createIndId(): string {
    let indId: string = this.createFirebasePushId();
    return indId.substring(1);
  }

  getWellFormattedPhone(num: string) {
    num = `${num}`; // --- convert to string
    if (num.includes("+")) {
      return num.toString().replace(/[- )(]/g, "");
    } else {
      let number = num.toString().replace(/\D/g, "");
      // --- add international prefix
      if (number.length > 10) {
        number = "+" + number;
      } else if (number.length < 10) {
        number = "+1" + number;
      }
      return number;
    }
  }

  getCountryCode(number: string) {
    const phoneNumber = parsePhoneNumberFromString(number, "US");
    if (phoneNumber && phoneNumber.country) {
      return { countryCode: phoneNumber.country, number: phoneNumber.number };
    } else {
      if (phoneNumber && phoneNumber.number) {
        return { countryCode: "US", number: phoneNumber.number };
      } else {
        return { countryCode: "US", number: number };
      }
    }
  }

  getCurrentURLRootPath() {
    let path = `${location.protocol}//${location.host}`;
    // if (location.host.indexOf("landingstage.high5.id") > -1) {
    //   path += `/stage`;
    // }
    return path;
  }

  async redirectToHigh5Kiosk(authApi: AuthApiService) {
    // --- prepare redirect url to high5 web build
    let redirUrl;
    if (location.host.indexOf("landing.high5.id") > -1) {
      redirUrl = "https://high5.id/v/#/identify-kiosk";
    } else if (location.host.indexOf("landingstage.high5.id") > -1) {
      redirUrl = "https://high5.id/stage/#/identify-kiosk";
    } else {
      redirUrl = "http://localhost:8100/";
    }

    // --- logout current user
    await this.executePromise(authApi.logout(undefined, undefined, redirUrl));
  }

  // update landing page background
  updateBackGround(backgroundImgPath?, topHeight = 0) {
    if (document.getElementById("backgroundImg") !== null) {
      let div = document.getElementById("backgroundImg");
      if (backgroundImgPath) {
        div.style.backgroundImage = `url(${backgroundImgPath})`;
      }
      if (document.getElementById("folder-div") !== null) {
        let topDiv = document.getElementById("folder-div");
        let height = topDiv.offsetHeight + topHeight;
        div.style.backgroundPosition = `right ${height}px`;
      } else {
        div.style.backgroundPosition = "right 0px";
      }
    }
  }
  removeBackground() {
    if (document.getElementById("backgroundImg") !== null) {
      let div = document.getElementById("backgroundImg");
      div.style.backgroundSize = "0 0";
    }
  }

  /**
   * convert string to boolean
   * @param val value to be converted to boolean
   * @returns boolean value of the given value
   */
  getBool(val) {
    if (lodash.isBoolean(val)) return val;
    if (lodash.isString(val)) {
      return val == "true" ? true : false;
    }
    return false;
  }

  // --- open fullscreen
  async openFullscreen() {
    let elem: any = document.documentElement;

    let method =
      elem.requestFullscreen ||
      elem.mozRequestFullScreen || // --- Firefox
      elem.webkitRequestFullscreen || // --- Chrome, Safari and Opera
      elem.msRequestFullscreen; // --- IE/Edge

    if (method) {
      try {
        await method.call(elem);
      } catch (e) {
        console.log("Error in opening full screen: ", e);
      }
    }
  }

  // --- close full screen
  async closeFullscreen() {
    let elem: any = document.documentElement;

    let isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );
    elem = isSafariBrowser ? elem : document;

    let method =
      elem.exitFullscreen ||
      elem.mozCancelFullScreen || // --- /* Firefox */
      elem.webkitExitFullscreen || // --- /* Chrome, Safari and Opera */
      elem.msExitFullscreen; // --- /* IE/Edge */

    try {
      await method.call(elem);
    } catch (e) {
      console.log("Error while closing full screen mode: ", e);
    }
  }

  generateRandomPassword() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i <= 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // --- convert roles to array of obj
  convertRolesToArrayOfObj(arr: any) {
    let rolesArr = [{ displayName: "Any", value: "Any" }];
    arr.forEach(ele => {
      rolesArr.push({ displayName: ele.name, value: ele.name });
    });
    return rolesArr;
  }

  // ---- convert roles to array of string
  convertRolesStrArr(rolesArr) {
    let roleArr = [];
    rolesArr.forEach(role => {
      roleArr.push(role.name);
    });
    return roleArr;
  }

  // --- get roles list for given org type
  async getOrgTypeRoles(orgType: string) {
    let snapshot = await this.db
      .list(`org-types`)
      .query.orderByChild("id")
      .equalTo(orgType)
      .once("value");
    let matchingOrgTypesData = snapshot.val();
    return lodash
      .chain(matchingOrgTypesData)
      .map((oTypeData: any) => lodash.map(oTypeData.roles, "name"))
      .flatten()
      .uniq()
      .value();
  }

  isURLValid(url: string) {
    if (!url) return false;
    if (lodash.includes(url, "127.0.0.1")) return true;
    let urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return urlRegex.test(url);
  }

  // --- create file from data and download it
  saveDataAsFile(
    blob,
    fileName // does the same as FileSaver.js
  ) {
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  // --- download file from URL
  downloadFileFromURL(url: string, filenameWithExt) {
    return new Promise(async (resolve, reject) => {
      let [response, err] = await this.executePromise(
        this.http
          .get(url, { responseType: "blob" })
          .pipe(take(1))
          .toPromise()
      );
      if (err) return reject(err);

      this.saveDataAsFile(response, filenameWithExt);
      return resolve("");
    });
  }

  // --- trigger download through browser from URL
  triggerBrowserDownloadFromURL(url: string, filenameWithExt?) {
    const a = document.createElement("a");
    a.href = url;
    if (filenameWithExt) a.download = filenameWithExt;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  getFileExtention(fname) {
    if (fname) {
      return fname.slice((Math.max(0, fname.lastIndexOf(".")) || Infinity) + 1);
    } else {
      return "";
    }
  }

  downloadFile(filename, text, type) {
    if (text != null && navigator.msSaveBlob)
      return navigator.msSaveBlob(new Blob([text], { type: type }), filename);
    var encodedUri = type + "," + encodeURIComponent(text);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }

  Array2CSV(array, download: boolean, delimiter: string = ",", fileName?) {
    let type = "data:text/csv;charset=utf-8";
    let csvContent = "\uFEFF";

    lodash.each(array, rowArray => {
      let row;
      if (lodash.isObject(rowArray) === true) {
        row = lodash.values(rowArray);
        row = row.join(delimiter);
      } else {
        row = rowArray;
        row = row.join(delimiter);
      }
      csvContent += row + "\r\n";
    });
    if (download === true) {
      if (fileName) {
        fileName = this.getFileExtention(fileName)
          ? fileName
          : fileName + ".csv";
      } else {
        fileName = "database.csv";
      }
      this.downloadFile(fileName, csvContent, type);
      alert("Report Download Successfully.");
    }

    return csvContent;
  }

  // ---- check blank, undefined or null value
  isBlank(val: string): boolean {
    return !(val != "" && val != undefined && val != null);
  }

  // --- unsubscribe subscription with safe checks
  unSub(x: Subscription) {
    if (x && !x.closed) {
      x.unsubscribe();
    }
  }

  // --- check email validation
  isEmailValid(email: string): Boolean {
    let regexp: RegExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!email || this.isBlank(email) || !regexp.test(email)) {
      return false;
    }
    return true;
  }

  /**
   * Check if the current device is mobile/tablet or something else
   * @returns true if device is mobile/tablet. false otherwise
   */
  mobileAndTabletCheck() {
    let check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  //
  stopCurrentlyRunningCamera() {
    return new Promise((resolve, reject) => {
      // STOP ALL ACTIVE CAMERA
      const video = document.querySelector("video");
      if (video) {
        navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then(
          (stream: MediaStream) => {
            // A video's MediaStream object is available through its srcObject attribute
            const mediaStream = stream;
            // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
            const tracks = mediaStream.getTracks();
            // Tracks are returned as an array, so if you know you only have one, you can stop it with:
            if (tracks.length == 1) {
              tracks[0].stop();
            } else {
              // Or stop all like so:
              tracks.forEach(track => {
                track.enabled = false;
                track.stop();
              });
            }
            resolve("");
          },
          err => {
            console.log("err: ", err);
          }
        );
      } else {
        resolve("");
      }
    });
  }

  // --- clean up object by removing undefined/null valued keys
  cleanUpObjForDBPush(obj) {
    obj = lodash.cloneDeep(obj);
    lodash.each(obj, (val, key) => {
      if (lodash.isObject(val)) {
        obj[key] = this.cleanUpObjForDBPush(val);
      } else if (lodash.isNil(val)) {
        delete obj[key];
      }
    });
    return obj;
  }

  // --- generate random integer in the given range
  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getBgColor() {
    let name = Background;
    let length = Background.length;
    let index = Math.floor(Math.random() * length);
    return name[index];
  }

  getWebsiteSettingsHintText(deviceInfo) {
    // --- prepare website settings hint based on browsers
    let osModel = lodash
      .chain(deviceInfo)
      .get("device.OS.model")
      .toLower()
      .value();
    let browserModel = lodash
      .chain(deviceInfo)
      .get("device.Browser.model")
      .toLower()
      .value();
    let websiteSettingsHint = "";
    switch (browserModel) {
      case "safari":
        if (osModel.indexOf("ios") > -1) {
          // --- for iphone
          websiteSettingsHint =
            'Tap "aA" on the address bar > website settings';
        } else if (osModel.indexOf("mac os") > -1) {
          // --- for desktop Mac OS
          websiteSettingsHint = "Safari > Preferences > Websites > Camera";
        }
        break;
    }
    return websiteSettingsHint;
  }

  /**
   * helper function to sleep the code for some time
   * @param timeout sleep time in milliseconds
   * @returns promise which resolves after given timeout
   */
  async sleep(timeout = 1000) {
    return new Promise(resolve => {
      setTimeout(() => {
        return resolve("");
      }, timeout);
    });
  }

  checkAssingedOnKey(key) {
    if (key.includes("_")) {
      key = key.substring(0, key.length - 5);
    }
    return key;
  }

  guid(len) {
    var buf = [],
      chars = "0123456789",
      charlen = chars.length,
      length = len || 32;
    for (var i = 0; i < length; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }
    return buf.join("");
  }

  prepareWelcomeMailBodyUrl() {
    return new Promise((resolve, reject) => {
      let actualLink;
      if (location.host.indexOf("high5.id") > -1) {
        this.db
          .object(`/settings/appVersion`)
          .valueChanges()
          .subscribe(appVersion => {
            if (location.host.indexOf("landingstage.high5.id") > -1) {
              actualLink = `https://high5.id/stage/v${appVersion}?ver=${appVersion}`;
            } else {
              actualLink = environment.redirectToHigh5;
            }
            resolve({ actualLink });
          });
      } else {
        this.db
          .object(`/settings/appVersion`)
          .valueChanges()
          .subscribe(appVersion => {
            actualLink = `https://high5.id/stage/v${appVersion}?ver=${appVersion}`;
            resolve({ actualLink });
          });
      }
    });
  }

  // --- load script from given url
  async loadScript(url: string) {
    if (!url || !this.isURLValid(url)) return false;

    // --- check if script is already loaded
    var len = $("script").filter(function() {
      return $(this).attr("src") == url;
    }).length;
    if (len > 0) return true;

    // --- add script
    let scriptElem = $("<script>").appendTo("head");
    let onScriptLoad = new Promise((resolve, reject) => {
      scriptElem.on("load", resolve);
      scriptElem.on("error", reject);
    });
    scriptElem.attr("src", url);

    // --- listen for script load
    let [loadRes, loadErr] = await this.executePromise(onScriptLoad);
    if (loadErr) {
      console.log("script loadErr", loadErr);
      scriptElem.remove(); // remove appended script element as script failed to load
      return false;
    }

    return true;
  }

  // --- load stylesheet from given url
  async loadStylesheet(url: string) {
    if (!url || !this.isURLValid(url)) return false;

    // --- check if stylesheet is already loaded
    var len = $("link").filter(function() {
      return $(this).attr("href") == url;
    }).length;
    if (len > 0) return true;

    // --- add stylesheet
    let linkElem = $("<link>").appendTo("head");
    let onLinkLoad = new Promise((resolve, reject) => {
      linkElem.on("load", resolve);
      linkElem.on("error", reject);
    });
    linkElem.attr("rel", "stylesheet");
    linkElem.attr("href", url);

    // --- listen for stylesheet load
    let [loadRes, loadErr] = await this.executePromise(onLinkLoad);
    if (loadErr) {
      console.log("stylesheet loadErr", loadErr);
      return false;
    }

    return true;
  }

  // --- connect renderer
  private async loadRenderer() {
    let loadPromises = [];
    loadPromises.push(
      this.loadScript(CommonServiceService.rendererScripts.rendererURL)
    );
    loadPromises.push(
      this.loadScript(CommonServiceService.rendererScripts.rendererContainerURL)
    );
    let results = await Promise.all(loadPromises);
    return lodash.every(results, res => res == true);
  }

  // --- load and connect to renderer
  vipPassRenderer;
  private async connectRenderer() {
    // --- load renderer
    let isRendererLoaded = await this.loadRenderer();
    if (!isRendererLoaded) throw new Error("Error loading renderer!");

    // --- create vipPassRenderer instance
    const options = {
      iframe: document.getElementById("iframe-renderer"),
      onMessage: (type, data) => {}
    };
    this.vipPassRenderer = new VipPassRendererInterOp(options);

    // --- connect to renderer
    let currentTimeStamp = Date.now();
    let rendererIframeElem = $("#iframe-renderer");
    if (!rendererIframeElem.length)
      throw new Error("Iframe not found to load renderer into!");

    let prepareForRendererConnection = new Promise((resolve, reject) => {
      rendererIframeElem.on("load", resolve);
      rendererIframeElem.on("error", reject);
    });
    rendererIframeElem.attr(
      "src",
      `${environment.rendererBaseUrl}/app/index.html?v=${currentTimeStamp}`
    );
    await prepareForRendererConnection;

    await this.vipPassRenderer.connect();

    return true;
  }

  // --- load and connect to renderer
  connectToRendererPromise: Promise<boolean>;
  connectToRendererResult = null;
  async loadAndConnectRenderer(): Promise<boolean> {
    if (this.connectToRendererResult != null)
      return this.connectToRendererResult;

    if (this.connectToRendererPromise != null)
      return this.connectToRendererPromise;

    this.connectToRendererPromise = this.connectRenderer();
    let [result, errConnectingRenderer] = await this.executePromise(
      this.connectToRendererPromise
    );
    this.connectToRendererPromise = null;
    if (errConnectingRenderer) throw errConnectingRenderer;
    else this.connectToRendererResult = result;

    return this.connectToRendererResult;
  }

  // --- start ID timestamp refreshing
  async startSVGTimestampTick() {
    try {
      if (
        lodash.has(this.vipPassRenderer, "TimerDelayMSec") &&
        this.vipPassRenderer.TimerDelayMSec() == 0
      )
        return;

      this.vipPassRenderer.startTimestampRefresh();
    } catch (e) {
      console.log("Error in starting SVG timestamp tick!", e);
    }
  }

  async stopSVGTimestampTick() {
    try {
      this.vipPassRenderer.stopTimestampRefresh();
    } catch (e) {
      console.log("Error in stoping SVG timestamp tick!", e);
    }
  }

  async startCountDown(millis: number) {
    try {
      this.vipPassRenderer.startCountDown(millis, 1000);
    } catch (e) {
      console.log("Error in starting SVG timestamp tick!", e);
    }
  }

  toQRCode(code: string, size: number) {
    // set QR code
    var qr = new QRious({
      value: code,
      size: size
    });

    var qrBase64 = qr.toDataURL();

    return qrBase64;
  }

  prepareElasticReqBodyNew(obj: any) {
    let requestBody = JSON.stringify(obj);
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      environment.edk
    );
    return encryptedBody;
  }

  // Decrypt Response
  decryptResponse(res: string) {
    return JSON.parse(this.encryptionService.decrypt(res, environment.edk));
  }

  // --- generate form-factor object from given width/height
  generateFormFactor(
    width: number,
    height: number,
    orientation: "portrait" | "landscape" = "portrait"
  ) {
    return {
      Units: "pixel",
      Pixels: "pixel",
      portrait: {
        cHeight: orientation == "portrait" ? height : width,
        cWidth: orientation == "portrait" ? width : height,
        fHeight: orientation == "portrait" ? height : width,
        fWidth: orientation == "portrait" ? width : height
      },
      landscape: {
        cHeight: orientation == "landscape" ? height : width,
        cWidth: orientation == "landscape" ? width : height,
        fHeight: orientation == "landscape" ? height : width,
        fWidth: orientation == "landscape" ? width : height
      }
    };
  }

  // --- fetch SVG-XML contents from URL
  async fetchXML(sourceURL: string, useCache: boolean = false) {
    if (!sourceURL) return null;

    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.SVGData, `${sourceURL}`);
      if (cachedData) return cachedData.value;
    }

    const getContentsFromURL = (url: string) => {
      let retryCount = 0;
      let maxRetryCount = 3;
      const getContent = async () => {
        // --- make request
        let [response, error] = await this.executePromise(fetch(url));

        // --- handle retries
        if (
          (error || (response && response.status == 504)) &&
          retryCount < maxRetryCount
        ) {
          retryCount++;
          await this.sleep(500);
          return getContent();
        }

        // --- handle response/error
        if (!response || error) return null;
        let [svgData, err] = await this.executePromise(response.text());
        if (!svgData || err) return null;

        // --- set cache
        this.cacheService.set(CacheKeys.SVGData, `${url}`, svgData);

        return svgData;
      };
      return getContent();
    };

    return await getContentsFromURL(sourceURL);
  }

  // --- replace special char with _
  createId(str: string): string {
    return str.replace(/[^A-Za-z0-9]/gi, "_");
  }

  /**
   *
   * @param field1 field1
   * @param field2 field2
   * @param obj {type: 'ind, org, orgmanager', orgId: '', indId: '', orgmanagerId: '', userData: {}}
   */
  async computeDateRange(
    field1: string,
    field2: string,
    refTimeStamp: any
  ): Promise<boolean> {
    if (!field1 || !field2) return false;
    let compareTimestamp = isNaN(refTimeStamp)
      ? Number(moment().format("x"))
      : Number(refTimeStamp);
    return (field1 && !field2 && Number(field1) <= compareTimestamp) ||
      (!field1 && field2 && Number(field2) > compareTimestamp) ||
      (field1 &&
        field2 &&
        Number(field1) < compareTimestamp &&
        Number(field2) > compareTimestamp)
      ? true
      : false;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode != 40 &&
      charCode != 41 &&
      charCode != 43 &&
      charCode != 45
    ) {
      return false;
    }
    return true;
  }

  // --- map object keys in nested object
  mapNestedKeys(obj: any, modifierFn: (key: string) => string) {
    // prevent modifying original object
    obj = lodash.cloneDeep(obj);

    let mappedObj = lodash.isArray(obj) ? [] : {};

    lodash.each(obj, (value, key) => {
      if (lodash.isObject(value)) {
        let mappedSubObj = this.mapNestedKeys(value, modifierFn);
        let newKey = modifierFn(key);
        mappedObj[newKey] = mappedSubObj;
      } else {
        let newKey = modifierFn(key);
        mappedObj[newKey] = value;
      }
    });

    return mappedObj;
  }

  // --- map object values in nested object
  async mapNestedObjValues(
    obj: any,
    modifierFn: (value: any, key: string) => Promise<any>,
    valueInterceptor?: (value: any, key: any) => any
  ) {
    // prevent modifying original object
    obj = lodash.cloneDeep(obj);

    // --- wrapper function which wrapps modifierFn to return key and replacement value both
    const modifierFnWrapper = async (
      key: string,
      value: any,
      modifierFn: (value: any, key: string) => Promise<any>
    ) => {
      let replacementValue = await modifierFn(value, key);
      return { key, replacementValue };
    };

    let promises = lodash.map(obj, (value, key) => {
      if (valueInterceptor) value = valueInterceptor(value, key);

      if (lodash.isObject(value) || lodash.isArray(value)) {
        return this.mapNestedObjValues(value, modifierFn).then(
          replacementValue => {
            return { key, replacementValue };
          }
        );
      } else {
        return modifierFnWrapper(key, value, modifierFn);
      }
    });

    let promisesRes = await Promise.all(promises);
    lodash.each(promisesRes, result => {
      if (!result || lodash.isNil(result.key)) return;
      lodash.set(obj, result.key, result.replacementValue);
    });

    return obj;
  }

  // --- compares 2 objects property wise (nested objects also supported), and returns difference object containing only changed key-values
  // eg.
  // originalObj = { a: "b", c: "d", e: { f: "g", h: "i" } }
  // modifiedObj = { a: "k", c: "d", e: { f: "a", h: "i" } }
  // function will return differenceObj = { a: "k", e: { f: "a" } }
  getDifferenceObj(
    originalObj,
    modifiedObj,
    areValuesEqual: (val1, val2) => boolean = (val1, val2) => val1 == val2
  ) {
    let differenceObj = {};
    lodash.each(modifiedObj, (value, prop) => {
      if (lodash.isObject(value)) {
        let diffObj = this.getDifferenceObj(originalObj[prop] || {}, value);
        if (!lodash.isEmpty(diffObj)) differenceObj[prop] = diffObj;
      } else {
        let originalValue = originalObj[prop];
        if (!areValuesEqual(originalValue, value)) {
          differenceObj[prop] = value;
        }
      }
    });
    return differenceObj;
  }

  // --- flatten nested object for db update operation
  // eg.
  // obj = { a: "k", e: { f: "a", x: { y: z } } }
  // result returned will be { a: "k", 'e/f': "a", 'e/x/y': z }
  flattenObjForDBUpdate(obj) {
    if (!obj || !lodash.isObject(obj)) return obj;

    let result = {};
    const recursiveTraversal = (data, traversalPath = "") => {
      lodash.each(data, (value, prop) => {
        let fullPath = `${traversalPath}${prop}`;
        if (lodash.isObject(value)) {
          recursiveTraversal(value, `${fullPath}/`);
        } else {
          result[fullPath] = value;
        }
      });
    };
    recursiveTraversal(obj);

    return result;
  }

  // --- present alert
  async presentAlert(
    msg: string,
    title: string = "High5",
    positiveBtnTxt: string = "Ok",
    negativeBtnTxt?: string,
    modalOptions: {
      dismissOnOutsideClick?: boolean;
      showCloseBtn?: boolean;
    } = {}
  ) {
    let options: NgbModalOptions = {};
    if (modalOptions.dismissOnOutsideClick == false) {
      options.backdrop = "static";
      options.keyboard = false;
    }

    let showCloseBtn = lodash.get(modalOptions, "showCloseBtn", false);

    let [result, err] = await this.executePromise(
      this.alertDialogService.confirm(
        title,
        msg,
        positiveBtnTxt,
        negativeBtnTxt,
        null,
        !showCloseBtn,
        options
      )
    );

    if (err) return false;
    return result;
  }

  // --- show error dialog
  async handleError(
    err: any,
    title: string = "Error",
    modalOptions: { dismissOnOutsideClick?: boolean } = {}
  ) {
    console.log("err", err);

    return await this.presentAlert(
      this.prepareErrorMessage(err),
      title,
      "Ok",
      null,
      modalOptions
    );
  }

  // --- get fields list for given organization (based on org licenses)
  async getFieldsForOrg(
    orgID: string,
    urlService: UrlService,
    licenseService: LicenseService,
    usersData?: any,
    useCache?: boolean
  ) {
    let readDataPromises = [];

    // --- read fields by inheritance
    readDataPromises.push(
      urlService.getFieldsByInheritance(Role.ORG, orgID, usersData, useCache)
    );

    // --- read org licenses
    readDataPromises.push(licenseService.getOrgLicensesArr(orgID));

    let readDataPromisesRes = await Promise.all(readDataPromises);
    let fieldsList = lodash.nth(readDataPromisesRes, 0);
    let orgLicenses = lodash.nth(readDataPromisesRes, 1);

    // --- filter fields list by licenses
    fieldsList = lodash.filter(
      fieldsList,
      fieldData =>
        fieldData &&
        licenseService.isAnyLicenseValid(fieldData.licenseType, orgLicenses)
    );

    return fieldsList;
  }

  // --- wait for authorization to be set
  async waitForAuthorization(authApi: AuthApiService) {
    // --- wait for authorization
    let [, authStateErr] = await this.executePromise(
      authApi.waitForAuthStateToGetReady()
    );
    if (authStateErr) {
      await this.handleError(authStateErr, undefined, {
        dismissOnOutsideClick: false
      });
      location.reload();
      return false;
    }
    return true;
  }

  static readonly StudentVisitTypeObj = {
    tardy: "Late_Arrival",
    earlyDismissal: "Early_Dismissal",
    return: "Returning_to_school",
    earlyArrival: "Early_Entry"
  };

  // --- prepare message by evaluating tags
  async prepareMessage(
    msgContent: string,
    services: PrepMsgServices,
    inputs: PrepMsgInputs,
    preCookedData: PrepMsgPreCookedData = {},
    options: PrepMsgOptions = {},
    useCache: boolean = false,
    replacementDefValue?: any
  ) {
    let rqrmnts = services.urlService.getContextRqrmntsForTagEvaluation(
      msgContent
    );

    if (options && options.additionalRqrmnts)
      rqrmnts = lodash.merge(rqrmnts, options.additionalRqrmnts);

    const defPropsToRead = ["all"];
    let fieldsList = [];
    let context: any = {};

    let readDataPromises = [];

    // --- read fields
    const readFields = async () => {
      if (inputs.orgID)
        return this.getFieldsForOrg(
          inputs.orgID,
          services.urlService,
          services.licenseService,
          null,
          useCache
        );

      if (inputs.orgMgrID)
        return services.urlService.getFieldsByInheritance(
          Role.STUDIO,
          inputs.orgMgrID,
          null,
          useCache
        );

      return [];
    };
    readDataPromises.push(readFields().then(list => (fieldsList = list)));

    // --- helper method to get org data
    const getOrgData = async () => {
      // --- read from pre-cooked data (if available)
      if (lodash.has(preCookedData, "orgData")) return preCookedData.orgData;

      // --- read from db
      let orgID = inputs.orgID;
      if (!orgID) {
        console.error("Org ID not available in params!");
        return null;
      }

      if (!services.orgService) {
        console.error("orgService not available in params!");
        return null;
      }

      let propsToRead = options.orgProps || defPropsToRead;
      let [orgData, readOrgDataErr] = await this.executePromise(
        services.orgService.getOrgData(orgID, propsToRead, useCache)
      );
      if (readOrgDataErr) {
        console.error("Error reading org data", readOrgDataErr);
        return null;
      }

      return orgData;
    };

    // --- helper method to get ind data
    const getIndData = async () => {
      // --- read individual data
      const getInd = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "indData")) return preCookedData.indData;

        // --- read from db
        let orgID = inputs.orgID;
        let indID = inputs.indID;
        if (!orgID || !indID) {
          console.error(
            "orgID or Ind ID not available in params!",
            orgID,
            indID
          );
          return null;
        }

        if (!services.indApiService) {
          console.error("indApiService not available in params!");
          return null;
        }

        let propsToRead = options.indProps || defPropsToRead;
        let [indData, readIndDataErr] = await this.executePromise(
          services.indApiService.getIndData(
            orgID,
            indID,
            propsToRead,
            undefined,
            useCache
          )
        );
        if (readIndDataErr) {
          console.error("Error reading ind data", readIndDataErr);
          return null;
        }

        return indData;
      };

      // --- prepare individual data
      const prepareIndData = async () => {
        let indData = await getInd();
        return indData;
      };

      return await prepareIndData();
    };

    // --- helper method to get org logo url
    const getOrgLogoUrl = async () => {
      // --- read from pre-cooked data (if available)
      if (lodash.has(preCookedData, "orgLogoUrl"))
        return preCookedData.orgLogoUrl;

      // --- get from org data
      let orgData = await getDataPromiseFromRqrmnt("org");

      // --- prepare org logo url
      let orgLogo = lodash.get(orgData, "settings.visuals.logo");
      return await this.fetchFirebaseStorageDownloadURL(orgLogo);
    };

    // --- helper method to get org org cycle to display (eg. on ID)
    const getOrgCycleToDisplay = async () => {
      // --- read from pre-cooked data (if available)
      if (lodash.has(preCookedData, "orgDisplayCycle"))
        return preCookedData.orgDisplayCycle;

      // --- read from db
      let orgID = inputs.orgID;
      if (!orgID) {
        console.error("Org ID not available in params!");
        return null;
      }

      if (!services.transactionsService) {
        console.error("transactionsService not available in params!");
        return null;
      }

      let [orgCycleToDisplay, errReadingData] = await this.executePromise(
        services.transactionsService.getCycleToDisplayOnID(
          Role.ORG,
          orgID,
          undefined,
          useCache
        )
      );
      if (errReadingData) {
        console.error("Error reading org cycle to display", errReadingData);
        return null;
      }

      return orgCycleToDisplay;
    };

    // --- helper method to get org manager data
    const getOrgMgrData = async () => {
      // --- get org manager ID
      const getOrgMgrID = async () => {
        // --- read from inputs data
        let orgMgrID = inputs.orgMgrID;
        if (orgMgrID) return orgMgrID;

        // --- get from org data
        let orgData = await getDataPromiseFromRqrmnt("org");
        orgMgrID = lodash.get(orgData, "studioID");
        return orgMgrID;
      };

      // --- prepare org manager data
      const prepareOrgMgrData = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "orgMgrData"))
          return preCookedData.orgMgrData;

        let orgMgrID = await getOrgMgrID();
        if (!orgMgrID) {
          console.error("Org manager ID not available!");
          return null;
        }

        if (!services.orgMgrService) {
          console.error("Org manager service not available in params!");
          return null;
        }

        let propsToRead = options.orgMgrProps || defPropsToRead;
        let [orgMgrData, readOrgMgrDataErr] = await this.executePromise(
          services.orgMgrService.getOrgMgrData(orgMgrID, propsToRead, useCache)
        );
        if (readOrgMgrDataErr) {
          console.error("Error reading org manager data", readOrgMgrDataErr);
          return null;
        }

        return orgMgrData;
      };

      return await prepareOrgMgrData();
    };

    // --- helper method to get ind photo url
    const getIndPhotoUrl = async () => {
      // --- get photo data
      const getPhotoData = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "indPhotoData"))
          return preCookedData.indPhotoData;

        // --- prepare by processing
        let indPhotoIps = inputs.indPhotoIps;
        let orgID = inputs.orgID;
        let indID = inputs.indID;
        let photoPrefValue = indPhotoIps?.photoPrefValue;
        let currentCycle = inputs.currentCycle;
        if (
          !orgID ||
          !indID ||
          lodash.isNil(photoPrefValue) ||
          lodash.isNil(currentCycle)
        ) {
          console.error(
            "org ID or Ind ID or photoPrefValue or currentCycle not available in params!",
            orgID,
            indID,
            photoPrefValue,
            currentCycle
          );
          return null;
        }

        if (!services.getIndPhotoPipe) {
          console.error("getIndPhotoPipe not available in params!");
          return null;
        }

        let indData = await getDataPromiseFromRqrmnt("ind");

        // --- prepare ind photo data with background
        let [photoData, photoDataErr] = await this.executePromise(
          services.getIndPhotoPipe.transform(
            indData,
            photoPrefValue,
            currentCycle,
            null,
            orgID,
            null,
            null,
            true,
            useCache
          )
        );

        if (photoDataErr) {
          console.error("Error reading photo data", photoDataErr);
          return null;
        }

        return photoData;
      };

      // --- prepare photo url
      const preparePhotoUrl = async () => {
        let indPhotoIps = inputs.indPhotoIps;
        if (indPhotoIps && indPhotoIps.mode == "capture") return "";
        if (indPhotoIps && indPhotoIps.photoUrl) return indPhotoIps.photoUrl;

        let photoData = await getPhotoData();

        let isPhotoMustBeApproved: Boolean = false;
        if (indPhotoIps) {
          isPhotoMustBeApproved = this.getBool(
            indPhotoIps.isPhotoMustBeApproved
          );
        }
        if (
          (photoData && isPhotoMustBeApproved && photoData.photoStatus == 1) ||
          (!isPhotoMustBeApproved && photoData && photoData.photoStatus != 2)
        )
          return photoData.finalImgSrc;

        return environment.placeholderImageUrl;
      };

      return await preparePhotoUrl();
    };

    // --- helper method to get ind division display value
    const getIndDivision = async () => {
      // --- read division data
      const getDivision = async (divisionID: string) => {
        if (!divisionID) return null;

        // --- read from pre-cooked data (if available)
        let divisionData;
        if (lodash.has(preCookedData, "divisionsList")) {
          divisionData = lodash.find(
            preCookedData.divisionsList,
            divData => divData.key == divisionID
          );
        }
        if (divisionData) return divisionData;

        let leagueID = inputs.leagueID;
        if (!leagueID) {
          console.error("League ID not available in params!");
          return null;
        }
        if (!services.leagueDivisionsService) {
          console.error("leagueDivisionsService not available in params!");
          return null;
        }

        let [divData, errReadingDivData] = await this.executePromise(
          services.leagueDivisionsService.getDivision(leagueID, divisionID)
        );
        if (errReadingDivData) {
          console.error(
            "Error reading division data",
            divisionID,
            errReadingDivData
          );
          return null;
        }
        divisionData = divData;

        return divisionData;
      };

      // --- prepare individual data
      const prepareIndDivision = async () => {
        let indData = await getDataPromiseFromRqrmnt("ind");

        // --- convert division ID to display value
        let divName = null;
        if (indData && indData.division) {
          let divData = await getDivision(indData.division);
          divName = lodash.get(divData, "name", indData.division);
        }

        return divName;
      };

      return await prepareIndDivision();
    };

    // --- helper method to get crossing data
    const getCrossingData = async () => {
      let indData = await getDataPromiseFromRqrmnt("ind");
      let indRole = lodash.get(indData, "role");
      let indRoleL = lodash.toLower(indRole);

      if (!indRole || indRoleL == "guardian") {
        indRole = "Visitor";
        indRoleL = lodash.toLower(indRole);
      }

      // --- prepare visit data
      const getVisitData = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "visitData"))
          return preCookedData.visitData;

        // --- read from db
        let visitID = inputs.visitID;
        if (!visitID) {
          console.error("visit ID not available in params!");
          return null;
        }

        if (!services.visitsService) {
          console.error("visitsService not available in params!");
          return null;
        }

        let [visitData, readVisitDataErr] = await this.executePromise(
          services.visitsService.getVisiByKey(visitID)
        );
        if (readVisitDataErr) {
          console.error("Error reading visit data", readVisitDataErr);
          return null;
        }

        return visitData;
      };

      // --- get crossing types
      const getCrossingTypes = async (indRole: string) => {
        let indRoleL = lodash.toLower(indRole);
        let type: "student" | "visitor" =
          indRoleL == "student" ? "student" : "visitor";

        // --- read from pre-cooked data (if available)
        let key =
          type == "student" ? "studentCrossingType" : "visitorCrossingTypes";
        if (lodash.has(preCookedData, key)) return preCookedData[key];

        let orgID = inputs.orgID;
        if (!orgID) {
          console.error("org ID not available in params!");
          return null;
        }
        if (!services.settingsService) {
          console.error("settingsService not available in params!");
          return null;
        }

        // --- read crossing types
        let [crossingTypes, readCrossingTypesErr] = await this.executePromise(
          services.settingsService.getCrossingTypes(
            type,
            Role.ORG,
            orgID,
            undefined,
            useCache
          )
        );

        // --- error handling
        if (readCrossingTypesErr) {
          console.error("Error reading crossing types", readCrossingTypesErr);
          return null;
        }

        return crossingTypes;
      };

      // --- get location data
      const getLocationData = async (locationKey: string) => {
        let orgID = inputs.orgID;
        if (!orgID) {
          console.error("org ID not available in params!");
          return null;
        }
        if (!locationKey) {
          console.error("locationKey not available!");
          return null;
        }
        if (!services.settingsService) {
          console.error("settingsService not available in params!");
          return null;
        }

        // --- read location data
        let [locationData, readLocationDataErr] = await this.executePromise(
          services.settingsService.getLocation(orgID, locationKey)
        );

        // --- error handling
        if (readLocationDataErr) {
          console.error("Error reading location data", readLocationDataErr);
          return null;
        }

        return locationData;
      };

      // --- get kiosk data
      const getKioskData = async (kioskKey: string) => {
        let orgID = inputs.orgID;
        if (!orgID) {
          console.error("org ID not available in params!");
          return null;
        }
        if (!kioskKey) {
          console.error("kioskKey not available!");
          return null;
        }
        if (!services.settingsService) {
          console.error("settingsService not available in params!");
          return null;
        }

        // --- read kiosk data
        let [kioskData, readKioskDataErr] = await this.executePromise(
          services.settingsService.getKiosk(orgID, kioskKey)
        );

        // --- error handling
        if (readKioskDataErr) {
          console.error("Error reading kiosk data", readKioskDataErr);
          return null;
        }

        return kioskData;
      };

      // --- helper function to prepare crossing data by modifying/copying field values
      const prepareCrossingData = async (visitData: any) => {
        if (!visitData) return null;
        let crossingData = lodash.cloneDeep(visitData);

        if (indRoleL == "student")
          crossingData.reason = crossingData.studentVisitReason;

        // --- read crossing types
        let [
          crossingTypes,
          errReadingCrossingTypes
        ] = await this.executePromise(getCrossingTypes(indRole));
        if (errReadingCrossingTypes) {
          console.error(
            "Error reading crossing types",
            errReadingCrossingTypes
          );
          return;
        }

        // --- prepare crossing and reason display values
        let visitType, visitorType;
        if (indRoleL == "student" && crossingData.studentVisitType) {
          visitType = lodash.get(
            CommonServiceService.StudentVisitTypeObj,
            crossingData.studentVisitType,
            crossingData.studentVisitType
          );

          // --- replace visitType with displayName of the crossing type (if available)
          visitType = lodash.get(
            crossingTypes,
            `${visitType}.displayName`,
            visitType
          );

          visitorType = visitType;
        } else if (crossingData.visitorType && crossingData.visitType) {
          visitorType = crossingData.visitorType;
          visitType = crossingData.visitType;

          // ---- replace visitorType with displayName of the crossing type (if available)
          visitorType = lodash.get(
            crossingTypes,
            `${visitorType}.displayName`,
            visitorType
          );

          // --- replace visitType with name of the reason (if available)
          visitType = lodash.get(
            crossingTypes,
            `${visitorType}.reasons.${visitType}`,
            visitType
          );
        }
        crossingData.visitType = visitType;
        crossingData.visitorType = visitorType;

        return crossingData;
      };

      let visitData = await getVisitData();
      return await prepareCrossingData(visitData);
    };

    // --- helper method to get individual certificates data
    const getIndCertsData = async () => {
      // --- get certificate configs
      const getCertConfigs = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "certConfigs"))
          return preCookedData.certConfigs;

        // --- read from db
        let orgID = inputs.orgID;
        let indID = inputs.indID;
        if (!orgID || !indID) {
          console.error(
            "orgID or Ind ID not available in params!",
            orgID,
            indID
          );
          return null;
        }

        if (!services.certificateService) {
          console.error("certificateService not available in params!");
          return null;
        }

        let [certConfigs, readCertConfigsErr] = await this.executePromise(
          services.certificateService.readCertificate(Role.ORG, orgID, null)
        );
        if (readCertConfigsErr) {
          console.error(
            "Error reading certificates configs",
            readCertConfigsErr
          );
          return null;
        }

        return certConfigs;
      };

      // --- get individual's certificates
      const getIndCerts = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "indCerts"))
          return preCookedData.indCerts;

        // --- read from db
        let orgID = inputs.orgID;
        let indID = inputs.indID;
        if (!orgID || !indID) {
          console.error(
            "orgID or Ind ID not available in params!",
            orgID,
            indID
          );
          return null;
        }

        if (!services.certificateService) {
          console.error("certificateService not available in params!");
          return null;
        }

        let [indCerts, readIndCertsErr] = await this.executePromise(
          services.certificateService.readCertificationApprovalData(
            orgID,
            indID
          )
        );
        if (readIndCertsErr) {
          console.error(
            "Error reading individual certificates",
            readIndCertsErr
          );
          return null;
        }

        return indCerts;
      };

      // --- prepare certification data by modifying/copying field values
      const prepareCertData = async () => {
        let promises = [];
        promises.push(getCertConfigs());
        promises.push(getIndCerts());
        let [promisesRes, promisesErr] = await this.executePromise(
          Promise.all(promises)
        );

        if (promisesErr) {
          console.error("Error preparing certificates data", promisesErr);
          return null;
        }

        let certificatesList = lodash.nth(promisesRes, 0);
        let indCerts = lodash.nth(promisesRes, 1);

        let indCertsByKey = lodash.keyBy(indCerts, "certKey");
        let indCertsDetails = lodash
          .chain(certificatesList)
          .map(certConfigData => {
            let certKey = lodash.get(certConfigData, "key");
            let certID = lodash.get(certConfigData, "settings.ID");
            if (!certKey || !certID) return null;

            let indCertData = lodash.get(indCertsByKey, certKey, {});
            return { ...certConfigData, ...indCertData, certID, certKey };
          })
          .compact()
          .value();

        // --- prepare list of uniq org IDs to fetch certification stamp
        let uniqOrgIDs = lodash
          .chain(indCertsDetails)
          .map(item => {
            if (
              item.partnerReviewOrgID &&
              ((item.partnerReviewStatus == 1 &&
                item.orgReviewStatus == 1 &&
                item.certID != "DOB") ||
                (item.certID == "DOB" &&
                  item.orgReviewStatus == 1 &&
                  item.orgMgrReviewStatus == 1))
            ) {
              return item.partnerReviewOrgID;
            }

            return null;
          })
          .compact()
          .uniq()
          .value();

        if (!services.orgService)
          console.warn("orgService not available in params!");

        // --- read partner org stamps
        let readCertStampsPromises = lodash.map(uniqOrgIDs, item =>
          services.orgService
            ? services.orgService
                .getOrgData(item, ["key", "certificationStamp"], useCache)
                .then(data => {
                  let orgID = lodash.get(data, "key");
                  let certificationStamp = lodash.get(
                    data,
                    "certificationStamp"
                  );

                  if (!orgID || !certificationStamp) return {};
                  return { [orgID]: certificationStamp };
                })
            : of(null).toPromise()
        );

        // --- prepare list of uniq org manager user IDs to fetch certification signature
        let uniqOrgMgrUserIDs = lodash
          .chain(indCertsDetails)
          .map(item => {
            let certID = item.certID || lodash.get(item, "settings.ID");

            if (certID != "BirthCert") return null;

            if (item.orgMgrReviewStatus == 1 && item.orgMgrReviewIndID)
              return item.orgMgrReviewIndID;

            return null;
          })
          .compact()
          .uniq()
          .value();

        // --- read org manager user cert signatures
        let readCertSignsPromises = lodash.map(uniqOrgMgrUserIDs, item =>
          services.certificateService.getCertSign(item).then(certSign => {
            if (!certSign) return {};
            return { [item]: certSign };
          })
        );

        let [
          readStampAndSignsRes,
          readStampAndSignsErr
        ]: any = await this.executePromise(
          Promise.all([
            Promise.all(readCertStampsPromises),
            Promise.all(readCertSignsPromises)
          ])
        );
        let certStampsByOrgID = {};
        let certSignsByUserID = {};
        if (readStampAndSignsErr) {
          console.error(
            "Error reading certification signs/stamps of org",
            readStampAndSignsErr
          );
        } else {
          let readCertStampsPromisesRes = lodash.nth(readStampAndSignsRes, 0);
          let readCertSignsPromisesRes = lodash.nth(readStampAndSignsRes, 1);

          certStampsByOrgID = lodash
            .chain({})
            .merge(...readCertStampsPromisesRes)
            .value();
          certSignsByUserID = lodash
            .chain({})
            .merge(...readCertSignsPromisesRes)
            .value();
        }

        const img1px = environment.sampleImage;
        const imgStampNotConfigured = environment.stampNotConfiguredImage;

        let indCertsData = lodash
          .chain(indCertsDetails)
          .map(item => {
            let certData: any = {};
            certData.certID = item.certID || lodash.get(item, "settings.ID");
            if (!certData.certID) return null;

            // ind
            certData.indClaim = item.indClaimStatus ? true : false;
            certData.indClaimAt = item.indClaimTimeStamp;

            // Org
            certData.orgReviewRejectionReason = item.orgReviewRejectionReason;
            certData.orgReviewTimestamp = item.orgReviewTimestamp;
            certData.orgUpdatedByUsername = item.orgUpdatedByUsername;
            certData.orgReviewIndID = item.orgReviewIndID;

            // Partner
            certData.partnerReviewRejectionReason =
              item.partnerReviewRejectionReason;
            certData.partnerReviewTimestamp = item.partnerReviewTimestamp;
            certData.partnerUpdatedByUsername = item.partnerUpdatedByUsername;
            certData.partnerReviewIndID = item.partnerReviewIndID;
            certData.partnerReviewOrgID = item.partnerReviewOrgID;

            // partner certification stamp
            if (
              item.partnerReviewOrgID &&
              ((item.partnerReviewStatus == 1 &&
                item.orgReviewStatus == 1 &&
                item.certID != "DOB") ||
                (item.certID == "DOB" &&
                  item.orgReviewStatus == 1 &&
                  item.orgMgrReviewStatus == 1))
            ) {
              certData.partnerStamp = lodash.get(
                certStampsByOrgID,
                item.partnerReviewOrgID,
                imgStampNotConfigured
              );
            } else certData.partnerStamp = img1px;

            // Org Manager
            certData.orgMgrReviewStatus = item.orgMgrReviewStatus;
            certData.orgMgrReviewIndID = item.orgMgrReviewIndID;
            certData.orgMgrReviewTimestamp = item.orgMgrReviewTimestamp;
            certData.orgMngrUpdatedByUsername = item.orgMngrUpdatedByUsername;
            certData.orgMgrReviewID = item.orgMgrReviewID;

            return certData;
          })
          .keyBy("certID")
          .value();

        let birthCertData = lodash.get(indCertsData, "BirthCert");
        let orgCtxData: any = {};
        let orgMgrCtxData: any = {};
        if (birthCertData) {
          let partnerStamp = birthCertData.partnerStamp;
          if (partnerStamp)
            lodash.set(
              orgCtxData,
              "partnerOrg.certificationStamp",
              partnerStamp
            );

          if (
            birthCertData.orgMgrReviewStatus == 1 &&
            birthCertData.orgMgrReviewIndID
          ) {
            let certificationOfficerSignature = lodash.get(
              certSignsByUserID,
              birthCertData.orgMgrReviewIndID
            );
            if (certificationOfficerSignature)
              lodash.set(
                orgMgrCtxData,
                `certificationOfficerSignature`,
                certificationOfficerSignature
              );
          }
        }

        return { indCertsData, orgCtxData, orgMgrCtxData };
      };

      return await prepareCertData();
    };

    // --- helper method to get individual guardians data
    const getIndGuardiansData = async () => {
      // --- read from pre-cooked data (if available)
      if (lodash.has(preCookedData, "indGuardiansData"))
        return preCookedData.indGuardiansData;

      // --- read from db
      let orgID = inputs.orgID;
      let indID = inputs.indID;
      if (!orgID || !indID) {
        console.error("orgID or Ind ID not available in params!", orgID, indID);
        return null;
      }

      if (!services.indApiService) {
        console.error("indApiService not available in params!");
        return null;
      }

      let propsToRead = options.grdnProps || defPropsToRead;
      propsToRead = lodash.map(propsToRead, prop => `guardian.${prop}`);

      let authDependencies = options.authDependencies?.forGrdnData;

      let [indGrdnsData, readIndGrndsDataErr] = await this.executePromise(
        services.indApiService.getIndData(
          orgID,
          indID,
          propsToRead,
          undefined,
          useCache,
          authDependencies
        )
      );
      if (readIndGrndsDataErr) {
        console.error("Error reading ind data", readIndGrndsDataErr);
        return null;
      }
      indGrdnsData = lodash.get(indGrdnsData, "guardian");

      return indGrdnsData;
    };

    // --- helper method to get pass data
    const getPassData = async () => {
      // --- get pass type data
      const getPassTypeData = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "passTypeData"))
          return preCookedData.passTypeData;

        // --- read from db
        let orgID = inputs.orgID;
        let passTypeID = inputs.passTypeID;
        if (!orgID || !passTypeID) {
          console.error(
            "orgID or Pass type ID not available in params!",
            orgID,
            passTypeID
          );
          return null;
        }

        if (!services.passTypeService) {
          console.error("passTypeService not available in params!");
          return null;
        }

        let [passTypeData, passTypeDataErr] = await this.executePromise(
          services.passTypeService.getPassTypes(
            Role.ORG,
            orgID,
            undefined,
            undefined,
            passTypeID
          )
        );
        if (passTypeDataErr) {
          console.error("Error reading pass type data", passTypeDataErr);
          return null;
        }

        return passTypeData;
      };

      // --- get pass data
      const fetchPassData = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "passData"))
          return preCookedData.passData;

        // --- read from db
        let orgID = inputs.orgID;
        let indID = inputs.indID;
        let passID = inputs.passID;
        let currentCycle = inputs.currentCycle;
        if (!orgID || !indID || !passID || !currentCycle) {
          console.error(
            "orgID, indID, passID or currentCycle not available in params!",
            orgID,
            indID,
            passID,
            currentCycle
          );
          return null;
        }

        if (!services.passesService) {
          console.error("passesService not available in params!");
          return null;
        }

        let [passData, passDataErr] = await this.executePromise(
          services.passesService.getPass(orgID, indID, passID, currentCycle)
        );
        if (passDataErr) {
          console.error("Error reading pass data", passDataErr);
          return null;
        }

        return passData;
      };

      // --- prepare pass data
      const preparePassData = async () => {
        let promises = [];
        promises.push(getPassTypeData());
        promises.push(fetchPassData());

        let [promisesRes, promisesErr] = await this.executePromise(
          Promise.all(promises)
        );
        if (promisesErr) {
          console.error(
            "Error in resolving promises for pass preparation",
            promisesErr
          );
          return;
        }

        let passTypeData = lodash.nth(promisesRes, 0);
        let passData = lodash.nth(promisesRes, 1);
        if (!passData) passData = {};

        // --- merge pass type and pass data and return
        return lodash
          .chain(passData)
          .set("passType", passTypeData)
          .value();
      };

      return await preparePassData();
    };

    // --- helper method to get pass assigner data
    const getPassAssignerData = async () => {
      let passData = await getDataPromiseFromRqrmnt("pass");

      if (!passData || !passData.assignedBy) return null;

      let orgID = inputs.orgID;
      if (!orgID) {
        console.error("org ID not available in params!");
        return null;
      }
      if (!services.indApiService) {
        console.error("indApiService not available in params!");
        return null;
      }

      // --- read pass assigner data
      let [
        passAssignerData,
        errReadingPassAssignerData
      ] = await this.executePromise(
        services.indApiService.getIndData(
          orgID,
          passData.assignedBy,
          GetIndFields.PassAssignerProps,
          undefined,
          useCache
        )
      );

      // --- error handling
      if (errReadingPassAssignerData) {
        console.error(
          "Error reading pass assigner data",
          errReadingPassAssignerData
        );
        return null;
      }

      return passAssignerData;
    };

    // --- helper method to get variation data
    const getVariationData = async () => {
      // --- read from pre-cooked data (if available)
      if (lodash.has(preCookedData, "variationData"))
        return preCookedData.variationData;

      // --- read from db
      let orgID = inputs.orgID;
      let designTypeID = inputs.designTypeID;
      let variationID = inputs.variationID;
      if (!orgID || !designTypeID || !variationID) {
        console.error(
          "orgID or design type ID or variation ID is not available in params!",
          orgID,
          designTypeID,
          variationID
        );
        return null;
      }

      if (!services.variationsService) {
        console.error("variationsService not available in params!");
        return null;
      }

      let [variationData, variationDataErr] = await this.executePromise(
        services.variationsService.getVariationByInheritance(
          Role.ORG,
          orgID,
          designTypeID,
          variationID,
          undefined,
          useCache
        )
      );
      if (variationDataErr) {
        console.error("Error reading variation data", variationDataErr);
        return null;
      }

      return variationData;
    };

    // --- helper method to get output data
    const getOutputData = async () => {
      let outputData = {
        timestamp: moment().valueOf()
      };

      return outputData;
    };

    // --- helper method to get incident data
    const getIncidentData = async () => {
      // --- get behavioural incident data
      const getBIData = async () => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "_BIData")) return preCookedData._BIData;

        // --- read from db
        let orgID = inputs.incidentIps?.orgID;
        let indID = inputs.incidentIps?.indID;
        let _BIID = inputs.incidentIps?._BIID;
        if (!orgID || !indID || !_BIID) {
          console.error(
            "orgID or indID or _BIID not available in params!",
            orgID,
            indID,
            _BIID
          );
          return null;
        }

        if (!services.bhvrlIncdntService) {
          console.error("bhvrlIncdntService not available in params!");
          return null;
        }

        let [_BIData, _BIDataErr] = await this.executePromise(
          services.bhvrlIncdntService.getbehaviouralIncident(
            indID,
            orgID,
            _BIID
          )
        );
        if (_BIDataErr) {
          console.error("Error reading behavioural incident data", _BIDataErr);
          return null;
        }

        return _BIData;
      };

      // --- get incident data
      const getIncidentData = async incidentID => {
        // --- read from pre-cooked data (if available)
        if (lodash.has(preCookedData, "incidentData"))
          return preCookedData.incidentData;

        // --- read from db
        let orgID = inputs.incidentIps?.orgID;
        let indID = inputs.incidentIps?.indID;
        if (!orgID || !indID || !incidentID) {
          console.error(
            "orgID or indID or incidentID not available in params!",
            orgID,
            indID,
            incidentID
          );
          return null;
        }

        if (!services.incidentsService) {
          console.error("incidentsService not available in params!");
          return null;
        }

        let [incidentData, incidentDataErr] = await this.executePromise(
          services.incidentsService.getIncidents(Role.ORG, orgID, incidentID)
        );
        incidentData = lodash.first(incidentData);
        if (incidentDataErr) {
          console.error("Error reading incident data", incidentDataErr);
          return null;
        }

        return incidentData;
      };

      // --- prepare incident data
      const prepareIncidentData = async () => {
        let _BIData = await getBIData();
        if (!_BIData) return null;

        let incidentData = await getIncidentData(_BIData.incidentID);
        return lodash.merge(incidentData, _BIData);
      };

      return await prepareIncidentData();
    };

    // --- helper function to get data promise from requirement (useful to resolve dependency of one rqrmnt on another. eg. crossing)
    let rqrmntDataMap = {};
    const getDataPromiseFromRqrmnt = (rqrmnt: string) => {
      if (lodash.has(rqrmntDataMap, rqrmnt)) return rqrmntDataMap[rqrmnt];

      switch (rqrmnt) {
        case "org":
          rqrmntDataMap[rqrmnt] = getOrgData();
          break;

        case "ind":
          rqrmntDataMap[rqrmnt] = getIndData();
          break;

        case "orgMgr":
          rqrmntDataMap[rqrmnt] = getOrgMgrData();
          break;

        case "orgLogo":
          rqrmntDataMap[rqrmnt] = getOrgLogoUrl();
          break;

        case "orgCycle":
          rqrmntDataMap[rqrmnt] = getOrgCycleToDisplay();
          break;

        case "crossing":
          rqrmntDataMap[rqrmnt] = getCrossingData();
          break;

        case "pass":
          rqrmntDataMap[rqrmnt] = getPassData();
          break;

        case "passAssigner":
          rqrmntDataMap[rqrmnt] = getPassAssignerData();
          break;

        case "indCerts":
          rqrmntDataMap[rqrmnt] = getIndCertsData();
          break;

        case "indGuardians":
          rqrmntDataMap[rqrmnt] = getIndGuardiansData();
          break;

        case "indPhoto":
          rqrmntDataMap[rqrmnt] = getIndPhotoUrl();
          break;

        case "indDivision":
          rqrmntDataMap[rqrmnt] = getIndDivision();
          break;

        case "variation":
          rqrmntDataMap[rqrmnt] = getVariationData();
          break;

        case "incident":
          rqrmntDataMap[rqrmnt] = getIncidentData();
          break;

        case "output":
          rqrmntDataMap[rqrmnt] = getOutputData();
          break;
      }

      if (lodash.has(rqrmntDataMap, rqrmnt)) return rqrmntDataMap[rqrmnt];
      else of(null).toPromise();
    };

    // --- prepare context & other required data
    let readRqrmntsPromises = lodash
      .chain(rqrmnts)
      .map((_, rqrmnt) => {
        return getDataPromiseFromRqrmnt(rqrmnt).then(data => {
          let currentVal, newVal;
          switch (rqrmnt) {
            case "org":
            case "ind":
              currentVal = lodash.get(context, rqrmnt);
              newVal = lodash.merge(currentVal, data);
              lodash.set(context, rqrmnt, newVal);
              break;

            case "indCerts":
              let indCertsData = lodash.get(data, "indCertsData");
              let orgCtxData = lodash.get(data, "orgCtxData");
              let orgMgrCtxData = lodash.get(data, "orgMgrCtxData");
              lodash.set(context, "ind.cert", indCertsData);
              if (!lodash.isEmpty(orgCtxData))
                lodash.merge(context, { org: orgCtxData });
              if (!lodash.isEmpty(orgMgrCtxData))
                lodash.merge(context, { orgMgr: orgMgrCtxData });
              break;

            case "indGuardians":
              lodash.set(context, "ind.guardian", data);
              break;

            case "indPhoto":
              lodash.set(context, "ind.photoUrl", data);
              break;

            case "indDivision":
              lodash.set(context, "ind.division", data);
              break;

            case "orgLogo":
              lodash.set(context, "org.logoURL", data);
              break;

            case "orgCycle":
              lodash.set(context, "org.cycle", data);
              break;

            case "pass":
              currentVal = lodash.get(context, rqrmnt);
              newVal = lodash.merge(currentVal, data);
              lodash.set(context, rqrmnt, newVal);
              break;

            case "passAssigner":
              lodash.set(context, "pass.assigner", data);
              break;

            default:
              lodash.set(context, rqrmnt, data);
              break;
          }
        });
      })
      .compact()
      .value();
    readDataPromises.push(Promise.all(readRqrmntsPromises));

    await Promise.all(readDataPromises);

    // --- add some custom context data
    if (options?.customCtx) context = lodash.merge(context, options.customCtx);

    // --- add some custom fields data
    if (options?.customFields)
      fieldsList = lodash.concat(fieldsList, options.customFields);

    // --- evaluate tags
    let evaluatedStr = await services.urlService.evaluateStrWithTags(
      msgContent,
      fieldsList,
      context,
      replacementDefValue
    );
    return evaluatedStr;
  }

  // --- validates password based on firebase password minimum requirement
  isPwValid(password: string) {
    return lodash.size(password) >= 6;
  }

  // --- helper function to add telemetry
  async addTelemetry(
    settingsService: SettingsService,
    orgID,
    indID,
    indData,
    msg?: string
  ) {
    if (!orgID || !indID || !indData) {
      console.warn(
        "Telemetry not added! missing params!",
        orgID,
        indID,
        indData
      );
      return false;
    }

    // --- prepare required data
    let fullName = `${indData.firstName} ${indData.lastName}`;
    let updateObj: any = { url: window.location.href };
    if (msg) updateObj.type = msg;
    let [, err] = await this.executePromise(
      settingsService.addDeviceInfo(fullName, indID, orgID, updateObj)
    );
    if (err) {
      console.warn("Error while saving telemetry", err);
      return false;
    }

    return true;
  }
  isCorsError(error: any) {
    let errMsg = lodash.toLower(this.prepareErrorMessage(error));
    return lodash.includes(errMsg, "0 unknown error");
  }

  async getCameraDevices() {
    return await navigator.mediaDevices.enumerateDevices();
  }

  getCachePathForProps(props: string[]) {
    let containsAll = lodash.some(props, prop => prop == "all");
    if (containsAll) return `all`;
    else {
      return lodash
        .chain(props)
        .orderBy(prop => prop)
        .join("_")
        .value();
    }
  }

  // --- parse phone number to get proper formatted phone with country code
  parsePhone(phone: string) {
    phone = this.getWellFormattedPhone(phone);

    const phoneNumber = parsePhoneNumberFromString(phone, "US");

    let response = { countryCode: "US", number: phone };
    if (!phoneNumber) return response;

    let country = phoneNumber.country;
    let number = phoneNumber.number;
    if (country) response.countryCode = country;
    if (number) response.number = number;

    return response;
  }

  // --- check if given time is within given limit or not
  isTimeWithin(
    timeToCheck: string,
    format: MomentFormatSpecification,
    limit: number,
    unit: moment.unitOfTime.DurationConstructor
  ) {
    if (!timeToCheck) return false;

    return (
      moment().valueOf() <
      moment(timeToCheck, format)
        .add(limit, unit)
        .valueOf()
    );
  }

  // --- is given input a base64 image
  isBase64Img(s) {
    return lodash.startsWith(s, "data:image");
  }

  // --- is given input a base64 pdf
  isBase64PDF(s) {
    return lodash.startsWith(s, "data:application/pdf");
  }

  getExtensionFromB64Img(base64) {
    if (!base64 || !lodash.isString(base64)) return "png";

    let fileType: string = base64.substring(
      "data:image/".length,
      base64.indexOf(";base64")
    );
    if (!lodash.find(["png", "jpg", "jpeg", "webp"], ext => ext == fileType))
      fileType = "png";
    return fileType;
  }

  getMimeTypeFromB64Img(base64) {
    let extension = this.getExtensionFromB64Img(base64);

    if (lodash.some(["jpg", "jpeg"], item => item == lodash.toLower(extension)))
      return MimeType.JPEG;

    return MimeType.PNG;
  }

  getUrlsFromString(str: string) {
    let urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
    return urlRegex.exec(str);
  }

  // --- get cached ID
  async getCachedID() {
    // --- read cached ID
    let [result, error] = await this.executePromise(
      this.indexedDBService.getItem(IndexedDBKeys.CachedID)
    );
    if (error) {
      console.log("Error reading cached ID", error);
      return null;
    }

    let cachedID = lodash.get(result, "value");
    try {
      cachedID = this.encryptionService.decrypt(cachedID, environment.edk);
      cachedID = JSON.parse(cachedID);
    } catch (e) {}

    // --- check validity of cached ID
    let timestamp = lodash.get(cachedID, "t");
    let isCachedIDValid = this.isTimeWithin(timestamp, "x", 7, "days");
    if (!isCachedIDValid) return { isExpired: true };
    let idB64 = lodash.get(cachedID, "b64");
    if (isCachedIDValid && idB64) return cachedID;
    return { isCachePresent: false };
  }

  sessionID;
  getSessionID() {
    if (!this.sessionID) this.sessionID = this.randomIntFromInterval(0, 999999);
    return this.sessionID;
  }

  // --- get high5 trace
  getH5Trace() {
    return localStorage.getItem(LocalStorageKeys.H5Trace);
  }

  // --- add high5 trace (eg. helpful for partial issues debugging on client's machines)
  addH5Trace(trc: H5Trace) {
    try {
      // --- add session id in trace
      lodash.set(trc, "sid", this.getSessionID());

      const maxTraceCount = 50;

      // --- extract trace from cookies
      let trace: any = this.getH5Trace();
      if (!trace) trace = "[]";

      // --- parse trace
      try {
        trace = JSON.parse(trace);
      } catch (e) {
        console.log("Error parsing trace", e, trace);
        return;
      }

      // --- add new item to trace
      trace = lodash.concat(trace, trc);

      // --- maintain limited trace size
      let traceSize = lodash.size(trace);
      let diff = maxTraceCount - traceSize;
      if (diff < 0) trace = lodash.slice(trace, Math.abs(diff));

      // --- store trace back to cookies
      localStorage.setItem(LocalStorageKeys.H5Trace, JSON.stringify(trace));
    } catch (e) {
      console.log("Error storing trace", e);
    }
  }

  // --- function to check whether we should notify superadmin about move to organization or not (basically deciding whether its a bug situation or normal)
  shouldReportToSAAboutOrgNav(h5Trace: string) {
    try {
      h5Trace = JSON.parse(h5Trace);
      let doesContainsHomePageRoute = lodash.some(h5Trace, item =>
        lodash.includes(item.u, "/landingPage")
      );
      return doesContainsHomePageRoute; // report if user have been to home page before
    } catch (e) {
      return true;
    }
  }

  /**
   * Get storage host name from given URL
   * @param url url string
   * @returns Storage host where the file is stored
   */
  getStorageHostFromURL(url: string): StorageHost {
    if (!url) return StorageHost.UNKNOWN;

    if (url.indexOf(environment.awsImageUpload.bucketUrl) > -1)
      return StorageHost.S3;
    if (url.indexOf(environment.firebaseImgUrl) > -1)
      return StorageHost.FIREBASE;
    return StorageHost.UNKNOWN;
  }

  /**
   * extract s3 storage file path from URL
   * @param url s3 storage file url
   * @returns path of file in s3 storage
   */
  extractS3StoragePath(url: string) {
    if (!url) return null;

    let regex = /https:\/\/[^.]*.s3.amazonaws.com\//g;
    let path = lodash.replace(url, regex, "");

    if (path.indexOf("http") > -1) return null;
    else return path;
  }

  /**
   * extract firebase storage file path from URL
   * @param url firebase storage file URL
   * @returns path of file in firebase storage
   */
  extractFirebaseStoragePath(url: string) {
    if (!url) return null;

    url = lodash.replace(url, /%2F/gi, "/");
    url = lodash.replace(url, /\?.*/gi, "/");
    let path = lodash.replace(
      url,
      `${environment.firebaseImgUrl}${environment.firebaseConfig.storageBucket}/o/`,
      ""
    );

    if (path.indexOf("http") > -1) return null;
    else return path;
  }

  // --- delete file (mostly images) from given url
  deleteFile(url: string) {
    let deleteFilePromise;
    let storageHost: StorageHost = this.getStorageHostFromURL(url);
    if (storageHost == StorageHost.S3) {
      let storagePath = this.extractS3StoragePath(url);
      if (storagePath) {
        let reqBody = {
          type: "deleteObjects",
          objectPaths: [storagePath]
        };
        deleteFilePromise = this.http
          .post(`${environment.awsImageUpload.endPoint}/s3`, reqBody, {
            headers: { "x-api-key": environment.awsImageUpload.xApiKey }
          })
          .pipe(take(1))
          .toPromise();
      }
    } else if (storageHost == StorageHost.FIREBASE) {
      let storagePath = this.extractFirebaseStoragePath(url);
      if (storagePath)
        deleteFilePromise = this.firebaseStorage
          .ref(storagePath)
          .delete()
          .pipe(take(1))
          .toPromise();
    }

    return deleteFilePromise;
  }

  /**
   * set svg sizing to fit screen
   * @param containerViewChild svg container viewchild reference
   * @param offset container height offset (eg. total height of container should be 100% - offset pixels)
   * @param containerHeight container height (if wants to override)
   * @returns void
   */
  svgSizing(
    containerViewChild: any,
    offset: number = 0,
    containerHeight?: string
  ) {
    let svgElem = lodash.get(containerViewChild, "nativeElement.children[0]");
    if (!svgElem) return;

    // --- set svg element styles
    svgElem.style.width = "100%";
    svgElem.style.height = "100%";

    // --- set svg container style
    if (!containerHeight) containerHeight = `${window.innerHeight - offset}px`;
    containerViewChild.nativeElement.style.height = containerHeight;
  }

  // --- soem overlay strokes of SVG isn't visible in firefox so this is a fix for that
  setSvgStrokeStyles() {
    $("svg svg #path").css("stroke", "#000");
    $("svg svg #path").css("stroke-linecap", "round");
    $("svg svg #path").css("stroke-linejoin", "round");
    $("svg svg #path").css("fill", "#fff");
    $("svg svg #path").css("fill-rule", "evenodd");
    $("svg svg #path").css("font-family", "Roboto");
    $("svg svg #path").css("font-size", "14px");
    $("svg svg #path").css("text-anchor", "middle");

    $("svg svg #path1").css("stroke", "#000");
    $("svg svg #path1").css("stroke-linecap", "round");
    $("svg svg #path1").css("stroke-linejoin", "round");
    $("svg svg #path1").css("fill", "#fff");
    $("svg svg #path1").css("fill-rule", "evenodd");
    $("svg svg #path1").css("font-family", "Roboto");
    $("svg svg #path1").css("font-size", "14px");
    $("svg svg #path1").css("text-anchor", "middle");

    $("svg svg #path3").css("stroke", "#000");
    $("svg svg #path3").css("stroke-linecap", "round");
    $("svg svg #path3").css("stroke-linejoin", "round");
    $("svg svg #path3").css("fill", "#fff");
    $("svg svg #path3").css("fill-rule", "evenodd");
    $("svg svg #path3").css("font-family", "Roboto");
    $("svg svg #path3").css("font-size", "14px");
    $("svg svg #path3").css("text-anchor", "middle");
  }

  // --- sanitize key (string) for firebase push (eg. avoid /[]. symbols)
  sanitizeFirebaseKey(key: string): string {
    if (!key || !lodash.isString(key)) return key;
    return encodeURIComponent(key).replace(/\./g, "%2E");
  }

  // --- sanitize object (eg. sanitize obj keys to replace unsupported chars like /[].)
  sanitizeObjForFirebase(obj: any) {
    if (!obj || !lodash.isObject(obj)) return obj;
    return this.mapNestedKeys(obj, this.sanitizeFirebaseKey);
  }

  // --- prepare auth dependencies
  async prepareAuthDependencies(
    orgID: string,
    indID: string,
    authApi: AuthApiService
  ): Promise<CommonAuthDependencies> {
    let authDependencies: CommonAuthDependencies = {};

    let promises = [];
    promises.push(authApi.isGivenUserLoggedIn(orgID, indID));
    promises.push(authApi.isUserGuardian());
    let promisesRes = await Promise.all(promises);

    let isSelf = promisesRes[0];
    let isGuardian = promisesRes[1];

    authDependencies.indOperationsAuthDependencies =
      !isSelf && isGuardian
        ? [{ forRule: "static", requires: "relations" }]
        : [];

    return authDependencies;
  }

  // --- Last pass extension on Firefox browser was somehow triggerring event which made browser to add favicon image to body
  // this is a patch to remove that image
  uiIssueGeneratedByLastPassPatch() {
    // --- if last child of body is <img src="./assets/high5-logo-small-ios-pwa.png" style="visibility: hidden;"> then remove it
    let bodyLastChild = $("body")
      .children()
      .last()[0];
    if (
      bodyLastChild.nodeName == "IMG" &&
      (bodyLastChild as HTMLImageElement).src.endsWith(
        "high5-logo-small-ios-pwa.png"
      )
    ) {
      bodyLastChild.remove();
      return true;
    }

    return false;
  }

  isDateTimeRangValid(val: number, currentDateTime: any, startDateTime: number, endDateTime: number, beforeDateTime: number, afterDateTime: number) {
    currentDateTime = isNaN(currentDateTime)
    ? Number(moment().format("x"))
    : Number(currentDateTime)
    let currentFormate = !lodash.isBoolean(val) ? val > 6 ? 'YYYY/MM/DD HH:mm' : (val <= 6 && val > 2) ? 'HH:mm' : 'x' : 'x';
    let currentMoment: any = moment(currentDateTime).format(currentFormate);
    currentMoment = moment(currentMoment, currentFormate);
    const startMoment = moment(moment(startDateTime, 'x').format(currentFormate), currentFormate);
    const endMoment = moment(moment(endDateTime, 'x').format(currentFormate), currentFormate);
    const beforeMoment = moment(moment(beforeDateTime, 'x').format(currentFormate), currentFormate);
    const afterMoment = moment(moment(afterDateTime, 'x').format(currentFormate), currentFormate);
    switch (val) {
      case TimeVaryingMethod.YES:
        return true;

      case TimeVaryingMethod.NO:
        return false;

      case TimeVaryingMethod.YESINTIME:
      case TimeVaryingMethod.YESINDATETIME:
        return currentMoment.isBetween(startMoment, endMoment);

      case TimeVaryingMethod.NOINTIME:
      case TimeVaryingMethod.NOINDATETIME:
        return !currentMoment.isBetween(startMoment, endMoment);

      case TimeVaryingMethod.YESAFTERTIME:
      case TimeVaryingMethod.YESAFTERDATETIME:
        return afterMoment.isBefore(currentMoment) && !afterMoment.isAfter(currentMoment);

      case TimeVaryingMethod.NOAFTERTIME:
      case TimeVaryingMethod.NOAFTERDATETIME:
        return beforeMoment.isAfter(currentMoment) && !beforeMoment.isBefore(currentMoment);

      default:
        return true;
    }
  }
  logs = [];
  appendLog(log: string) {
    this.logs.push(`${log} :: ${moment().valueOf()}`);
  }

  async reportLogs() {
    try {
      let deviceID = "";
      try {
        deviceID = localStorage.getItem("deviceID");
      } catch (e) {}
      if (!deviceID) {
        deviceID = this.generateId(10);
        try {
          localStorage.setItem("deviceID", deviceID);
        } catch (e) {}
      }

      let log = {
        details: JSON.stringify(this.logs),
        timestamp: moment().valueOf(),
        url: location.href
      };
      await this.db.list(`temp/${deviceID}/`).push(log);
      await this.db.object(`temp/${deviceID}/deviceInfo`).set(getDeviceInfo());
      this.resetLogs();
    } catch (e) {
      console.log("Error reporting logs", e);
      return false;
    }

    return true;
  }

  resetLogs() {
    this.logs = [];
  }

  // --- generate full url from endpoint and query params object
  generateUrl(endpoint: string, queryParams: any) {
    let url = window.location.origin + endpoint;
    if (queryParams) {
      let query = new URLSearchParams(queryParams).toString();
      url = `${url}?${query}`;
    }
    return url;
  }

  // --- append query params to url
  appendQueryParams(url: string, queryParams: string) {
    if (url.indexOf("?") > -1) return `${url}&${queryParams}`;
    return `${url}?${queryParams}`;
  }

  getTicketPriceIncludingAllServices(payableAmount): number {
    /**
     * high5Charge = 0.49;
     * stripe charge 2.9% + $0.30 of payableAmount & High5 keep 0.49 fixded Amount
     * final price calculated according to this equation: X - (X * 2.9% + $0.30) - $0.49 = $10. assume X is ticket price here.
     * stripe change 2.9%  = 0.029 (2.9% of 1) according to equation
     * 
    */ 
    return Number((payableAmount + 0.30 + 0.49) / (1 - 0.029));
  }
}

export interface H5Trace {
  f: string; // function name
  t: number; // timestamp
  [key: string]: any; // for additional key/value pairs
}
