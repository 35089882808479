import {
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit
} from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { getQueryParams, setQueryParams } from "./core/utils/queryParams.utils";
import {
  debounceTime,
  delay,
  filter,
  skip,
  switchMap,
  takeUntil,
  tap
} from "rxjs/operators";
import { AuthApiService } from "./core/auth/auth.api.service";
import { DigitalIDService } from "./core/digital-id/digital-id.service";
import { CommonServiceService } from "./core/common/common-service.service";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { getDeviceInfo } from "../assets/js/deviceInfo.js";
import {
  Language,
  LocalStorageKeys,
  MsgMedium,
  SendMsgToIndType,
  SendMsgToReceiver,
  UpdateTypes
} from "./core/enums/general.enums";
import lodash from "lodash";
import { LocalStorageService } from "./core/storage/local-storage.service";
import { AlertDialogService } from "./features/alert-dialog/alert-dialog.service";
import { Auth2FAService } from "./core/auth2-fa.service";
import { interval, Observable, Subject, Subscription } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Auth2FAModalComponent } from "./features/auth2-famodal/auth2-famodal.component";
import { IndividualApiService } from "./core/individual/individual.api.service";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { High5AppService } from "./core/high5-app/high5-app.service";
import { EventsService } from "./core/events.service";
import firebase from "firebase/app";
import { ApiHelperService, CloudFnNames } from "./core/api-helper.service";
import UserAuth from "./core/auth/userAuth.class";
import {
  Message,
  MessageArea,
  MessagesService,
  MessageTypes
} from "./core/messages/messages.service";
import { Role } from "./core/enums/user.enums";

import { PwaPopupDialogService } from "./features/confirm-dialog/pages/pwa-popup/pwa-popup-dialog.service";
import { OrganizationsService } from "./core/organizations/organizations.service";
import { ServiceWorkerService } from "./core/service-worker.service";
import { PassRequest, PassesService } from "./core/passes/passes.service";
import { PassRequestModalComponent } from "./features/passes/pass-request-modal/mpass-request-modal/pass-request-modal.component";
import { SSOService } from "./core/sso/sso.service";
@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit, OnDestroy {
  deviceInfo = getDeviceInfo(); //this.deviceService.getDeviceInfo();
  showPopover = false;
  isIOS = this.deviceInfo.device.OS.model == "iOS";

  authStateReadyObservable: Observable<any>;

  static readonly app: any = {
    version: 5.66
  };
  @HostListener("gesturestart", ["$event"])
  onGestureStart(event: Event) {
    event.preventDefault();
  }
  constructor(
    private db: AngularFireDatabase,
    private authApi: AuthApiService,
    private translateService: TranslateService,
    private commonService: CommonServiceService,
    private localStorageService: LocalStorageService,
    private alertDialogService: AlertDialogService,
    private _2FAAuthService: Auth2FAService,
    private router: Router,
    private modalService: NgbModal,
    private individualApiService: IndividualApiService,
    public high5AppService: High5AppService,
    private eventsService: EventsService,
    private apiHelperService: ApiHelperService,
    private messagesService: MessagesService,
    private ngZone: NgZone,
    private pwaPopupService: PwaPopupDialogService,
    private orgService: OrganizationsService,
    private swService: ServiceWorkerService,
    private passesService: PassesService,
    private ssoService: SSOService
  ) {
    this.removeOldServiceWorker();

    // --- check if "Cookies blocked", if so, show a dialog to user
    if (!this.localStorageService.isAvailable()) {
      let hint = "";
      if (this.isIOS)
        hint =
          '(Hint: Settings > safari > Turn off "Block All Cookies" toggle)';
      this.alertDialogService.confirm(
        "Attention",
        `We've found that you have blocked cookies for either this website or for the browser.

In order to store login sessions and other useful information's for your convenience, we need to store cookies on your device.
You can allow cookies from your browser settings.${hint ? "\n" + hint : null}

You can still continue without allowing cookies but that will require you to login every time you visit the site.`,
        "Ok",
        "Cancel",
        "sm",
        true
      );
    }

    // --- set default translation language
    let allSupportedLangs = Language.getAll();
    this.translateService.addLangs(allSupportedLangs);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(Language.EN);

    // --- set language as per user's choice
    let lang: Language =
      (this.localStorageService.getItem(LocalStorageKeys.lang) as Language) ||
      Language.EN;
    this.commonService.setAppLanguage(lang);

    // --- connect renderer
    this.commonService.executePromise(
      this.commonService.loadAndConnectRenderer()
    );

    // --- prepare auth state ready observable
    this.authStateReadyObservable = new Observable(observer => {
      this.eventsService.subscribe("user:authStateReady", (...args) =>
        observer.next(...args)
      );
    });

    // --- setup auth state ready listener
    this.setupAuthStateReadyListener();

    // --- prepare auth state
    this.prepareAuthState().catch(async e => {
      if (e && e.message && e.message.toLowerCase().includes("loading chunk")) throw e; // if its chunk loading failed error, throw it so that it can be handled by global error handler
      this.commonService.appendLog(
        "Error preparing auth: " + this.commonService.prepareErrorMessage(e)
      );
      this.commonService.reportLogs();
    });

    // --- set network connectivity change listeners
    this.setNtwChangeListeners();

    // --- listen for app installation events
    this.setupAppInstallationEvents();

    // --- monitor for latest updates
    this.monitorUpdates();

    // --- configure SSO service
    this.ssoService.configureSSO();
  }

  // --- monitor app updates
  async monitorUpdates() {
    this.db
      .object("/settings/versions/landing/version")
      .valueChanges()
      .subscribe(async (newVersion: any) => {
        if (newVersion <= AppComponent.app.version) return;

        // --- check for update availability
        let isUpdateAvailable = await this.swService.updateIfAvailable();
        if (!isUpdateAvailable) return;

        // --- activate update
        await this.swService.activateUpdate();

        // --- check if its forcefull update
        let snapshot = await this.db
          .object("settings/versions/landing/type")
          .query.once("value");
        let type = snapshot.val();

        // --- if forcefull update, reload update now!
        if (type == UpdateTypes.FORCEFULL) window.location.reload();
      });
  }

  // --- remove old service worker (which was responsible for PWA installation)
  async removeOldServiceWorker() {
    if (!navigator || !navigator.serviceWorker) return; // for old browsers

    // --- unregister cache service workers
    let registrations: any = await navigator.serviceWorker.getRegistrations();
    registrations = lodash.filter(
      registrations,
      reg =>
        reg &&
        reg.active &&
        lodash.includes(reg.active.scriptURL, "/service-worker.js")
    );
    if (registrations.length == 0) {
      console.log("No old service worker registrations found!");
      return;
    }

    const unregisterPromises = registrations.map(registration =>
      registration.unregister()
    );
    await Promise.all(unregisterPromises);
    console.log("============+ Removed old service worker +============");
  }

  // --- setup PWA installation events
  setupAppInstallationEvents() {
    // --- setup installation prompt event
    const onAboutToShowPWAInstallationEvent = e => {
      console.log("beforeinstallprompt triggered ....");
      e.preventDefault();
      this.commonService.shareData.deferredPWAPrompt = e;
    };
    window.addEventListener(
      "beforeinstallprompt",
      onAboutToShowPWAInstallationEvent
    );

    // --- triggers when PWA installation done
    const onPWAInstalled = async () => {
      // --- set deferredPWAPrompt to null as its used once
      this.commonService.shareData.deferredPWAPrompt = null;
      // --- update individual data about PWA installation
      let params = getQueryParams();
      let orgID = params.orgID;
      let indID = params.indID;
      if (orgID && indID) {
        let [, err] = await this.commonService.executePromise(
          this.individualApiService.updateInd(orgID, indID, {
            installedPWAat: moment().valueOf()
          })
        );
        if (err)
          console.error(
            "Error updating individual's PWA installation timestamp!",
            err
          );
      }
    };

    // --- setup PWA installed event
    window.addEventListener("appinstalled", onPWAInstalled);

    // --- listen to PWA installation command
    this.eventsService.subscribe("installPWA", async (data: any) => {
      // --- handle IOS PWA installation
      // basically, not possible to show PWA popup by code in IOS, so just detect PWA installation state and store entry in database
      const handleIOS = async () => {
        if (!this.isIOS) return;

        let params = getQueryParams();
        let orgID = params.orgID;
        let indID = params.indID;
        if (!orgID || !indID) return;

        // --- if in standalone mode (which is PWA mode, mentioned in manifest), means PWA is installed
        const isInStandaloneMode =
          "standalone" in window.navigator && window.navigator["standalone"];
        if (!isInStandaloneMode) return; // this means not in PWA

        let isPWAInstallationDetectedBefore = this.localStorageService.getItem(
          LocalStorageKeys.isPWAInstallationDetectedBefore
        );
        if (isPWAInstallationDetectedBefore) return; // this means PWA data already attached before with the individual

        // --- store PWA installation data
        this.localStorageService.setItem(
          LocalStorageKeys.isPWAInstallationDetectedBefore,
          "true"
        );
        await onPWAInstalled();
      };

      // --- handle IOS devices installations
      if (this.isIOS) {
        await handleIOS();
        return;
      }

      if (!this.commonService.shareData.deferredPWAPrompt) return;

      let interfacePref = lodash.get(data, "interfacePref", "custom"); // default or custom dialog

      // --- show custom dialog before showing default installation prompt
      if (interfacePref == "custom") {
        let confirmed = await this.pwaPopupService.present("lg");
        if (!confirmed) return;
      }

      // --- show PWA installation default prompt
      this.commonService.shareData.deferredPWAPrompt.prompt();
    });
  }

  // --- set network (internet) status change listeners
  setNtwChangeListeners() {
    // --- checks internet connection by server ping
    const isOnline = async () => {
      if (!navigator.onLine) return false; // navigator offline trigger is reliable, so use it. online trigger isn't reliable because it checks for the connection only, not for the internet availability

      // --- helper method to connect to any URL
      const connect = async url => {
        // --- request timeout controller
        let abortController = new AbortController();
        setTimeout(() => {
          abortController.abort();
        }, 3 * 1000);

        try {
          const result = await fetch(url, { signal: abortController.signal });
          return result.status >= 200 && result.status < 300;
        } catch (e) {
          if (e instanceof DOMException && e.name == "AbortError") {
            // this happens when the source page initiating request gets destroyed. To avoid showing offline page for such cases, return true.
            console.log("Connection error::DOMException...", e);
            return true;
          } else {
            console.log("Connection error::Other error...", e);
          }
          return false;
        }
      };

      // --- try to ping different urls
      let urls = [
        "https://high5.id/media/utils/internet_test_img.png",
        "https://api.ipify.org/"
      ];
      let promises = lodash.map(urls, url => connect(url));
      let results = await Promise.all(promises);

      // --- if any connection succeeds, internet is available
      return lodash.some(results, connected => connected == true);
    };

    let ntwPingSubject = new Subject<number>();

    // --- check network status every x milliseconds (where milliseconds will change whenever subject will trigger new value)
    ntwPingSubject
      .pipe(
        switchMap(ms => interval(ms)),
        filter(
          () => document.hasFocus() && document.visibilityState !== "hidden"
        )
      )
      .subscribe(() => checkNtwStatus());

    // --- listen to internet access (eg. wifi connected but no internet connection)
    let prevStatus: "online" | "offline";
    let noChangeCounts = 0;
    let intervalMS = 3000;
    const checkNtwStatus = async () => {
      const connected = await isOnline();

      if (!document.hasFocus() || document.visibilityState === "hidden") {
        console.log("Preventing action as document is not in focus!");
        return; // if page is not open, do nothing
      }

      if (prevStatus == connected) {
        // --- if no change since past 10 pings, then double the interval timeout
        noChangeCounts++;
        if (noChangeCounts > 10) {
          noChangeCounts = 0;
          intervalMS += intervalMS;
          ntwPingSubject.next(intervalMS);
        }

        return; // if status is unchanged, do nothing
      }

      prevStatus = connected; // update prevStatus value
      noChangeCounts = 0; // reset no change count
      intervalMS = 3000; // reset interval timeout
      ntwPingSubject.next(intervalMS);

      this.onConnectionStatusUpdate(connected ? "online" : "offline");
    };

    // --- set interval based internet listener
    ntwPingSubject.next(intervalMS);

    // --- trigger initial event
    checkNtwStatus();

    window.addEventListener("offline", checkNtwStatus); // additional window listener for quick action
    window.addEventListener("focus", checkNtwStatus); // additional window listener for quick action
  }

  // --- triggers when internet connection status changes
  onConnectionStatusUpdate = lodash.debounce((status: "online" | "offline") => {
    this.commonService.shareData.connectionStatus = status;
    this.commonService.addH5Trace({
      f: "conUpdt",
      t: moment().valueOf(),
      s: status,
      u: window.location.href
    });
    if (status == "offline") {
      // --- if internet connection is lost, open the ID page
      this.ngZone.run(() => {
        this.router.navigate(["/offline"]);
      });
    } else if (status == "online") {
      const sendMsgToSuperadmin = (additionalDetails?) => {
        let h5Trace = this.commonService.getH5Trace();
        let shouldReport = this.commonService.shouldReportToSAAboutOrgNav(
          h5Trace
        );
        if (!shouldReport) return;

        let details = `redirected to org page.<br/><br/>H5 Trace: ${h5Trace}`;
        if (additionalDetails)
          details += `${details}<br/><br/>${additionalDetails}`;
        let message: Message = {
          timestamp: moment().valueOf(),
          area: MessageArea.ATTENTION,
          type: MessageTypes.INTERNAL,
          details: details
        };
        this.messagesService.setMessage(Role.SUPERADMIN, message);
      };

      // --- if internet connection is restored, go back to the previous page
      const goBack = async () => {
        // --- wait for authorization
        await this.commonService.waitForAuthorization(this.authApi);

        // --- check for localstorage preferences, if user logged in, open landing page,
        // else open find-organizations page
        let { orgID, indID } = await this.authApi.getCurrentUserData();

        if (orgID && indID) {
          let isGivenUserLoggedIn = await this.authApi.isGivenUserLoggedIn(
            orgID,
            indID
          );
          if (!isGivenUserLoggedIn) {
            // --- send message to superadmin (possible bug)
            sendMsgToSuperadmin(
              `Given user login check failed. ${orgID} ${indID}`
            );

            this.router.navigate(["organization/"]);
            return;
          }
          let queryParams: UserAuth = {
            orgID: orgID,
            indID: indID
          };
          await this.router.navigate(["/landingPage"], { queryParams });
        } else {
          // --- send message to superadmin (possible bug)
          // sendMsgToSuperadmin();

          this.router.navigate(["organization/"]);
        }
      };

      if (
        window.location.pathname == "/offline"
      ) {
        try {
          goBack();
        } catch (e) {
          console.log("e", e);

          // --- send message to superadmin (possible bug)
          // let h5Trace = this.commonService.getH5Trace();
          // let message: Message = {
          //   timestamp: moment().valueOf(),
          //   area: MessageArea.ATTENTION,
          //   type: MessageTypes.INTERNAL,
          //   details: `redirected to org page (from error part).<br/><br/>Error: ${this.commonService.prepareErrorMessage(
          //     e,
          //     undefined,
          //     true
          //   )}<br/><br/>H5 Trace: ${h5Trace}`
          // };
          // this.messagesService.setMessage(Role.SUPERADMIN, message);

          this.router.navigate(["organization/"]);
        }
      }
    }
  }, 100);

  // --- prepare auth state (eg. sign in anonymous user if required)
  async prepareAuthState() {
    // --- if no user is signed, sign in as anonymous user
    this.commonService.appendLog("prepareAuthState begin");
    let authState = await this.authApi.getAuthState();
    this.commonService.appendLog(`auth present?: ${!!authState}`);

    if (!authState) {
      this.commonService.appendLog(`anonymous sign in begin`);
      let user = await this.authApi.signInAnonymously();
      this.commonService.appendLog(
        `anonymous sign in done: ${lodash.get(user, "user.uid")}`
      );
      authState = await this.authApi.getAuthState();
      this.commonService.appendLog(`auth present?: ${!!authState}`);
    }

    this.eventsService.publish("user:authStateReady", authState);
    this.authApi.isAuthStateReady = true;
    this.commonService.appendLog(`published auth state ready event`);
  }

  // --- setup auth state ready listener
  setupAuthStateReadyListener() {
    this.authStateReadyObservable.subscribe(this.onAuthStateReady.bind(this));
  }

  // --- triggers when auth state gets ready
  onAuthStateReady(authState: firebase.User) {
    if (!authState || !authState.uid) {
      console.error(
        "user:authStateReady event missing valid authState!",
        authState
      );
      return;
    }

    // --- listen to claims metadata changes for current user
    const onClaimsMetadataChange = async (updatedValue: any) => {
      // --- refresh claims
      await this.authApi.refreshIDToken();
      this.eventsService.publish("user:authTokenRefreshed");
    };
    this.db
      .object(`metadata/${authState.uid}/claimsUpdatedAt`)
      .valueChanges()
      .pipe(takeUntil(this.authStateReadyObservable))
      .pipe(
        filter((value: any) => {
          //  --- if timestamp is changed, then only proceed
          let isClaimsUpdateAtChanged = value && this.localStorageService.getItem(LocalStorageKeys.claimsUpdatedAt) != value;

          // --- store claims update timestamp in localstorage
          if (isClaimsUpdateAtChanged)
            this.localStorageService.setItem(
              LocalStorageKeys.claimsUpdatedAt,
              value
            );

          return isClaimsUpdateAtChanged;
        })
      )
      .subscribe(onClaimsMetadataChange);

    // --- listen to password metadata changes for current user
    const onPwMetadataChange = async (updatedValue: any) => {
      let currentPath = location.pathname;
      // TVT location path name
      if (
        !lodash.some(
          CommonServiceService.tvtPathArr,
          route => route == currentPath
        )
      ) {
        window.location.reload();
      }
    };
    this.db
      .object(`metadata/${authState.uid}/pwUpdatedAt`)
      .valueChanges()
      .pipe(takeUntil(this.authStateReadyObservable))
      .pipe(skip(1))
      .subscribe(onPwMetadataChange);
  }

  // --- manage pass requests monitoring
  monitorPassRequestsSubData: any = {};
  passReqModalRef: NgbModalRef;
  async managePassRequestsMonitoring() {
    // --- on pass requests data changes
    const onPassRequestsDataChange = async (passRequests: any) => {
      // --- if previous modal is open, then return (as we are waiting for approval/denial of previous request)
      if (this.passReqModalRef) return;

      // --- get currently logged in user details
      let { orgID, indID } = await this.authApi.getCurrentUserData();
      if (!orgID || !indID) return;

      let requests: PassRequest[] = lodash
        .chain(passRequests)
        .map((requestData: any, key: string) => {
          return { ...requestData, key };
        })
        .filter((requestData: any) => {
          return requestData.requestedFrom == indID; // its just a sanity filter, listener is only listening for current user so not needed but still
        })
        .orderBy("requestedAt")
        .value();
      if (lodash.size(requests) == 0) return;

      // --- ask for approval of first request
      // --- when first request will be approved/denied, listener will again get triggered as we move the request to history, so no need to loop through all the requests
      for (const passReq of requests) {
        this.passReqModalRef = this.modalService.open(
          PassRequestModalComponent,
          {
            backdrop: "static"
          }
        );
        this.passReqModalRef.componentInstance.orgID = orgID;
        this.passReqModalRef.componentInstance.indID = indID;
        this.passReqModalRef.componentInstance.passRequest = passReq;
        await this.commonService.executePromise(this.passReqModalRef.result);
        this.passReqModalRef = null;
      }
    };

    let shouldStopMonitoring = false;

    // --- if user is on kiosk flow, then remove pass requests subscription
    let queryParams = getQueryParams();
    shouldStopMonitoring = queryParams.callFrom == "kiosk";

    // --- if user is not logged in, or is not having staff access, then remove pass requests subscription
    let [claims, _] = await this.commonService.executePromise(
      this.authApi.getUserCustomClaim()
    );
    let orgID = lodash.get(claims, "OID");
    let indID = lodash.get(claims, "IID");
    let claimsAR = lodash.get(claims, "AR");
    shouldStopMonitoring =
      !orgID || !indID || !this.authApi.isTeacher(claimsAR);

    // --- remove pass requests subscription
    if (shouldStopMonitoring) {
      let subscription: Subscription = this.monitorPassRequestsSubData.sub;
      if (subscription && !subscription.closed) subscription.unsubscribe();
      return;
    }

    // --- monitor pass requests
    let currentSubIndID = this.monitorPassRequestsSubData.indID;
    let currentSubOrgID = this.monitorPassRequestsSubData.orgID;
    let currentSub = this.monitorPassRequestsSubData.sub;
    if (
      !currentSub ||
      currentSub.closed ||
      (currentSubOrgID != orgID && currentSubIndID != indID)
    ) {
      // --- remove old subscription
      if (currentSub && !currentSub.closed) currentSub.unsubscribe();

      // --- create new subscription
      let passRequestsObserver = await this.passesService.monitorPendingPassRequests(
        orgID,
        indID
      );
      this.monitorPassRequestsSubData.sub = passRequestsObserver
        .pipe(delay(500)) // delay a few milliseconds so that when request is approved/denied, it gets some time to reflect changes on variables (eg. making passReqModalRef null)
        .subscribe(onPassRequestsDataChange.bind(this));
      this.monitorPassRequestsSubData.orgID = orgID;
      this.monitorPassRequestsSubData.indID = indID;
    }
  }

  monitor2FARequiredFlagSubData: any = {};
  auth2FAModalRef: NgbModalRef;
  ngOnInit() {
    this.high5AppService.isIosDevice();

    // --- store _2FAData in cache if present in URL
    let queryParams = getQueryParams();
    let orgID = queryParams.orgID;
    let indID = queryParams.indID;
    let _2FAVerification = queryParams._2FAVerification;
    let payload = queryParams.payload;
    if (orgID && indID && _2FAVerification && payload) {
      let _2FAData = {
        orgID,
        indID,
        timestamp: _2FAVerification,
        token: payload
      };
      this.localStorageService.setItem(
        LocalStorageKeys._2FAData,
        JSON.stringify(_2FAData)
      );
    }

    // --- send 2FA verification SMS to ind's registered mobile
    const send2FAVerificationSMS = async (orgID, indID, number) => {
      // --- send message
      let [, sendMsgErr] = await this.commonService.executePromise(
        this.apiHelperService.postToCloudFn(CloudFnNames.sendMsgToInd, {
          orgID,
          indID,
          msgType: SendMsgToIndType._2FA,
          msgMedium: MsgMedium.SMS,
          receiver: SendMsgToReceiver.SELF
        })
      );

      // --- error handling
      if (sendMsgErr) {
        this.commonService.handleError(sendMsgErr);
        return;
      }

      // --- show success dialog
      await this.commonService.presentAlert(
        `A message was sent to\n${number}`,
        "Success"
      );
    };

    // --- on 2FA Required flag changes
    const on2FARequiredFlagChange = async value => {
      let userUID = lodash.get(value, "uid");
      let userData = await this.authApi.getCurrentUserData();
      let orgID = userData.orgID;
      let indID = userData.indID;

      // --- dismiss dialog if present
      if (this.auth2FAModalRef) {
        this.auth2FAModalRef.dismiss(
          "Dismissed dialog before showing another one"
        );
        this.auth2FAModalRef = null;
      }

      // --- prevent 2FA dialog if in kiosk mode or required data is missing
      let shouldAskFor2FA = userUID && orgID && indID;
      let queryParams = getQueryParams();
      if (queryParams.callFrom == "kiosk") shouldAskFor2FA = false;

      if (!shouldAskFor2FA) return;

      let readDataPromises = [];

      // --- read org data
      readDataPromises.push(
        this.orgService.getOrgData(orgID, ["orgName", "key"], true)
      );

      // --- read ind data
      readDataPromises.push(
        this.individualApiService.getIndData(orgID, indID, [
          "key",
          "mobilePhone"
        ])
      );

      let [
        readDataPromisesRes,
        readDataPromisesErr
      ] = await this.commonService.executePromise(
        Promise.all(readDataPromises)
      );

      if (readDataPromisesErr) return;
      let orgData: any = lodash.nth(readDataPromisesRes, 0);
      let indData: any = lodash.nth(readDataPromisesRes, 1);
      if (!orgData || !indData) return;

      // --- send SMS to mobile phone if its unknown device/token expired
      let cache2FAData = this.localStorageService.getItem(
        LocalStorageKeys._2FAData
      );
      let cached2FAToken, cached2FATimestamp;
      try {
        cache2FAData = JSON.parse(cache2FAData);
        let cached2FAOrgID = lodash.get(cache2FAData, "orgID");
        let cached2FAIndID = lodash.get(cache2FAData, "indID");
        if (cached2FAOrgID == orgID && cached2FAIndID == indID) {
          cached2FAToken = lodash.get(cache2FAData, "token");
          cached2FATimestamp = lodash.get(cache2FAData, "timestamp");
        }
      } catch (e) {}

      if (
        !cached2FAToken ||
        cached2FATimestamp + CommonServiceService._2FATokenExpiry <
          moment().valueOf()
      ) {
        if (!indData.mobilePhone) return;

        let wellFormattedNumber = this.commonService.getWellFormattedPhone(
          indData.mobilePhone
        );
        let phoneNumber = this.commonService.getCountryCode(
          wellFormattedNumber
        );
        let phoneNumberObj = parsePhoneNumberFromString(
          wellFormattedNumber,
          "US"
        );

        // --- ask for confirmation
        await this.alertDialogService.confirm(
          "Verification",
          `Your account at ${
            orgData.orgName
          } is being used.\n\nWe are about to send a message to\n${
            phoneNumberObj
              ? phoneNumberObj.formatNational()
              : wellFormattedNumber
          }\nto verify your identity.`,
          "Continue",
          null,
          null,
          true,
          { backdrop: "static" }
        );

        await send2FAVerificationSMS(orgID, indID, phoneNumber.number);
        return;
      }

      // --- ask user for authentication
      let authToken = cached2FAToken || getQueryParams().payload;
      if (!authToken) return;

      this.auth2FAModalRef = this.modalService.open(Auth2FAModalComponent, {
        backdrop: "static"
      });
      this.auth2FAModalRef.componentInstance.orgName = orgData.orgName;
      let [authResult, authErr] = await this.commonService.executePromise(
        this.auth2FAModalRef.result
      );
      if (authErr) {
        console.log("authErr", authErr);
        return;
      }

      // --- if authenticated positively
      if (authResult == true) {
        let [, tokenVerificationErr] = await this.commonService.executePromise(
          this.apiHelperService.postToCloudFn(CloudFnNames.verify2FA, {
            orgID: orgID,
            indID: indID,
            token: authToken
          })
        );
        if (tokenVerificationErr) {
          this.commonService.handleError(tokenVerificationErr);
          return;
        }
      }

      // --- if authenticated negatively
      if (authResult != true) {
        let [, _2FARejectionErr] = await this.commonService.executePromise(
          this.apiHelperService.postToCloudFn(CloudFnNames.reject2FA, {
            orgID: orgID,
            indID: indID
          })
        );
        if (_2FARejectionErr) {
          this.commonService.handleError(_2FARejectionErr);
          return;
        }
      }
    };

    // --- on router page changes
    const onPageChanges = async () => {
      this.managePassRequestsMonitoring(); // manage pass requests monitoring

      let shouldRemove2FASub = false;

      // --- if user is on kiosk flow, then remove 2FA flag subscription
      let queryParams = getQueryParams();
      shouldRemove2FASub = queryParams.callFrom == "kiosk";

      // --- if user is not logged in then remove 2FA flag subscription
      let userData = await this.authApi.getCurrentUserData();
      let orgID = userData.orgID;
      let indID = userData.indID;
      shouldRemove2FASub = !orgID || !indID;

      // --- remove 2FA flag subscription
      if (shouldRemove2FASub) {
        let subscription: Subscription = this.monitor2FARequiredFlagSubData.sub;
        if (subscription && !subscription.closed) subscription.unsubscribe();
        return;
      }

      // --- monitor 2FA required flag
      let currentSubIndID = this.monitor2FARequiredFlagSubData.indID;
      let currentSubOrgID = this.monitor2FARequiredFlagSubData.orgID;
      let currentSub = this.monitor2FARequiredFlagSubData.sub;
      if (
        !currentSub ||
        currentSub.closed ||
        (currentSubOrgID != orgID && currentSubIndID != indID)
      ) {
        // --- remove old subscription
        if (currentSub && !currentSub.closed) currentSub.unsubscribe();

        // --- create new subscription
        this.monitor2FARequiredFlagSubData.sub = this._2FAAuthService
          .monitor2FARequiredFlag(orgID, indID)
          .subscribe(on2FARequiredFlagChange.bind(this));
        this.monitor2FARequiredFlagSubData.orgID = orgID;
        this.monitor2FARequiredFlagSubData.indID = indID;
      }
    };

    // --- monitor page changes
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        tap(e => {
          this.commonService.addH5Trace({
            t: moment().valueOf(),
            f: "pageChange",
            u: window.location.href
          });
        }),
        debounceTime(1000)
      )
      .subscribe(onPageChanges.bind(this));
  }

  ngOnDestroy() {
    // --- remove subscriptions
    let sub = this.monitor2FARequiredFlagSubData.sub;
    if (sub && !sub.closed) sub.unsubscribe();
  }
}
